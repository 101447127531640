
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle, faImage, faPhoneAlt, faComments, faGlobeAmericas,
    faChevronDown, faVideo, faLink, faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import {
    getTextColor,
    getLblStyle,
    getWazeBtnStyle,
    getBtnStyle,
    getProfileOption,
    getBackGroundStyle,
} from "../utils/utils";
import phoneOfficeIcon from '../images/phone-office.png'
import wazeOnlyIcon from '../images/waze-only-icon.PNG'
import emptyUser from '../images/empty_user2.jpg'
import instagramIcon from '../images/instagram_icon.png'
import youtubeIcon from '../images/youtube_icon.png'
import './PreviewPhone.scss';

class PreviewPhone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            theState: this.props.theState,
            bgNames:[]
        }
    }
    componentDidMount = async () => {
        // let bgNames = await this.props.firebase.getAllBackgroundNames()
        // this.setState({
        //     bgNames
        // })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps !== this.props) {
            this.setState({
                theState: this.props.theState
            })
        }
    }


    getPhoneMainImages = (device) => {

        const {
            theCard, faceImageUrl, coverImageUrl, selectedProfileImgOption
        } = this.state.theState

        let profileOption = getProfileOption(theCard, selectedProfileImgOption)

        let coverImg = null

        if (theCard !== undefined && theCard !== null && theCard.coverImageUrl !== "") {
            coverImg = <img alt="" src={theCard.coverImageUrl} className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"} />
        }
        if (coverImageUrl !== undefined) {
            coverImg = <img alt="" src={URL.createObjectURL(coverImageUrl)} className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"} />
        }
        if (coverImageUrl === undefined && ((theCard === undefined || theCard === null) || theCard.coverImageUrl === "")) {
            coverImg = <div className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"}></div>
        }


        let faceImg = null

        if (theCard !== undefined && theCard !== null) {
            let faceImgSrc = theCard.faceImageUrl !== "" && theCard.faceImageUrl !== undefined ?
                theCard.faceImageUrl : emptyUser

            faceImg = <img
                src={faceImgSrc} alt=""
                className={device === "mobile" ?
                    "preview-tiny-face-img" : "preview-face-img"}
                style={{
                    borderRadius: profileOption === "Circle" ? "50%" :
                        profileOption === "Rounded Rectangle" ? "10%" : "0%",
                }}
            />
        }
        if (faceImageUrl !== undefined) {
            faceImg = <img
                src={URL.createObjectURL(faceImageUrl)} alt=""
                className={device === "mobile" ? "preview-tiny-face-img" : "preview-face-img"}
                style={{
                    borderRadius: profileOption === "Circle" ? "50%" :
                        profileOption === "Rounded Rectangle" ? "10%" : "0%",
                }}
            />
        }
        if (faceImageUrl === undefined && ((theCard === undefined || theCard === null) || theCard.faceImageUrl === "")) {
            faceImg = <div>
                <img src={emptyUser} alt=""
                    className={device === "mobile" ? "preview-tiny-face-img" : "preview-face-img"}
                    style={{
                        borderRadius: profileOption === "Circle" ? "50%" :
                            profileOption === "Rounded Rectangle" ? "10%" : "0%",
                    }}
                />
            </div>
        }

        return (
            <div className="top-person-images">
                {coverImg}
                {faceImg}
            </div>
        )
    }

    getPhoneTitles = (device) => {
        const {
            theCard, businessCardTitle, businessJobTitle, selectedTextColor
        } = this.state.theState

        let textColor = getTextColor(theCard, selectedTextColor)

        let lblStyle = { color: textColor }

        let title = (theCard !== undefined && theCard !== null && theCard.businessCardTitle !== "") && businessCardTitle === "" ?
            theCard.businessCardTitle :
            businessCardTitle !== "" ? businessCardTitle : "The Person Name"

        let job = (theCard !== undefined && theCard !== null && theCard.businessJobTitle !== "") && businessJobTitle === "" ?
            theCard.businessJobTitle :
            businessJobTitle !== "" ? businessJobTitle : "The Person Job Description"

        return (
            <div className={device === "mobile" ? "preview-tiny-titles" : "preview-titles"}>

                <label className={device === "mobile" ? "preview-tiny-title1" : "preview-title1"}
                    style={lblStyle}>
                    {title}
                </label>

                <label className={device === "mobile" ? "preview-tiny-title2" : "preview-title2"}
                    style={lblStyle}>
                    {job}
                </label>
            </div>
        )
    }

    getNumOfBtns = (ThePhone, TheWhatsapp, TheLandline,
        TheEmail, TheFullAddress, TheWebsite,
        twitter, facebook, instagram, linkedin, youtube) => {
        let ans = 0;
        if (ThePhone !== "") {
            ans = ans + 2 //phone + sms
        }
        if (TheWhatsapp !== "") {
            ans++
        }
        if (TheLandline !== "") {
            ans++
        }
        if (TheEmail !== "") {
            ans++
        }
        if (TheFullAddress !== "") {
            ans++
        }
        if (TheWebsite !== "") {
            ans++
        }
        if (twitter !== null) {
            ans++
        }
        if (facebook !== null) {
            ans++
        }
        if (instagram !== null) {
            ans++
        }
        if (linkedin !== null) {
            ans++
        }
        if (youtube !== null) {
            ans++
        }
        return ans
    }

    getPhoneBtns = (device) => {
        const {
            phone, whatsapp, landline,
            fullAddress, website, email,
            theCard, selectedTextColor,
            selectedIconBorderShape, selectedIconBorderColor
        } = this.state.theState

        let btnStyle = getBtnStyle(theCard, selectedIconBorderShape, selectedIconBorderColor)

        let lblStyle = getLblStyle(theCard, selectedTextColor)

        let wazeBtnStyle = getWazeBtnStyle(theCard, selectedIconBorderShape, selectedIconBorderColor)

        let ThePhone = (theCard !== undefined && theCard !== null && theCard.phone !== "" && theCard.phone !== undefined) && phone === "" ?
            theCard.phone : phone !== null ? phone : ""

        let TheWhatsapp = (theCard !== undefined && theCard !== null && theCard.whatsapp !== "" && theCard.whatsapp !== undefined) && whatsapp === "" ?
            theCard.whatsapp : whatsapp !== null ? whatsapp : ""

        let TheLandline = (theCard !== undefined && theCard !== null && theCard.landline !== "" && theCard.landline !== undefined) && landline === "" ?
            theCard.landline : landline !== null ? landline : ""

        let TheEmail = (theCard !== undefined && theCard !== null && theCard.email !== "" && theCard.email !== undefined) && email === "" ?
            theCard.email : email !== null ? email : ""

        let TheFullAddress = (theCard !== undefined && theCard !== null && theCard.fullAddress !== "" && theCard.fullAddress !== undefined) && fullAddress === "" ?
            theCard.fullAddress : fullAddress !== null ? fullAddress : ""

        let TheWebsite = (theCard !== undefined && theCard !== null && theCard.website !== "" && theCard.website !== undefined) && website === "" ?
            theCard.website : website !== null ? website : ""

        let twitter = this.getPersotSocialValue("Twitter") || null
        let facebook = this.getPersotSocialValue("Facebook") || null
        let instagram = this.getPersotSocialValue("Instagram") || null
        let linkedin = this.getPersotSocialValue("LinkedIn") || null
        let youtube = this.getPersotSocialValue("YouTube") || null

        return (
            <div>
                <div className={device === "mobile" ? "card-btns-tiny" : "card-btns2"}>

                    {ThePhone !== "" ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <FontAwesomeIcon icon={faPhoneAlt} />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Phone</label>
                        </div>
                        :
                        null
                    }

                    {ThePhone !== "" ?
                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <FontAwesomeIcon icon={faComments} />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>SMS</label>
                        </div>
                        :
                        null
                    }


                    {TheWhatsapp !== "" ?
                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <WhatsappIcon
                                    size={window.innerWidth < 800 ? 15 : 35}
                                    iconFillColor="black"
                                    bgStyle={{ fill: "transparent" }}
                                />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Whatsapp</label>
                        </div>
                        :
                        null
                    }


                    {TheLandline !== "" ?
                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <img alt="" src={phoneOfficeIcon} className="btn-icon" />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Landline</label>
                        </div>
                        :
                        null
                    }


                    {TheFullAddress !== "" ?
                        <div className="card-btn-div2">

                            <div className="card-btn-circle-div2"
                                style={wazeBtnStyle}
                            >
                                <img className="btn-icon"
                                    src={wazeOnlyIcon} alt=""
                                />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Navigate</label>

                        </div>
                        :
                        null
                    }


                    {TheWebsite !== "" ?
                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <FontAwesomeIcon icon={faGlobeAmericas} />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Website</label>
                        </div>
                        :
                        null
                    }

                    {TheEmail !== "" ?
                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Email</label>
                        </div>
                        : null}
                    {twitter !== null ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <TwitterIcon
                                    size={window.innerWidth < 800 ? 15 : 35}
                                    iconFillColor="black"
                                    bgStyle={{ fill: "transparent" }}
                                />
                            </div>
                            <label className="preview-card-btn-lbl"
                                style={lblStyle}>Twitter</label>
                        </div>
                        :
                        null
                    }

                    {facebook !== null ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <FacebookIcon
                                    size={window.innerWidth < 800 ? 15 : 35}
                                    iconFillColor="black"
                                    bgStyle={{ fill: "transparent" }}
                                />
                            </div>
                            <label className="preview-card-btn-lbl" style={lblStyle}>
                                Facebook
                            </label>
                        </div>
                        :
                        null
                    }

                    {instagram !== null ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <img alt="" src={instagramIcon} className="btn-icon" />
                            </div>
                            <label className="preview-card-btn-lbl" style={lblStyle}>
                                Instagram
                            </label>
                        </div>
                        :
                        null
                    }

                    {linkedin !== null ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <LinkedinIcon
                                    size={window.innerWidth < 800 ? 15 : 35}
                                    iconFillColor="black"
                                    bgStyle={{ fill: "transparent" }}
                                />                            </div>
                            <label className="preview-card-btn-lbl" style={lblStyle}>
                                Linkedin
                            </label>
                        </div>
                        :
                        null
                    }

                    {youtube !== null ?

                        <div className="card-btn-div2">
                            <div className="card-btn-circle-div2"
                                style={btnStyle}
                            >
                                <img alt="" src={youtubeIcon} className="btn-icon" />
                            </div>
                            <label className="preview-card-btn-lbl" style={lblStyle}>youtube</label>
                        </div>
                        :
                        null
                    }

                </div>
            </div>
        )
    }

    getFullContactInfo = (device) => {
        return (
            <div>
                <div className="contact-div1">

                    <div className={device === "mobile" ? "hidden-info-btn-tiny" : "hidden-info-btn-prev"}>

                        <div className="bottom-btn-and-icon-div">
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faInfoCircle} />
                            <label className="bottom-btn-lbl">
                                Full Contact Info
                            </label>
                        </div>
                        <FontAwesomeIcon
                            className="bottom-btn-arrow-icon" icon={faChevronDown} />
                    </div>
                </div>
            </div>
        )
    }

    getVideo = (device) => {
        const { theCard } = this.state.theState
        let videoUrl = this.props.theState.videoUrl
        if (theCard !== undefined && theCard !== null && videoUrl === undefined) {
            videoUrl = theCard.videoUrl
        }
        if (videoUrl === "") {
            return (
                <div></div>
            )
        }

        return (
            <div>
                <div className="video-div1">

                    <div
                        className={device === "mobile" ? "hidden-video-btn-tiny" : "hidden-video-btn"}>

                        <div className="bottom-btn-and-icon-div">
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faInfoCircle} />
                            <label className="bottom-btn-lbl">
                                Watch Video
                            </label>
                        </div>

                    </div>
                </div>

            </div>
        )
    }

    getVideosGallary = (device) => {
        return (
            <div>
                <div className={device === "mobile" ? "hidden-info-btn-tiny"
                    : "hidden-info-btn-prev"}>

                    <div className="bottom-btn-and-icon-div">
                        <FontAwesomeIcon className="bottom-btn-icon"
                            icon={faVideo} />
                        <label className="bottom-btn-lbl">
                            Videos Gallery
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className="bottom-btn-arrow-icon" icon={faChevronDown} />
                </div>
            </div>
        )
    }

    getPhotoesGallary2 = (device) => {
        return (
            <div>
                <div className={device === "mobile" ? "hidden-info-btn-tiny"
                    : "hidden-info-btn-prev"}>

                    <div className="bottom-btn-and-icon-div">
                        <FontAwesomeIcon className="bottom-btn-icon"
                            icon={faImage} />
                        <label className="bottom-btn-lbl">
                            Photos Gallery
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className="bottom-btn-arrow-icon" icon={faChevronDown} />
                </div>


                <div className={device === "mobile" ? "gallery-div1-tiny" : "gallery-div1"}>
                </div>
            </div>
        )
    }
    getPhotoesGallary = (device) => {
        return (
            <div>
                <div className={device === "mobile" ? "hidden-info-btn-tiny"
                    : "hidden-info-btn-prev"}>

                    <div className="bottom-btn-and-icon-div">
                        <FontAwesomeIcon className="bottom-btn-icon"
                            icon={faImage} />
                        <label className="bottom-btn-lbl">
                            Photos Gallery
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className="bottom-btn-arrow-icon" icon={faChevronDown} />
                </div>

            </div>
        )
    }

    getLinkDiv = (device) => {
        const { theCard, linkTitle } = this.state.theState
        let theHtmlText = this.props.theState.currentContentAsHTML
        if (theCard !== undefined && theCard !== null && theHtmlText === undefined) {
            theHtmlText = theCard.currentContentAsHTML
        }
        let TheTitle = (theCard !== undefined && theCard !== null && theCard.linkTitle !== "") && linkTitle === "" ?
            theCard.linkTitle : linkTitle !== "" ? linkTitle : ""

        if (TheTitle === null || TheTitle === "") {
            return null
        }
        return (
            <div>
                <div className="contact-div1">

                    <div
                        className={device === "mobile" ? "hidden-info-btn-tiny" : "hidden-info-btn-prev"}>

                        <div className="bottom-btn-and-icon-div">
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faLink} />
                            <label className="bottom-btn-lbl">
                                {TheTitle}
                            </label>
                        </div>
                        <FontAwesomeIcon
                            className="bottom-btn-arrow-icon" icon={faChevronDown} />
                    </div>
                </div>
            </div>
        )
    }
    getAbout = (device) => {
        const { theCard, currentContentAsHTML } = this.state.theState
        let theHtmlText = this.props.theState.currentContentAsHTML
        if (theCard !== undefined && theCard !== null && theHtmlText === undefined) {
            theHtmlText = theCard.currentContentAsHTML
        }
        if (currentContentAsHTML !== undefined) {
            theHtmlText = currentContentAsHTML
        }

        return (
            <div>
                <div className="contact-div1">

                    <div
                        className={device === "mobile" ? "hidden-info-btn-tiny" : "hidden-info-btn-prev"}>

                        <div className="bottom-btn-and-icon-div">
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faInfoCircle} />
                            <label className="bottom-btn-lbl">
                                About
                            </label>
                        </div>
                        <FontAwesomeIcon
                            className="bottom-btn-arrow-icon" icon={faChevronDown} />
                    </div>
                </div>
            </div>
        )
    }

    convertContentToHTML = () => {
        const { editorState } = this.state.theState
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
        this.setState({
            currentContentAsHTML
        })
    }

    createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    getPersotSocialValue = (socialType) => {
        const { allSocialMedia } = this.state.theState
        var result = allSocialMedia.filter(obj => {
            return obj.type === socialType
        })
        let ans = ""
        if (result.length > 0) {
            if (result[0] !== undefined) {
                ans = result[0].value
            }
        }
        return ans
    }

    render() {
        const { device } = this.props

        const {
            theCard, selectedCardOption, selectedBGColor
        } = this.state.theState
        
        return (
            <div className={device === "mobile" ? "preview-tiny-phone-div-container" : "blabla2"}>

                {device === "mobile" ?
                    <button className="save-details-btn2"
                        onClick={this.props.saveEditedCard}
                    >
                        Save Edited Card
                    </button>
                    :
                    null}

                <div className={device === "mobile" ?
                    "preview-tiny-phone-div" : "preview-phone-div"}>
                    <div className={device === "mobile" ?
                        "preview-tiny-phone-inside-div" : "preview-phone-inside-div"}
                    >
                        <div className={"preview-phone-inside-div-scrollable"}>

                            <div className="top-stuff-div2"
                                style={getBackGroundStyle(theCard, selectedCardOption, selectedBGColor)}//,this.state.bgNames)}
                            >
                                {this.getPhoneMainImages(device)}

                                {this.getPhoneTitles(device)}

                                {this.getPhoneBtns(device)}

                            </div>
                            {this.getVideo(device)}

                            {this.getAbout(device)}

                            {this.getPhotoesGallary(device)}

                            {this.getVideosGallary(device)}

                            {this.getLinkDiv(device)}

                            {this.getFullContactInfo(device)}



                        </div>
                    </div>
                </div >

            </div>
        )
    }
}

export default compose(
    withFirebase,
)(PreviewPhone);
