import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import './HomeTopComponent.scss';

import ngPhoneImg from '../images/ng_phone2.png'
import holdingNgcarImg from '../images/holding_ngcard_img.png'

class HomeTopComponent extends Component {
    constructor() {
        super();
        this.state = {
            versionName: "",
            needToChangePassword: false,
        }
    }

    componentDidMount = async () => {
        this.getData()
    }

    getData = () => {
        let versionName = process.env["REACT_APP_VERSION_NAME"]
        this.props.firebase.getCurrentUser(
            async user1 => {
                let userEmail = user1.email
                let user = await this.props.firebase.getUserByEmail(userEmail)
                this.setState({
                    versionName,
                    needToChangePassword: user.needToChangePassword === true ? true : false
                })
            },
            () => {
                this.setState({ versionName })
            }
        )
    }

    goToCreate = () => {
        window.location.href = "/pricing-plan/"
    }

    getAnnoyingChangePsw = () => {
        if (this.state.needToChangePassword === true) {
            return (
                <div className="annoying-change-psw-main">
                    <button className="annoying-change-psw-btn-x"
                        onClick={() => {
                            this.setState({ needToChangePassword: false })
                        }}>X</button>

                    <label className="annoying-change-psw-lbl-main">
                        Important system notification!
                        You are signed in with a temporary password.
                        In order to secure your account please update
                        Your password by clicking the ok button.
                    </label>
                    <button className="annoying-change-psw-btn-main"
                        onClick={() => {
                            window.location.href = "/listing-author?dashboard=update-profile"
                        }}>OK</button>
                </div>
            )
        } else {
            return null
        }
    }

    getMobileDiv = () => {
        return (
            <div className="home-top-component-main-container-mobile" id="home" >

                {this.getAnnoyingChangePsw()}

                <div className="bg-overly-mobile">
                    <div className="home-top-right-div-mobile">
                        <label className="home-top-lbl111">Smart Digital</label>
                        <label className="home-top-lbl1111">Buisness Card</label>
                        <label className="home-top-lbl22">NetGo Card provides a</label>
                        <label className="home-top-lbl22">
                            professional digital business card
                        </label>
                        <label className="home-top-lbl22">
                            That allows a variety of endless
                        </label>
                        <label className="home-top-lbl22">possibilities!</label>

                        <label className="home-top-lbl-wh">
                            width: {window.innerWidth}, height: {window.innerHeight}
                        </label>

                        <label className="home-top-lbl-version">
                            version : {this.state.versionName}
                        </label>

                        <button
                            className="create-btn"
                            onClick={this.goToCreate}>
                            CREATE YOUR OWN CARD
                        </button>


                        <div className="bootom-lbl-and-img-div">
                            <label className="home-bottom-lbl1">
                                ❝ The most advanced and innovative digital
                            </label>
                            <label className="home-bottom-lbl2">
                                card for corporates and individuals ❞
                            </label>
                            <img alt="" src={holdingNgcarImg}
                                className="holding_Ngcar_img"
                            />
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    getDesktopDiv = () => {
        return (

            <div className="home-top-component-main-container-desktop" id="home" >

                {this.getAnnoyingChangePsw()}

                <div className="bg-overly-desktop">
                    <div className="home-top-left-div">

                        <img alt="ngPhoneImg" src={ngPhoneImg} className="ng-phone-img" />
                    </div>

                    <div className="home-top-right-div-desktop">

                        <label className="home-top-lbl1">Smart Digital</label>
                        <label className="home-top-lbl11">Business  Card</label>
                        <label className="home-top-lbl2">
                            NetGo Card provides a professional
                        </label>
                        <label className="home-top-lbl2">
                            digital business card that allows a variety
                        </label>
                        <label className="home-top-lbl2">
                            of endless possibilities!
                        </label>

                        <label className="home-top-lbl-wh">
                            width: {window.innerWidth}, height: {window.innerHeight}
                        </label>

                        <label className="home-top-lbl-version">
                            version : {this.state.versionName}
                        </label>

                        <button
                            className="create-btn"
                            onClick={this.goToCreate}>
                            CREATE YOUR OWN CARD
                        </button>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (window.innerWidth < 1100) {
            return this.getMobileDiv()
        }
        else {
            return this.getDesktopDiv()
        }
    }
}

export default compose(withFirebase)(HomeTopComponent)