
import React, { Component } from "react";
import Select from "react-select";
import "./MainInfo.scss";

class MainInfo extends Component {
    constructor() {
        super();
        this.state = {
            businessCardTitle: null,
            businessJobTitle: null,
            industryOptions: [
                { value: "Agriculture", label: "Agriculture" },
                { value: "Communication Services", label: "Communication Services" },
                { value: "Construction and Infrastructure", label: "Construction and Infrastructure" },
                { value: "Education", label: "Education" },
                { value: "Energy", label: "Energy" },
                { value: "Entertainment", label: "Entertainment" },
                { value: "Finance & Insurance", label: "Finance & Insurance" },
                { value: "Food & Hospitality", label: "Food & Hospitality" },
                { value: "General Business", label: "General Business" },
                { value: "Government and Politics", label: "Government and Politics" },
                { value: "Health Care", label: "Health Care" },
                { value: "Industrials", label: "Industrials" },
                { value: "Information Technology", label: "Information Technology" },
                { value: "Legal", label: "Legal" },
                { value: "Media", label: "Media" },
                { value: "Personal Services", label: "Personal Services" },
                { value: "Real Estate", label: "Real Estate" },
                { value: "Retail", label: "Retail" },
                { value: "Security and Safety", label: "Security and Safety" },
                { value: "Transportation", label: "Transportation" },
                { value: "Utilities", label: "Utilities" },
                { value: "Others", label: "Others" },
            ],
            selectedIndustryOption: "",
        }
    }
    componentDidMount() { }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.onChangeP(event)
        });
    }
    handleIndustryOptionsChange = selectedOption => {
        this.setState({ selectedIndustryOption: selectedOption }, () => {
            this.props.setIndustry(selectedOption)
        })
    }

    render() {
        const theCard = this.props.theCard || null
        const {
            businessCardTitle, businessJobTitle, selectedIndustryOption, industryOptions
        } = this.state
        let industryOption = (theCard !== null && theCard.industry !== ""
            && selectedIndustryOption === "") ?
            { value: theCard.industry, label: theCard.industry }
            :
            selectedIndustryOption
        return (

            <div className="add-details-container">

                <label className="add-top-lbl">
                    Main Contact Information
                </label>

                <div className="add-details-title-div">

                    <label className="add-details-lbl">
                        Business Card Title (Name Or Business Name)
                    </label>

                    <label className="add-details-must-lbl">*</label>
                </div>

                <input
                    className="add-details-input"
                    name="businessCardTitle"
                    value={theCard !== null && businessCardTitle === null ?
                        theCard.businessCardTitle : businessCardTitle === null ? "" : businessCardTitle}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Your Title"
                />

                <label className="add-details-lbl">
                    Second Line Title
                </label>

                <input
                    className="add-details-input"
                    name="businessJobTitle"
                    value={theCard !== null && businessJobTitle === null ?
                        theCard.businessJobTitle : businessJobTitle === null ? "" : businessJobTitle}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Your Job Title"
                />

                <label className="add-details-lbl">
                    Industry / line of buisness
                </label>

                <Select
                    className="my-industry-select"
                    isSearchable={false}
                    value={industryOption}
                    onChange={this.handleIndustryOptionsChange}
                    options={industryOptions}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={{
                        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                />
            </div>
        )
    }
}

export default MainInfo;