import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NavBar from './NavBar'
import SignUpPage from '../UserLogin/SignUpPage'
import SignInPage from '../UserLogin/SignInPage'
import PasswordForgetPage from '../UserLogin/PasswordForget';
import HomePage from './HomePage'
import ChoosePlanPage from './ChoosePlanPage'
import CardPage from './CardPage'
import ADashboardPage from '../dashboard/ADashboardPage'
import CreateCardPage from '../createCard/CreateCardPage'
import EditCardPage from './EditCardPage'
import ExcelToMultipeCardsPage from './ExcelToMultipeCardsPage'
import AdminSystem from './AdminSystem'
import CheckOutPage from './CheckOutPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import PrivacyPolicyPlayPage from './PrivacyPolicyPlayPage'
import './App.scss'

function App() {
    return (
        <Router>
            <div className="main-container1">

                <NavBar />
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/home" exact component={HomePage} />
                    <Route path="/card/:name/:id" exact component={CardPage} />
                    <Route path="/editcard/:name/:id" exact component={EditCardPage} />
                    <Route path="/pricing-plan" exact component={ChoosePlanPage} />
                    <Route path="/listing-author" exact component={ADashboardPage} />
                    <Route path="/login" exact component={SignInPage} />
                    <Route path="/register" exact component={SignUpPage} />
                    <Route path="/forgotpassword" exact component={PasswordForgetPage} />
                    <Route path="/submit-listing" exact component={CreateCardPage} />
                    <Route path="/payment-checkout" exact component={CheckOutPage} />
                    <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
                    <Route path="/privacy-notice" exact component={PrivacyPolicyPlayPage} />
                    <Route path="/read-excel" exact component={ExcelToMultipeCardsPage} />
                    <Route path="/admin-system" exact component={AdminSystem} />
                </Switch>
            </div>
        </Router>
    )
}

export default App