
import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import 'react-image-crop/dist/ReactCrop.css';
import './CreateCardImages.scss';

class CreateCardImages extends Component {
    constructor() {
        super();
        this.state = {
            srcFace: null,
            cropFace: {
                unit: '%',
                width: 100,
                aspect: 1 / 1,
            },

            srcCover: null,
            cropCover: {
                unit: '%',
                width: 200,
                aspect: 16 / 9,
            },
        }
    }
    componentDidMount() { }

    getAcceptedFilesFace = async (acceptedFiles) => {
        let img = acceptedFiles[0]
        let size = acceptedFiles[0].size / 1024 // in KB

        if (size <= 1000) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ srcFace: reader.result })

            );
            reader.readAsDataURL(img);
        } else {
            alert("image too big! please pick an image smaller then 1MB")
        }
    }
    getDropzoneDiv = () => {
        let text1 = window.innerWidth < 1100 ? "click to add Image" : "Drag and Drop a file or click to add Image"
        let text2 = "accepted Image Sizes: 1MB or less"

        return (
            <div className="the-dropzone">
                <label className="the-dropzone-lbl1">
                    {text1}
                </label>
                <label className="the-dropzone-lbl1">
                    {text2}
                </label>
            </div>
        )
    }

    onCropFaceComplete = cropFace => {
        this.makeClientCropFace(cropFace);
    }
    onCropCoverComplete = cropCover => {
        this.makeClientCropCover(cropCover);
    }

    onFaceImageLoaded = image => {
        this.imageRef1 = image;
    }
    onCoverImageLoaded = image => {
        this.imageRef2 = image;
    }

    async makeClientCropCover(cropCover) {
        if (this.imageRef2 && cropCover.width && cropCover.height) {
            const croppedCoverImageUrl = await this.getCroppedCoverImg(
                this.imageRef2,
                cropCover,
                'newFile2.jpeg'
            );
            this.setState({ croppedCoverImageUrl }, () => {
                this.props.setCoverImg(croppedCoverImageUrl)
            })
        }
    }
    async makeClientCropFace(cropFace) {
        if (this.imageRef1 && cropFace.width && cropFace.height) {
            const croppedFaceImageUrl = await this.getCroppedFaceImg(
                this.imageRef1,
                cropFace,
                'newFile1.jpeg'
            );
            this.setState({ croppedFaceImageUrl }, () => {
                this.props.setFaceImg(croppedFaceImageUrl)
            })
        }
    }

    onCropCoverChange = cropCover => {
        this.setState({ cropCover });
    }
    onCropFaceChange = (cropFace, percentcropFace) => {
        this.setState({ cropFace });
    }


    getCroppedCoverImg(image, cropCover, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.ceil(cropCover.width * scaleX);
        canvas.height = Math.ceil(cropCover.height * scaleY);
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            cropCover.x * scaleX,
            cropCover.y * scaleY,
            cropCover.width * scaleX,
            cropCover.height * scaleY,
            0,
            0,
            cropCover.width * scaleX,
            cropCover.height * scaleY,
        );
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });


    }
    getCroppedFaceImg(image, cropFace, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.ceil(cropFace.width * scaleX);
        canvas.height = Math.ceil(cropFace.height * scaleY);
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            cropFace.x * scaleX,
            cropFace.y * scaleY,
            cropFace.width * scaleX,
            cropFace.height * scaleY,
            0,
            0,
            cropFace.width * scaleX,
            cropFace.height * scaleY,
        );
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });

    }
    getAcceptedFilesCover = async (acceptedFiles) => {
        let img = acceptedFiles[0]
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ srcCover: reader.result })
        );
        reader.readAsDataURL(img);

    }

    saveProfileImg = () => {
        this.setState({
            profileImgSelected: true,
            srcFace: null

        })
    }
    cancelProfileImg = () => {
        this.setState({
            profileImgSelected: true,
            srcFace: null,
            croppedFaceImageUrl: null,
        }, () => {
            this.props.cancelCroppedFaceImg()
        })
    }
    saveCoverImg = () => {
        this.setState({
            coverImgSelected: true,
            srcCover: null
        })
    }
    cancelCoverImg = () => {
        this.setState({
            coverImgSelected: true,
            srcCover: null,
            croppedCoverImageUrl: null,
        }, () => {
            this.props.cancelCroppedCoverImg()
        })
    }

    render() {
        const { srcFace, cropFace, srcCover, cropCover, } = this.state
        return (

            <div className="upload-images-div">

                <div className="upload-image-inside-div">

                    <label className="add-top-lbl">
                        Upload Profile Logo/Image
                    </label>

                    <div className="add-img-container">

                        {srcFace === null ?
                            <Dropzone
                                onDrop={acceptedFiles => this.getAcceptedFilesFace(acceptedFiles)}>

                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            {this.getDropzoneDiv()}

                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            :
                            <ReactCrop
                                src={srcFace}
                                crop={cropFace}
                                ruleOfThirds
                                onImageLoaded={this.onFaceImageLoaded}
                                onComplete={this.onCropFaceComplete}
                                onChange={this.onCropFaceChange}
                            />
                        }

                    </div>
                    <div className="add-img-btns-container">
                        <button className="add-img-btn"
                            onClick={this.cancelProfileImg}>Cancel</button>
                        <button className="add-img-btn"
                            onClick={this.saveProfileImg}>OK</button>
                    </div>
                </div>
                {this.props.canEditStyle === true ?
                    <div className="upload-image-inside-div">
                        <label className="add-top-lbl">
                            Upload Cover Logo/Image
                        </label>

                        <div className="add-img-container">

                            {srcCover === null ?
                                <Dropzone
                                    onDrop={acceptedFiles => this.getAcceptedFilesCover(acceptedFiles)}>

                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />

                                                {this.getDropzoneDiv()}

                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                :
                                <ReactCrop
                                    src={srcCover}
                                    crop={cropCover}
                                    ruleOfThirds
                                    onImageLoaded={this.onCoverImageLoaded}
                                    onComplete={this.onCropCoverComplete}
                                    onChange={this.onCropCoverChange}
                                />
                            }

                        </div>
                        <div className="add-img-btns-container">
                            <button className="add-img-btn" onClick={this.cancelCoverImg}>Cancel</button>
                            <button className="add-img-btn" onClick={this.saveCoverImg}>OK</button>
                        </div>

                    </div>
                    :
                    null}

            </div>
        )
    }
}

export default compose(withFirebase)(CreateCardImages);