
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import sessionstorage from 'sessionstorage'
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHeart, faUserCircle, faLock, faArrowLeft, faDownload, faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import BuisnessCardsPage from './BuisnessCardsPage'
import MyCardsBookPage from './MyCardsBookPage'
import MyProfilePage from './MyProfilePage'

import './ADashboardPage.scss';

class ADashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
            downloadUrl: "",
            gettingAppFromServer: false,
        }
    }
    componentDidMount = async () => {
        const nowIn = this.props.location.search
        let selected = 1
        switch (nowIn) {
            case "?dashboard=listings":
                selected = 1
                break
            case "?dashboard=saved":
                selected = 2
                break
            case "?dashboard=update-profile":
                selected = 3
                break
            default:
                selected = 1
                break
        }

        this.setState({
            selected
        })

    }
    goToSelected = (selected) => {
        this.setState({ selected }, () => {
            switch (selected) {
                case 1:
                    window.location.href = "/listing-author?dashboard=listings"
                    break
                case 2:
                    window.location.href = "/listing-author?dashboard=saved"
                    break
                case 3:
                    window.location.href = "/listing-author?dashboard=update-profile"
                    break
                default:
                    window.location.href = "/listing-author?dashboard=listings"
                    break
            }
        })
    }
    logout = async () => {
        this.setState({ selected: 6 }, async () => {
            await this.props.firebase.doSignOut()
            sessionstorage.removeItem("userEmail")
            sessionstorage.removeItem("userId")
            sessionstorage.removeItem("userName")
            window.location.href = "/"
        })
    }
    goBackToHomePage = () => {
        this.setState({ selected: 7 }, () => {
            window.location.href = "/"
        })
    }
    downloadAndroidApp = async () => {
        this.setState({
            selected: 8,
            gettingAppFromServer: true
        }, async () => {
            let downloadUrl = await this.props.firebase.getAndroidAppUrl()
            this.setState({
                gettingAppFromServer: false,
                downloadUrl,
            }, () => {
                this.downloadApp()
            })
        })
    }

    downloadApp = () => {
        const { downloadUrl } = this.state
        const link = window.document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    getBrowserViewLeftNavbar = () => {
        const { selected, gettingAppFromServer } = this.state
        const selectedTabStyle = {
            borderLeft: "4px solid skyblue", backgroundColor: "#435363"
        }
        return (
            <div className="left-navbar">

                <div className="left-navbar-top-btn" >
                    <label className="left-navbar-title">My Account</label>
                </div>

                <div className="left-navbar-btn"
                    style={selected === 1 ? selectedTabStyle : null}
                    onClick={() => { this.goToSelected(1) }}
                >
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <label className="left-navbar-label">My Buisness Cards</label>
                </div>

                <div className="left-navbar-btn"
                    style={selected === 2 ? selectedTabStyle : null}
                    onClick={() => { this.goToSelected(2) }}
                >
                    <FontAwesomeIcon icon={faHeart} />
                    <label className="left-navbar-label">My Cards Book</label>
                </div>

                <div className="left-navbar-btn"
                    style={(selected === 3) ? selectedTabStyle : null}
                    onClick={() => { this.goToSelected(3) }}
                >
                    <FontAwesomeIcon icon={faUserCircle} />
                    <label className="left-navbar-label">My Profile</label>
                </div>

                <div className="left-navbar-btn"
                    style={(selected === 6) ? selectedTabStyle : null}
                    onClick={this.logout}
                >
                    <FontAwesomeIcon icon={faLock} />
                    <label className="left-navbar-label">Logout</label>
                </div>

                <div className="left-navbar-btn"
                    style={(selected === 7) ? selectedTabStyle : null}
                    onClick={this.goBackToHomePage}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <label className="left-navbar-label">Back to Homepage</label>
                </div>

                <button
                    onClick={this.downloadAndroidApp}
                    className="left-navbar-btn"
                    style={selected === 8 ? selectedTabStyle : null}
                >
                    {gettingAppFromServer === true ?
                        <ReactLoading type={"spinningBubbles"} color="white"
                            height={10} width={10}
                        />
                        :
                        <FontAwesomeIcon icon={faDownload} />
                    }
                    <label className="left-navbar-label">Download Android App</label>
                </button>

            </div>
        )
    }

    render() {
        const { selected } = this.state

        return (
            <div className="dashboard-main-container">

                {window.innerWidth < 1100 ?
                    null
                    :
                    this.getBrowserViewLeftNavbar()
                }

                <div className="inside-main"
                    style={{ width: window.innerWidth < 1100 ? "100%" : "84%" }}
                >
                    {
                        selected === 1 ?
                            <BuisnessCardsPage />
                            : selected === 2 ?
                                <MyCardsBookPage />
                                : selected === 3 ?
                                    <MyProfilePage />
                                    :
                                    null
                    }
                </div>

            </div>
        )
    }
}
export default compose(withFirebase)(ADashboardPage);