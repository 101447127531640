
import React, { Component } from 'react';
import BottomBar from './BottomBar'
import './PrivacyPolicyPlayPage.scss';

class PrivacyPolicyPlayPage extends Component {
    constructor() {
        super();
        this.state = {}
    }
    componentDidMount() { }

    render() {
        return (

            <div id="top" className="privacy-main-container">

                <div className="choose-plan-title-bg"></div>
                <div className="title-overly">---Privacy Notice---</div>

                <div className="privacy-text-container">

                    <div>
                        <br />
                    </div>
                    <div>
                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                            <strong>
                                <span style={{ fontSize: " 15px" }}>
                                    <span data-custom-className="subtitle">
                                        Last updated <bdt className="question">
                                            October 07, 2021</bdt>
                                    </span>
                                </span>
                            </strong>
                        </span>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                <span data-custom-className="body_text">
                                    Thank you for choosing to be part of our community at <bdt className="question">
                                        NetGoCard</bdt>
                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            <bdt className="block-component">
                                            </bdt>
                                        </span>
                                    </span>
                                    ("<span style={{ color: " rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            <bdt className="block-component">
                                            </bdt>
                                        </span>
                                    </span>
                                    <strong>
                                        Company</strong>
                                </span>
                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <bdt className="statement-end-if-in-editor">
                                                        <span data-custom-className="body_text">
                                                        </span>
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span data-custom-className="body_text">
                                    ," "<strong>
                                        we</strong>
                                    ," "<strong>
                                        us</strong>
                                    ," or "<strong>
                                        our</strong>
                                    "). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at <bdt className="question">
                                        info@netgocard.com</bdt>
                                    .</span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: " 15px" }}>
                            <br />
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: " 15px" }}>
                            <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                <span data-custom-className="body_text">
                                    This privacy notice describes how we might use your information if you:</span>
                            </span>
                        </span>
                        <span style={{ fontSize: " 15px" }}>
                            <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                <span data-custom-className="body_text">
                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            <bdt className="block-component">
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <div>
                            <bdt className="block-component">
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span data-custom-className="body_text">
                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        <bdt className="forloop-component">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </bdt>
                        </div>
                        <ul>
                            <li style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            Download and use our mobile application<bdt className="block-component">
                                            </bdt>
                                            — <bdt className="question">
                                                NetGoCard (NG Card)<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span data-custom-className="body_text">
                                                            <span style={{ fontSize: " 15px" }}>
                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                    <span data-custom-className="body_text">
                                                                        <span style={{ fontSize: " 15px" }}>
                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span style={{ fontSize: " 15px" }}>
                                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                        <span data-custom-className="body_text">
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <bdt className="forloop-component">
                                                                    </bdt>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span data-custom-className="body_text">
                                            <span style={{ fontSize: " 15px" }}>
                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <span style={{ fontSize: " 15px" }}>
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <bdt className="statement-end-if-in-editor">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <div>
                            <bdt className="block-component">
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span data-custom-className="body_text">
                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        <bdt className="forloop-component">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                        <span data-custom-className="body_text">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <bdt className="forloop-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </bdt>
                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                    <span data-custom-className="body_text">
                                        <span style={{ fontSize: " 15px" }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px" }}>
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <bdt className="statement-end-if-in-editor">
                                                            </bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                        <span data-custom-className="body_text">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <bdt className="block-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                Engage with us in other related ways ― including any sales, marketing, or events<span style={{ fontSize: " 15px" }}>
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <span data-custom-className="body_text">
                                                            <span style={{ fontSize: " 15px" }}>
                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                    <bdt className="statement-end-if-in-editor">
                                                                    </bdt>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                        <span data-custom-className="body_text">
                                            In this privacy notice, if we refer to:</span>
                                    </span>
                                </span>
                                <span style={{ fontSize: " 15px" }}>
                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                        <span data-custom-className="body_text">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <bdt className="block-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <div>
                                    <bdt className="block-component">
                                        <span style={{ fontSize: " 15px" }}>
                                            <span style={{ fontSize: " 15px" }}>
                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <bdt className="block-component">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </bdt>
                                </div>
                                <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    "<strong>
                                                        App</strong>
                                                    " we are referring to any application of ours that references or links to this policy, including any listed above</span>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px" }}>
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px" }}>
                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                        <bdt className="statement-end-if-in-editor">
                                                                        </bdt>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                                <div>
                                    <bdt className="block-component">
                                        <span style={{ fontSize: " 15px" }}>
                                            <span style={{ fontSize: " 15px" }}>
                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <bdt className="block-component">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </bdt>
                                </div>
                                <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    "<strong>
                                                        Services</strong>
                                                    " we are referring to our<span style={{ fontSize: " 15px" }}>
                                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                    <span data-custom-className="body_text">
                                                                        <bdt className="block-component">
                                                                            <bdt className="block-component">
                                                                            </bdt>
                                                                        </bdt>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: " 15px" }}>
                                            <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                <span data-custom-className="body_text">
                                                    App,</span>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px" }}>
                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: " 15px" }}>
                                            <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                <span data-custom-className="body_text">
                                                    and other related services, including any sales, marketing, or events<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px" }}>
                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span data-custom-className="body_text">
                                                The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <br />
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <strong>
                                                        Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</strong>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <br />
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(0, 0, 0){5} " }}>
                                                <strong>
                                                    <span data-custom-className="heading_1">
                                                        TABLE OF CONTENTS</span>
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <br />
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#infocollect">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                1. WHAT INFORMATION DO WE COLLECT?</span>
                                        </a>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                            <a data-custom-className="link" href="#infoshare">
                                                2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
                                        </span>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                    <bdt className="block-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <bdt className="block-component">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#inforetain">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                3. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                                        </a>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                            <bdt className="block-component">
                                                            </bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#infosafe">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                4. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                                        </a>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                        <bdt className="statement-end-if-in-editor">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                            <a data-custom-className="link" href="#privacyrights">
                                                5. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#DNT">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                6. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                                        </a>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#caresidents">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                                        </a>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px" }}>
                                        <a data-custom-className="link" href="#policyupdates">
                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                8. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                                        </a>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <a data-custom-className="link" href="#contact">
                                        <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                            9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                    </a>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <a data-custom-className="link" href="#request">
                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                            10. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</span>
                                    </a>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div id="infocollect" style={{ lineHeight: "1.5" }}>
                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                        <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                    <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                        <strong>
                                                            <span data-custom-className="heading_1">
                                                                1. WHAT INFORMATION DO WE COLLECT?</span>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span data-custom-className="heading_2" style={{ color: " rgb(0, 0, 0){5} " }}>
                                        <span style={{ fontSize: " 15px" }}>
                                            <strong>
                                                <u>
                                                    <br />
                                                </u>
                                            </strong>
                                            <strong>
                                                Personal information you disclose to us</strong>
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <div>
                                        <br />
                                    </div>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <strong>
                                                                    <em>
                                                                        In Short:</em>
                                                                </strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <strong>
                                                                    <em>
                                                                    </em>
                                                                </strong>
                                                                <em>
                                                                    We collect personal information that you provide to us.</em>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                We collect personal information that you voluntarily provide to us when you <span style={{ fontSize: " 15px" }}>
                                                    <bdt className="block-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                            <span data-custom-className="body_text">
                                                express an interest in obtaining information about us or our products and Services, when you participate in activities on the <span style={{ fontSize: " 15px" }}>
                                                    <bdt className="block-component">
                                                    </bdt>
                                                    <bdt className="block-component">
                                                    </bdt>
                                                    <bdt className="block-component">
                                                    </bdt>
                                                    App<bdt className="statement-end-if-in-editor">
                                                    </bdt>
                                                </span>
                                                <bdt className="block-component">
                                                </bdt>
                                            </span>
                                        </span>
                                        <span data-custom-className="body_text">
                                            or otherwise when you contact us.</span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                The personal information that we collect depends on the context of your interactions with us and the <span style={{ fontSize: " 15px" }}>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                        App<bdt className="statement-end-if-in-editor">
                                                        </bdt>
                                                    </span>
                                                    , the choices you make and the products and features you use. The personal information we collect may include the following:<bdt className="block-component">
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                <strong>
                                                    Personal Information Provided by You.</strong>
                                                We collect <span style={{ fontSize: " 15px" }}>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="forloop-component">
                                                        </bdt>
                                                        <bdt className="question">
                                                            email addresses</bdt>
                                                    </span>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="forloop-component">
                                                        </bdt>
                                                        <bdt className="question">
                                                            passwords</bdt>
                                                    </span>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="forloop-component">
                                                        </bdt>
                                                        and other similar information.</span>
                                                    <span data-custom-className="body_text">
                                                        <bdt className="statement-end-if-in-editor">
                                                        </bdt>
                                                        <bdt className="block-component">
                                                            <bdt className="block-component">
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.<bdt className="block-component">
                                                </bdt>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px" }}>
                                                        <bdt className="statement-end-if-in-editor">
                                                            <bdt className="statement-end-if-in-editor">
                                                                <span style={{ fontSize: " 15px" }}>
                                                                    <span data-custom-className="body_text">
                                                                        <span style={{ fontSize: " 15px" }}>
                                                                            <span data-custom-className="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                        <bdt className="block-component">
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span data-custom-className="heading_2" style={{ color: " rgb(0, 0, 0){5} " }}>
                                        <span style={{ fontSize: " 15px" }}>
                                            <strong>
                                                <u>
                                                    <br />
                                                </u>
                                            </strong>
                                            <strong>
                                                Information collected through our App</strong>
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <div>
                                        <br />
                                    </div>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <strong>
                                                                    <em>
                                                                        In Short:</em>
                                                                </strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                                <span data-custom-className="body_text">
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <strong>
                                                                    <em>
                                                                    </em>
                                                                </strong>
                                                                <em>
                                                                    We collect information regarding your <span data-custom-className="body_text" style={{ fontSize: " 15px" }}>
                                                                        <bdt className="block-component">
                                                                        </bdt>
                                                                        <bdt className="block-component">
                                                                        </bdt>
                                                                        mobile device,<bdt className="statement-end-if-in-editor">
                                                                        </bdt>
                                                                        <bdt className="block-component">
                                                                        </bdt>
                                                                        <bdt className="forloop-component">
                                                                        </bdt>
                                                                        <bdt className="block-component">
                                                                        </bdt>
                                                                        <bdt className="forloop-component">
                                                                        </bdt>
                                                                    </span>
                                                                    when you use our App.</em>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                            <span data-custom-className="body_text">
                                                If you use our App, we also collect the following information:<bdt className="block-component">
                                                </bdt>
                                            </span>
                                        </span>
                                    </span>
                                    <div>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: " 15px" }}>
                                            </span>
                                        </bdt>
                                    </div>
                                    <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                    <span data-custom-className="body_text">
                                                        <em>
                                                            Mobile Device Access.</em>
                                                        We may request access or permission to certain features from your mobile device, including your mobile device's <span style={{ fontSize: " 15px" }}>
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <span style={{ fontSize: " 15px" }}>
                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="forloop-component">
                                                                                            </bdt>
                                                                                            <bdt className="question">
                                                                                                manage phone calls</bdt>
                                                                                            , <bdt className="forloop-component">
                                                                                            </bdt>
                                                                                            <bdt className="question">
                                                                                                phone call logs</bdt>
                                                                                            , <bdt className="forloop-component">
                                                                                            </bdt>
                                                                                            <bdt className="question">
                                                                                                send sms messages</bdt>
                                                                                            , <bdt className="forloop-component">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        and other features. If you wish to change our access or permissions, you may do so in your device's settings.<span style={{ fontSize: " 15px" }}>
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <span style={{ fontSize: " 15px" }}>
                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                            <bdt className="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                    <div>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: " 15px" }}>
                                            </span>
                                        </bdt>
                                        <div>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: " 15px" }}>
                                                </span>
                                            </bdt>
                                            <div>
                                                <bdt className="block-component">
                                                    <bdt className="forloop-component">
                                                        <span style={{ fontSize: " 15px" }}>
                                                        </span>
                                                    </bdt>
                                                    <span style={{ fontSize: " 15px" }}>
                                                    </span>
                                                </bdt>
                                                <bdt className="forloop-component">
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.<span style={{ fontSize: " 15px" }}>
                                                                    <span data-custom-className="body_text">
                                                                        <span style={{ fontSize: " 15px" }}>
                                                                            <span data-custom-className="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                    <span data-custom-className="body_text">
                                                                        <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                            <span data-custom-className="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                    <bdt className="block-component">
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <bdt className="block-component">
                                                        </bdt>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div id="infoshare" style={{ lineHeight: "1.5" }}>
                                                    <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                        <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                        <strong>
                                                                            <span data-custom-className="heading_1">
                                                                                2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span>
                                                                        </strong>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <strong>
                                                                    <em>
                                                                        In Short:</em>
                                                                </strong>
                                                                <em>
                                                                    We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <br />
                                                </div>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                We may process or share your data that we hold based on the following legal basis:<bdt className="block-component">
                                                                </bdt>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Consent:</strong>
                                                                    We may process your data if you have given us specific consent to use your personal information for a specific purpose.<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="block-component">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Legitimate Interests:</strong>
                                                                    We may process your data when it is reasonably necessary to achieve our legitimate business interests.<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <bdt className="block-component">
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Performance of a Contract:</strong>
                                                                    Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <bdt className="block-component">
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Legal Obligations:</strong>
                                                                    We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <bdt className="block-component">
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Vital Interests:</strong>
                                                                    We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.<span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                More specifically, we may need to process your data or share your personal information in the following situations:</span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <ul>
                                                    <li style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <strong>
                                                                        Business Transfers.</strong>
                                                                    We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                            <span data-custom-className="body_text">
                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                        <span data-custom-className="body_text">
                                                                            <bdt className="block-component">
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: " 15px" }}>
                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                <span data-custom-className="body_text">
                                                                    <bdt className="block-component">
                                                                    </bdt>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div>
                                                            <span style={{ fontSize: " 15px" }}>
                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                    <span data-custom-className="body_text">
                                                                        <bdt className="block-component">
                                                                        </bdt>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                            <div>
                                                                <span style={{ fontSize: " 15px" }}>
                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                        <span style={{ fontSize: " 15px" }}>
                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                <span data-custom-className="body_text">
                                                                                    <bdt className="block-component">
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <div>
                                                                    <span style={{ fontSize: " 15px" }}>
                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                            <span data-custom-className="body_text">
                                                                                <bdt className="block-component">
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <div>
                                                                        <span style={{ fontSize: " 15px" }}>
                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                <span data-custom-className="body_text">
                                                                                    <bdt className="block-component">
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="block-component">
                                                                                            </bdt>
                                                                                        </span>
                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                            <bdt className="block-component">
                                                                                                <span data-custom-className="body_text">
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <span data-custom-className="body_text">
                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                    <bdt className="block-component">
                                                                                    </bdt>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <bdt className="block-component">
                                                                                                        <span data-custom-className="heading_1">
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                        <span data-custom-className="body_text">
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                        </span>
                                                                                                                        <bdt className="block-component">
                                                                                                                            <span data-custom-className="body_text">
                                                                                                                                <bdt className="block-component">
                                                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                                                        <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                                                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                                                                <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                                                                        <span data-custom-className="body_text">
                                                                                                                                                                            <bdt className="block-component">
                                                                                                                                                                            </bdt>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </bdt>
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="inforetain" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            3. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <strong>
                                                                                                <em>
                                                                                                    In Short:</em>
                                                                                            </strong>
                                                                                            <em>
                                                                                                We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        <bdt className="block-component">
                                                                                                        </bdt>
                                                                                                        <bdt className="question">
                                                                                                            1 year</bdt>
                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            .</span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="infosafe" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            4. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <strong>
                                                                                                <em>
                                                                                                    In Short:</em>
                                                                                            </strong>
                                                                                            <em>
                                                                                                We aim to protect your personal information through a system of organizational and technical security measures.</em>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                            <span data-custom-className="body_text">
                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                        <span data-custom-className="body_text">
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            App<bdt className="statement-end-if-in-editor">
                                                                                                                            </bdt>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            is at your own risk. You should only access the <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                            <span data-custom-className="body_text">
                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                        <span data-custom-className="body_text">
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                                                                                            </bdt>
                                                                                                                            App<bdt className="statement-end-if-in-editor">
                                                                                                                            </bdt>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            within a secure environment.<span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                </bdt>
                                                                                            </span>
                                                                                            <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="privacyrights" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            5. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <strong>
                                                                                                <em>
                                                                                                    In Short:</em>
                                                                                            </strong>
                                                                                            <em>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <em>
                                                                                                                <bdt className="block-component">
                                                                                                                </bdt>
                                                                                                            </em>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                                In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <em>
                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                </bdt>
                                                                                                            </em>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                                You may review, change, or terminate your account at any time.</em>
                                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure (iii) to restrict the processing of your personal information and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <a data-custom-className="link" href="#contact">
                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                    <span style={{ color: " rgb(48, 48, 241){5} " }}>
                                                                                                        contact details</span>
                                                                                                </span>
                                                                                            </a>
                                                                                            <p> provided below. We will consider and act upon any request in accordance with applicable data protection laws.
                                                                                            </p>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        <span style={{ color: " rgb(48, 48, 241){5} " }}>
                                                                                                            <span data-custom-className="body_text">
                                                                                                                <a data-custom-className="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank">
                                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span>
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            .</span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        <span style={{ color: " rgb(48, 48, 241){5} " }}>
                                                                                                            <span data-custom-className="body_text">
                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                    <a data-custom-className="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">
                                                                                                                        https://www.edoeb.admin.ch/edoeb/en/home.html</a>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            .<bdt className="block-component">
                                                                                            </bdt>
                                                                                            <bdt className="block-component">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span data-custom-className="body_text">
                                                                                    <bdt className="block-component">
                                                                                    </bdt>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="DNT" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            6. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="caresidents" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <strong>
                                                                                                <em>
                                                                                                    In Short:</em>
                                                                                            </strong>
                                                                                            <em>
                                                                                                Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            If you are under 18 years of age, reside in California, and have a registered account with <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                    <span data-custom-className="body_text">
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        the App<bdt className="statement-end-if-in-editor">
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            , you have the right to request removal of unwanted data that you publicly post on the <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                    <span data-custom-className="body_text">
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        App<bdt className="statement-end-if-in-editor">
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            . To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                    <span data-custom-className="body_text">
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                        App<bdt className="statement-end-if-in-editor">
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                            , but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).<span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="policyupdates" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            8. DO WE MAKE UPDATES TO THIS NOTICE?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <em>
                                                                                    <br />
                                                                                </em>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <em>
                                                                                                <strong>
                                                                                                    In Short:</strong>
                                                                                                Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="contact" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            If you have questions or comments about this notice, you may <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                    email us at <bdt className="question">
                                                                                                        info@netgocard.com</bdt>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                    <span data-custom-className="body_text">
                                                                                                        or by post to:</span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <bdt className="question">
                                                                                                                NetGoCard</bdt>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span data-custom-className="body_text">
                                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                <span data-custom-className="body_text">
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                    <span data-custom-className="body_text">
                                                                                        <bdt className="question">
                                                                                            Tel Aviv</bdt>
                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                    <span data-custom-className="body_text">
                                                                                        <bdt className="question">
                                                                                            __________</bdt>
                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                            </span>
                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                                <bdt className="block-component">
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                    <span data-custom-className="body_text">
                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                            <span data-custom-className="body_text">
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <bdt className="block-component">
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <bdt className="question">
                                                                                            Israel</bdt>
                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                            <span data-custom-className="body_text">
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                                            <span data-custom-className="body_text">
                                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                                                            <span data-custom-className="body_text">
                                                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                    </bdt>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                    </bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                    </bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span style={{ fontSize: " 15px" }}>
                                                                                    <span data-custom-className="body_text">
                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                            <span data-custom-className="body_text">
                                                                                                <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span style={{ color: " rgb(89, 89, 89){5} " }}>
                                                                                                            <span style={{ fontSize: " 15px" }}>
                                                                                                                <span data-custom-className="body_text">
                                                                                                                    <bdt className="block-component">
                                                                                                                        <bdt className="block-component">
                                                                                                                        </bdt>
                                                                                                                    </bdt>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span style={{ fontSize: " 15px" }}>
                                                                                            <span data-custom-className="body_text">
                                                                                                <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                                    <span style={{ fontSize: " 15px" }}>
                                                                                                        <span data-custom-className="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                <bdt className="block-component">
                                                                                                                </bdt>
                                                                                                            </bdt>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div id="request" style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ color: " rgb(127, 127, 127){5} " }}>
                                                                                    <span style={{ color: " rgb(89, 89, 89) fontSize: 15px" }}>
                                                                                        <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                            <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                                <span id="control" style={{ color: " rgb(0, 0, 0){5} " }}>
                                                                                                    <strong>
                                                                                                        <span data-custom-className="heading_1">
                                                                                                            10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <br />
                                                                            </div>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                    <span style={{ fontSize: " 15px color: rgb(89, 89, 89){5} " }}>
                                                                                        <span data-custom-className="body_text">
                                                                                            Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: <bdt className="block-component">
                                                                                            </bdt>
                                                                                        </span>
                                                                                        <br />
                                                                                        <span data-custom-className="body_text">
                                                                                            <bdt className="question">
                                                                                                <a href="https://netgocard.com/listing-author?dashboard=update-profile" target="_blank" data-custom-className="link">
                                                                                                    https://netgocard.com/listing-author?dashboard=update-profile</a>
                                                                                            </bdt>
                                                                                            <bdt className="else-block">
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                    <span data-custom-className="body_text">
                                                                                        .</span>
                                                                                </span>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <BottomBar />

            </div>
        )
    }
}

export default PrivacyPolicyPlayPage;