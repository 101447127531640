import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop';
import CreateCardDesign from '../createCard/CreateCardDesign';
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPhoneAlt, faComments } from '@fortawesome/free-solid-svg-icons'

import { WhatsappIcon } from "react-share";

import searchIcon from '../images/search-icon.png'
import flagImg from '../images/flag-plan.png'
import phoneOfficeIcon from '../images/phone-office.png'
import wazeOnlyIcon from '../images/waze-only-icon.PNG'
import emptyUser from '../images/empty_user2.jpg'

import { cardNameForUrl,getBackGroundStyle } from "../utils/utils";

import './AdminSystem.scss'

class AdminSystem extends Component {
    constructor() {
        super();
        this.state = {
            allCards: [],
            userId: "",
            filteredAllCards: [],
            searchByCardTitleTerm: "",
            selected: 0,
            showMenu: false,
            showAreYouSure: false,
            showChangePlanPopup: false,
            selectedPopupCard: null,

            showAreYouSureGroup: false,

            useNameOptions: [],
            selectedUseNameOption: "",
            groupNameOptions: [],
            selectedGroupNameOption: "",

            startDate: "",
            finishDate: "",
            userRole: null,

            selectedCards: [],
            rowLoading: -1,
            showDivEditSelectedGroup: false,

            selectedTextColor: null,
            selectedProfileImgOption: null,
            selectedIconImgOption: null,
            selectedCardOption: null,
            selectedBGColor: '',
            selectedIconBorderShape: null,
            srcCover: null,
            cropCover: {
                unit: '%',
                width: 200,
                aspect: 16 / 9,
            },

            exampleGroupCard: null,
            loadingGroupEdit: false,
            bgNames:[]
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount = async () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.props.firebase.getCurrentUser(
            async user1 => {
                let user = await this.props.firebase.getUserById(user1.uid)
                if (user.userRole1 !== "admin") {
                    window.location.href = '/'
                } else {
                    this.getAllData()
                }
            }, () => {
                window.location.href = '/'
            }
        )
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    getAllData = async () => {

        // let bgNames = await this.props.firebase.getAllBackgroundNames()
        let cards = await this.props.firebase.getAllCards()
        let users = await this.props.firebase.getAllUsers()

        let useNameOptions = [{ value: "All", label: "All" }]
        let groupNameOptions = [{ value: "All", label: "All" }]
        let allUserNames1 = []
        let allGroupNames1 = []

        let newCards = []
        cards.map((card, i) => {
            users.map((user, j) => {
                if (card.userId === user.id) {
                    newCards.push({
                        ...card, userName: user.name,
                        checked: false, loading: false
                    })
                    allUserNames1.push(user.name)
                    if (card.groupName !== undefined) {
                        allGroupNames1.push(card.groupName)
                    }
                }
            })
        })
        let allUserNames2 = allUserNames1.filter((c, index) => {
            return allUserNames1.indexOf(c) === index;
        });

        allUserNames2.map((name, i) => {
            useNameOptions.push({ value: name, label: name })
        })

        let allGroupNames2 = allGroupNames1.filter((c, index) => {
            return allGroupNames1.indexOf(c) === index;
        });

        allGroupNames2.map((name, i) => {
            groupNameOptions.push({ value: name, label: name })
        })

        this.setState({
            allCards: newCards,
            filteredAllCards: newCards,
            useNameOptions,
            groupNameOptions,
            // bgNames
        })
    }

    removeCard = async () => {
        const { selectedPopupCard } = this.state
        let cardId = selectedPopupCard.id
        let cardUserId = selectedPopupCard.userId
        let cardName = selectedPopupCard.businessCardTitle
       
        await this.props.firebase.removeCardPermanently(
            cardUserId, cardId, cardName)

        this.setState({ showAreYouSure: false }, () => {
            this.getAllData()
        })
    }
    removeAllCardsInGroup = async () => {
        const { selectedPopupGroup, filteredAllCards } = this.state
        let cardsUserId = filteredAllCards[0].userId
        
        let allCardIds = []
        filteredAllCards.map((card, i) => {
            allCardIds.push(card.id)
        })
        await this.props.firebase.removeAllCardsInGroupPermanently(
            selectedPopupGroup, allCardIds, cardsUserId)

        setTimeout(function () { //Start the timer
            //After 0.5 second
            this.setState({
                groupNameOptions: [],
                selectedGroupNameOption: { label: "All", value: "All" },
                showAreYouSureGroup: false,
                filteredAllCards: [],
                allCards: [],
            }, async () => {
                await this.getAllData()
            })

        }.bind(this), 500)

    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.hideMenu()
        }
    }

    onChangesearchByCardTitleTerm = event => {
        let term = event.target.value
        let allCards1 = this.state.allCards.filter(function (user) {
            return user.businessCardTitle.toLowerCase().includes(term.toLowerCase())
        })
        this.setState({
            searchByCardTitleTerm: event.target.value,
            filteredAllCards: allCards1
        })
    }

    showChangePlanPopup = (card) => {
        this.setState({
            showChangePlanPopup: true,
            selectedPopupCard: card,
        })
    }

    hideChangePlanPopup = () => {
        this.setState({
            showChangePlanPopup: false,
            selectedPopupCard: null,
        })
    }

    showAreYouSureRemoveCard = (card) => {
        this.setState({
            showAreYouSure: true,
            selectedPopupCard: card,
        })
    }

    showAreYouSureRemoveGroupCards = (groupName) => {
        this.setState({
            showAreYouSureGroup: true,
            selectedPopupGroup: groupName,
        })
    }
    hideAreYouSure = () => {
        this.setState({
            showAreYouSure: false,
            selectedPopupCard: null,
        })
    }
    hideAreYouSureGroup = () => {
        this.setState({
            showAreYouSureGroup: false,
            selectedPopupGroup: null,
        })
    }
    showMenu = (row, e, i) => {
        this.setState({
            showMenu: true, rowSelected: i,
            menuX: e.clientX,
            menuY: e.clientY,
        }, () => {
        })
    }

    hideMenu = () => {
        this.setState({ showMenu: false })
    }

    selectTab = async (tab) => {
        switch (tab) {
            case 0:
                this.setState({ selected: tab, filteredAllCards: this.state.allCards })
                break
            case 1:
                let allCards1 = this.state.allCards.filter(function (user) {
                    return user.status.includes("published")
                })
                this.setState({ selected: tab, filteredAllCards: allCards1 })
                break
            case 2:
                let allCards2 = this.state.allCards.filter(function (user) {
                    return user.status.includes("pending")
                })
                this.setState({ selected: tab, filteredAllCards: allCards2 })
                break
            case 3:
                let allCards3 = this.state.allCards.filter(function (user) {
                    return user.status.includes("expired")
                })
                this.setState({ selected: tab, filteredAllCards: allCards3 })
                break
            default:
                break
        }
    }
    getAreYouSureGroupPopup = () => {
        const { selectedPopupGroup } = this.state
        return (
            <div className="are-you-sure-popup-admin">
                <label className="are-you-sure-title-lbl">
                    Are you sure you want to remove all the cards in this group?
                </label>

                <label className="are-you-sure-name-lbl">
                    {selectedPopupGroup}
                </label>

                <div className="are-you-sure-btns-div">
                    <button className="are-you-sure-btn"
                        onClick={this.removeAllCardsInGroup}
                    >Yes</button>
                    <button className="are-you-sure-btn"
                        onClick={this.hideAreYouSureGroup}
                    >No</button>
                </div>
            </div>
        )
    }
    getAreYouSurePopup = () => {
        const { selectedPopupCard } = this.state
        return (
            <div className="are-you-sure-popup-admin">
                <label className="are-you-sure-title-lbl">
                    Are you sure you want to remove this card?
                </label>
                <img alt=""
                    className="are-you-sure-img"
                    src={selectedPopupCard.faceImageUrl !== undefined
                        && selectedPopupCard.faceImageUrl !== null
                        && selectedPopupCard.faceImageUrl !== "" ?
                        selectedPopupCard.faceImageUrl : emptyUser} />
                <label className="are-you-sure-name-lbl">
                    {selectedPopupCard.businessCardTitle}
                </label>

                <div className="are-you-sure-btns-div">
                    <button className="are-you-sure-btn"
                        onClick={this.removeCard}
                    >Yes</button>
                    <button className="are-you-sure-btn"
                        onClick={this.hideAreYouSure}
                    >No</button>
                </div>
            </div>
        )
    }

    choosePlan = async (plan) => {

        let cardId = await this.props.firebase.getCardIdFromCardName(
            this.state.selectedPopupCard.businessCardTitle)

        await this.props.firebase.setCardPlan(cardId, plan)

        this.setState({ showChangePlanPopup: false }, () => {
            this.getData()
        })


    }

    getChangePlanPopup = () => {
        let currentPlan = this.state.selectedPopupCard.associatedPlan
        return (
            <div className="change-plan-popup">
                <button className="change-plan-popup-x-btn"
                    onClick={this.hideChangePlanPopup}
                >X</button>
                <label className="change-plan-title-lbl">
                    change this card plan
                </label>
                <div className="current-div"
                    style={{
                        justifyContent:
                            currentPlan === "1 Digital Business Card" ? "flex-start" : "flex-end"
                    }}
                >
                    <label className="current-plan-lbl">
                        current plan
                    </label>
                </div>
                <div className="plan-cards-div2">

                    <div className="plan-card-div">
                        <label className="plan-card-top-lbl">
                            1 Digital Business Card
                        </label>

                        <label className="plan-card-duration-lbl">
                            Duration: 1 Year
                        </label>

                        <label className="price-lbl">
                            N 25,000
                        </label>

                        <label className="plan-card-per-lbl">
                            Per Business Card
                        </label>

                        <label className="plan-card-includes-lbl">
                            The price includes card setup,
                        </label>

                        <label className="plan-card-includes-lbl">
                            support, and a management app
                        </label>

                        <button
                            className="choose-plan-btn"
                            onClick={() => this.choosePlan("1 Digital Business Card")}
                        >
                            CHOOSE PLAN
                        </button>
                    </div>

                    <div className="plan-card-div2">
                        <img className="flag-img" alt="flag" src={flagImg} />

                        <label className="plan-card-top-lbl">
                            5 Digital Business Card
                        </label>

                        <label className="best-seller-lbl">
                            Best Seller
                        </label>

                        <label className="plan-card-duration-lbl">
                            Duration: 1 Year
                        </label>

                        <label className="price-lbl">
                            $270
                        </label>

                        <label className="plan-card-per-lbl">
                            Per Business Card
                        </label>

                        <label className="plan-card-includes-lbl">
                            The price includes card setup,
                        </label>

                        <label className="plan-card-includes-lbl">
                            support, and a management app
                        </label>

                        <button
                            className="choose-plan-btn"
                            onClick={() => this.choosePlan("5 Digital Business Cards")}
                        >
                            CHOOSE PLAN
                        </button>
                    </div>

                </div>


            </div>
        )
    }

    addToSelectedCards = (card) => {
        const { selectedCards } = this.state
        if (card.checked === true) {//remove 
            card.checked = false
            var index = selectedCards.indexOf(card)
            let w1 = selectedCards.filter(c => c.id === c.id);
            let w = w1[0]
            var index2 = selectedCards.indexOf(w)
            if (index !== -1) {
                selectedCards.splice(index, 1);
            } else if (index2 !== -1) {
                selectedCards.splice(index2, 1);
            }
        } else {//add
            card.checked = true
            selectedCards.push(card)
        }
        this.setState({
            selectedCards
        })

    }
    handleStatusChange = async (row, e) => {
        row.loading = true
        this.forceUpdate()
        let ans = await this.props.firebase.changeCardStatus(row.id, e.value)
        row.false = true
        this.forceUpdate()
        this.getAllData()
    }
    getColumns = () => {
        const columns = [
            {
                name: '',
                selector: 'faceImageUrl',
                sortable: false,
                width: "8%",
                format: row => {

                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        if (row.faceImageUrl !== undefined && row.faceImageUrl !== "") {
                            return <img src={row.faceImageUrl} className="row-img" alt=""
                                onClick={() => { this.onRowClicked(row) }} />  
                        } else {
                            return <img src={emptyUser} className="row-img" alt=""
                                onClick={() => { this.onRowClicked(row) }} />
                        }
                    }
                },
            },
            {
                name: 'Card Title',
                selector: 'businessCardTitle',
                sortable: true,
                width: "17%",
                style: {
                    fontSize: '100%',
                },
                format: row => {

                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        if (row.businessCardTitle !== "") {
                            return <label
                                onClick={() => { this.onRowClicked(row) }} className="row-lbl">
                                {row.businessCardTitle}
                            </label>
                        } else {
                            return <label></label>
                        }
                    }
                },
            },
            {
                name: 'User Name',
                selector: 'userName',
                sortable: true,
                width: "10%",
            },
            {
                name: 'Group Name',
                selector: 'groupName',
                sortable: true,
                width: "10%",
            },
            {
                name: 'Last Updated',
                selector: 'lastEditAt',
                sortable: true,
                width: "10%",
                format: row => {
                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        let miliseconds = row.lastEditAt
                        let dateUpdated = moment(miliseconds).format('DD/MM/YYYY')
                        return <label>{dateUpdated}</label>
                    }
                }

            },
            {
                name: 'Published',
                selector: 'createdAt',
                sortable: true,
                width: "10%",
                format: row => {
                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        let miliseconds = row.createdAt
                        let dateCreated = moment(miliseconds).format('DD/MM/YYYY')
                        return <label>{dateCreated}</label>
                    }
                }
            },
            {
                name: 'Expiry',
                selector: 'createdAt',
                sortable: true,
                width: "5%",
                format: row => {
                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        let miliseconds = row.createdAt
                        let dateCreatedPlus1Year = moment(miliseconds).add(1, 'Y')
                        let dateNow = moment(new Date(Date.now()))
                        let diffInDays = dateCreatedPlus1Year.diff(dateNow, 'days');
                        return <label>{diffInDays}</label>
                    }
                }
            },
            {
                name: 'Plan',
                selector: 'associatedPlan',
                sortable: true,
                width: "10%",
                format: row => {
                    let plan = row.associatedPlan
                    if (plan === "plan1") {
                        plan = "1 Digital Buisness Card"
                    }
                    return <label className="associatedPlan-lbl">{plan}</label>
                }
            },
            {
                name: 'status',
                selector: 'status',
                width: "15%",
                sortable: true,               

                cell: (row) => {
                    let statusOptions = [
                        { label: "pending", value: "pending" },
                        { label: "published", value: "published" }
                    ]
                    let selectedUseNameOption = row.status
                    return (
                        <div className="status-select-and-loading">
                            <Select
                                className="status-select"
                                isSearchable={false}
                                value={selectedUseNameOption}
                                onChange={(e) => this.handleStatusChange(row, e)}
                                options={statusOptions}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                placeholder={row.status}
                                styles={{
                                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                            {row.loading === true ?
                                <ReactLoading type={"spinningBubbles"} color="crimson"
                                    className="status-select-loading" />
                                : null}
                        </div >
                    )
                },
            },
            {
                name: '',
                selector: 'status',
                width: "5%",
                sortable: false,
                format: row => {
                    return <FontAwesomeIcon icon={faTimes} className="menu-btn-icon2"
                        onClick={() => {
                            this.showAreYouSureRemoveCard(row)
                        }}
                    />
                },
            },
        ]

        return columns

    }

    getCustomStyles = () => {
        const customStyles = {
            divider: {
                style: {
                }
            },
            rows: {
                style: {
                }
            },
            headCells: {
                style: {
                    background: "#eef2f4",
                    color: "black",
                    fontSize: "90%",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                },
            },
            cells: {
                style: {
                    fontSize: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                },
            },
        }
        return customStyles
    }
    onRowClicked = (row) => {
        let name = cardNameForUrl(row.businessCardTitle)
        let id = row.id
        window.location.href = "/card/" + name + "/" + id
    }

    getBusinessCardTitleA = () => {
        const { selected } = this.state

        let businessCardTitleA = "All"
        switch (selected) {
            case 0:
                businessCardTitleA = "All"
                break;
            case 1:
                businessCardTitleA = "Published"
                break;
            case 2:
                businessCardTitleA = "Pending"
                break;
            case 3:
                businessCardTitleA = "Expired"
                break;
            default:
                businessCardTitleA = ""
                break
        }
        return businessCardTitleA
    }

    getTabs = () => {
        const { selected } = this.state

        return (
            <div className="all-tabs">

                <div className="buisness-cards-table-tabs">
                    <div className="table-tab"
                        style={selected === 0 ? { borderBottom: "4px solid skyblue" } : null}
                        onClick={() => this.selectTab(0)}
                    >
                        ALL
                    </div>
                    <div className="table-tab"
                        style={selected === 1 ? { borderBottom: "4px solid skyblue" } : null}
                        onClick={() => this.selectTab(1)}
                    >
                        PUBLISHED
                    </div>
                    <div className="table-tab"
                        style={selected === 2 ? { borderBottom: "4px solid skyblue" } : null}
                        onClick={() => this.selectTab(2)}
                    >
                        PENDING
                    </div>
                    <div className="table-tab"
                        style={selected === 3 ? { borderBottom: "4px solid skyblue" } : null}
                        onClick={() => this.selectTab(3)}
                    >
                        EXPIRED
                    </div>

                </div>
            </div>
        )
    }

    handleUserNameChange = selectedOption => {
        let selectedName = selectedOption.value
        let allCards1 = this.state.allCards
        if (selectedName !== "All") {
            allCards1 = this.state.allCards.filter(function (user) {
                return user.userName.includes(selectedName)
            })
        }
        this.setState({ selectedUseNameOption: selectedOption, filteredAllCards: allCards1 })
    }

    handleGroupNameChange = selectedOption => {
        let selectedName = selectedOption.value
        let allCards1 = this.state.allCards
        if (selectedName !== "All") {
            allCards1 = this.state.allCards.filter(function (card) {
                let ans = false
                if (card.groupName !== undefined) {
                    ans = card.groupName.includes(selectedName)
                }
                return ans
            })
        }
        this.setState({
            filteredAllCards: allCards1,
            selectedGroupNameOption: { label: selectedName, value: selectedName }
        })
    }

    selectStartDate = (date) => {
        this.setState({ startDate: date }, () => {
            this.tryFilterByDates()
        })
    }

    selectFinishDate = (date) => {
        this.setState({ finishDate: date }, () => {
            this.tryFilterByDates()
        })
    }

    removeSearchByDate = () => {
        let allCards1 = this.state.allCards
        this.setState({
            filteredAllCards: allCards1
        })
    }

    tryFilterByDates = () => {
        const {
            startDate, finishDate
        } = this.state
        if (startDate !== "" && finishDate !== "") {
            let allCards1 = this.state.allCards.filter(function (card) {
                return card.createdAt <= finishDate && card.createdAt >= startDate
            })
            this.setState({
                filteredAllCards: allCards1
            })
        }
    }

    getSelectDates = () => {
        const {
            startDate, finishDate
        } = this.state

        return (
            <div className="all-select-dates">
                <label className="all-select-dates-title">Filter By Publish Date</label>
                <label className="a-select-date-title">From:</label>
                <div>
                    <DatePicker
                        className="a-select-dates"
                        selected={startDate}
                        onChange={this.selectStartDate}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <label className="a-select-date-title">To:</label>
                <div>
                    <DatePicker
                        className="a-select-dates"
                        selected={finishDate}
                        onChange={this.selectFinishDate}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

                <button
                    className="remove-dates-btn"
                    onClick={this.removeSearchByDate}
                >Remove Search By Date</button>
            </div>
        )
    }

    getSelectDivs = () => {
        const {
            useNameOptions, selectedUseNameOption,
            groupNameOptions, selectedGroupNameOption,
            startDate, finishDate
        } = this.state

        return (
            <div className="all-selects">
                <div className="a-select-div">
                    <Select
                        className="my-design-select"
                        isSearchable={false}
                        value={selectedUseNameOption}
                        onChange={this.handleUserNameChange}
                        options={useNameOptions}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        placeholder="filter by user name"
                        styles={{
                            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                    />
                </div>

                <div className="a-select-div">

                    <Select
                        className="my-design-select"
                        isSearchable={false}
                        value={selectedGroupNameOption}
                        onChange={this.handleGroupNameChange}
                        options={groupNameOptions}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        placeholder="filter by group name"
                        styles={{
                            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                    />
                </div>
                {selectedGroupNameOption !== "" && selectedGroupNameOption.value !== "All" ?
                    <div className="edit-group-btns-div">
                        <button
                            className="edit-selected-group-btn"
                            onClick={() => {
                                this.showSelectedGroup(selectedGroupNameOption.value)
                            }}
                        >
                            Edit Selected Group
                        </button>

                        <button
                            className="edit-selected-group-btn"
                            onClick={() => {
                                this.changeSelectedGroupStatus(selectedGroupNameOption.value, "published")
                            }}
                        >
                            Publish all in Group
                        </button>

                        <button
                            className="edit-selected-group-btn"
                            onClick={() => {
                                this.changeSelectedGroupStatus(selectedGroupNameOption.value, "pending")
                            }}
                        >
                            Pending all in Group
                        </button>


                        <button
                            className="edit-selected-group-btn"
                            onClick={() => {
                                this.deleteSelectedGroup(selectedGroupNameOption.value)
                            }}
                        >
                            Delete all in Group
                        </button>

                    </div>
                    :
                    null
                }

            </div>
        )
    }

    getSearchDivs = () => {
        const { searchByCardTitleTerm } = this.state

        return (
            <div className="all-searches">

                <div className="a-search-div">
                    <input
                        className="my-search"
                        type="text"
                        placeholder="Search By Card Title"
                        name="searchByCardTitleTerm"
                        value={searchByCardTitleTerm}
                        onChange={this.onChangesearchByCardTitleTerm}
                    />
                    <img src={searchIcon} className="search-img" alt="" />
                </div>
            </div>
        )
    }
    showSelectedGroup = (selectedGroupName) => {
        let exampleGroupCard = this.state.filteredAllCards[0]
        this.setState({
            showDivEditSelectedGroup: true,
            selectedGroupName,
            exampleGroupCard
        })
    }

    changeSelectedGroupStatus = async (selectedGroupName, status) => {
        await this.props.firebase.changeAllCardsInGroupStatus(selectedGroupName, status)
        let allCards1 = await this.props.firebase.getAllCards()

        allCards1 = allCards1.filter(function (card) {
            let ans = false
            if (card.groupName !== undefined) {
                ans = card.groupName.includes(selectedGroupName)
            }
            return ans
        })

        this.setState({
            filteredAllCards: allCards1,
            selectedGroupNameOption: { label: selectedGroupName, value: selectedGroupName }
        })

    }

    deleteSelectedGroup = (selectedGroupName) => {
        this.showAreYouSureRemoveGroupCards(selectedGroupName)
    }

    setTextColor = (color) => {
        this.setState({ selectedTextColor: color })
    }

    setCardBGOption = (color) => {
        this.setState({ selectedCardOption: color })
    }

    setCardBGColor = (color) => {
        this.setState({ selectedBGColor: color })
    }

    setProfileImgDesign = (option) => {
        this.setState({ selectedProfileImgOption: option })
    }

    setIconBorderShape = (color) => {
        this.setState({ selectedIconImgOption: color })
    }

    setIconBorderColor = (color) => {
        this.setState({ selectedIconBorderShape: color })
    }

    getTextColor = (exampleGroupCard) => {
        const { selectedTextColor } = this.state
        let textColor = null

        if (exampleGroupCard !== undefined && exampleGroupCard !== null
            && exampleGroupCard.textColor !== "" && selectedTextColor === null) {
            textColor = exampleGroupCard.textColor
        }
        else if (selectedTextColor !== null && selectedTextColor.value !== undefined) {
            textColor = selectedTextColor.value
        }
        else if (selectedTextColor !== null && selectedTextColor.value === undefined) {
            textColor = selectedTextColor
        }

        return textColor
    }
    getCardOption2 = (exampleGroupCard) => {
        const { selectedCardOption, selectedBGColor } = this.state
        let cardOption = null

        if (selectedCardOption !== null) {
            cardOption = selectedCardOption.value
        } else if (selectedBGColor !== "") {
            cardOption = selectedBGColor
        } else {
            cardOption = "#fff"
        }
        return cardOption
    }

    getCardOption = (exampleGroupCard) => {
        const { selectedCardOption, selectedBGColor } = this.state
        let cardOption = null
        let cardOptionType = null

        if (exampleGroupCard !== undefined && exampleGroupCard !== null &&
            (exampleGroupCard.selectedBGColor !== "" || exampleGroupCard.cardBGName !== "")) {
            if (exampleGroupCard.cardBGName !== "" && selectedCardOption === null
                && selectedBGColor === "") {
                cardOption = exampleGroupCard.cardBGName
                cardOptionType = "cardBGName"
            }
            else if (exampleGroupCard.selectedBGColor !== "" && selectedCardOption === null && selectedBGColor === "") {
                cardOption = exampleGroupCard.selectedBGColor
                cardOptionType = "selectedBGColor"
            }
            else if (selectedCardOption !== null) {
                cardOption = selectedCardOption.value
                cardOptionType = "cardBGName"
            } else if (exampleGroupCard.cardBGName !== "" && selectedBGColor === "") {
                cardOption = exampleGroupCard.cardBGName
                cardOptionType = "cardBGName"
            } else if (selectedBGColor !== "") {
                cardOption = selectedBGColor
                cardOptionType = "selectedBGColor"
            } else {
                cardOption = "#fff"
                cardOptionType = "selectedBGColor"
            }
        }
        else {
            if (selectedCardOption !== null) {
                cardOption = selectedCardOption.value
                cardOptionType = "cardBGName"
            } else if (selectedBGColor !== "") {
                cardOption = selectedBGColor
                cardOptionType = "selectedBGColor"
            } else {
                cardOption = "#fff"
                cardOptionType = "selectedBGColor"
            }
        }

        return [cardOption, cardOptionType]
    }

    getProfileOption = (exampleGroupCard) => {
        const { selectedProfileImgOption } = this.state
        
        let profileOption = (exampleGroupCard !== undefined &&
            exampleGroupCard !== null && exampleGroupCard.profileImgOptions !== "")
            && selectedProfileImgOption === null ?
            exampleGroupCard.profileImgOptions
            :
            selectedProfileImgOption !== undefined
                && selectedProfileImgOption !== null ? selectedProfileImgOption.value : null

        return profileOption
    }

    getBgStyle = (exampleGroupCard) => {
        const { selectedBGColor,bgNames } = this.state
        let bgOptions = this.getCardOption(exampleGroupCard)

        let bgColor = null
        let bgName = null
        let cardBGType = bgOptions[1]
        if (cardBGType === "selectedBGColor") {
            bgColor = bgOptions[0]
        } else {
            bgName = bgOptions[0]
        }
        let style = getBackGroundStyle(null, bgName, selectedBGColor)//,bgNames)
        return style
    }

    getPhoneMainImages = (device, exampleGroupCard) => {

        const { coverImageUrl } = this.state

        let profileOption = this.getProfileOption(exampleGroupCard)
     
        return (
            <div className="top-person-images">

                {exampleGroupCard !== undefined && exampleGroupCard !== null
                    && exampleGroupCard.coverImageUrl !== "" ?
                    <img alt=""
                        src={coverImageUrl === undefined && exampleGroupCard.coverImageUrl !== undefined
                            && exampleGroupCard.coverImageUrl !== "" ?
                            exampleGroupCard.coverImageUrl : URL.createObjectURL(coverImageUrl)}
                        className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"} />
                    :
                    <div className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"}></div>
                }

                <img
                    src={emptyUser} alt=""
                    className={device === "mobile" ? "preview-tiny-face-img" : "preview-face-img"}
                    style={{
                        borderRadius: profileOption === "Circle" ? "50%" :
                            profileOption === "Rounded Rectangle" ? "10%" : "0%",
                    }}
                />
            </div>
        )
    }

    getPhoneTitles = (device, exampleGroupCard) => {

        let textColor = this.getTextColor(exampleGroupCard)

        let lblStyle = { color: textColor }

        let title = "The Person Name"

        let job = "The Person Job Description"

        return (
            <div className={device === "mobile" ? "preview-tiny-titles" : "preview-titles"}>

                <label className={device === "mobile" ? "preview-tiny-title1" : "preview-title1"}
                    style={lblStyle}>
                    {title}
                </label>

                <label className={device === "mobile" ? "preview-tiny-title2" : "preview-title2"}
                    style={lblStyle}>
                    {job}
                </label>
            </div>
        )
    }


    getIconBorderShape = (exampleGroupCard) => {
        const {
            selectedIconBorderShape,
        } = this.state
        let iconBorder = "black"

        if (exampleGroupCard !== undefined && exampleGroupCard !== null
            && exampleGroupCard.iconBorder !== "") {
            if (selectedIconBorderShape === undefined
                || selectedIconBorderShape === null
                || selectedIconBorderShape === "") {
                iconBorder = exampleGroupCard.iconBorder
            } else {
                if (selectedIconBorderShape.value !== undefined) {
                    iconBorder = selectedIconBorderShape.value
                } else {
                    iconBorder = selectedIconBorderShape
                }
            }
        } 
        return iconBorder
    }

    getIconBorderColor = (exampleGroupCard) => {
        const { selectedIconImgOption } = this.state

        let iconOption = (exampleGroupCard !== undefined &&
            exampleGroupCard !== null && exampleGroupCard.iconOptions !== "")
            && selectedIconImgOption === null ?
            exampleGroupCard.iconOptions
            :
            selectedIconImgOption !== undefined
                && selectedIconImgOption !== null ? selectedIconImgOption.value : null

        return iconOption
    }

    getBtnStyle = (exampleGroupCard) => {

        let iconOption = this.getIconBorderColor(exampleGroupCard)
        let iconBorder = this.getIconBorderShape(exampleGroupCard)

        let btnStyle = iconOption === "Circle Icons" ?
            {
                borderColor: iconBorder, borderRadius: "50%",
            }
            :
            iconOption === "Rounded Rectangle Icons" ?
                {
                    borderColor: iconBorder, borderRadius: "15%",
                }
                :
                {
                    borderColor: iconBorder, borderRadius: "0%",
                }
        return btnStyle
    }
    getLblStyle = (exampleGroupCard) => {
        let textColor = this.getTextColor(exampleGroupCard)
        let lblStyle = { color: textColor }
        return lblStyle
    }
    getWazeBtnStyle = (exampleGroupCard) => {
        let iconOption = this.getIconBorderColor(exampleGroupCard)
        let iconBorderOption = this.getIconBorderShape(exampleGroupCard)
        // let cardOption = this.getCardOption()

        let bgColor = "#55cce7"
        let borderColor = "#55cce7"
        if (iconBorderOption === "Gold" || iconBorderOption === "goldenrod") {//cardOption === "Midnight"
            bgColor = "goldenrod"
            borderColor = "goldenrod"
        }

        let wazeBtnStyle = iconOption === "Circle Icons" ?
            {
                borderColor: borderColor,
                backgroundColor: bgColor,
                borderRadius: "50%",
            }
            :
            iconOption === "Rounded Rectangle Icons" ?
                {
                    borderColor: borderColor,
                    backgroundColor: bgColor,
                    borderRadius: "15%",
                }
                :
                {
                    borderColor: borderColor,
                    backgroundColor: bgColor,
                    borderRadius: "0%",
                }
        return wazeBtnStyle
    }
    getPhoneBtns = (device, exampleGroupCard) => {

        let btnStyle = this.getBtnStyle(exampleGroupCard)

        let lblStyle = this.getLblStyle(exampleGroupCard)

        let wazeBtnStyle = this.getWazeBtnStyle(exampleGroupCard)

        return (
            <div>
                <div className={device === "mobile" ? "card-btns-tiny" : "card-btns"}>

                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <FontAwesomeIcon icon={faPhoneAlt} />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Phone</label>
                    </div>

                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <FontAwesomeIcon icon={faComments} />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>SMS</label>
                    </div>


                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <WhatsappIcon
                                size={35}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Whatsapp</label>
                    </div>


                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <img src={phoneOfficeIcon} className="btn-icon" alt="" />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Landline</label>
                    </div>

                    <div className={"card-btn-div"}>

                        <div className="card-btn-circle-div"
                            style={wazeBtnStyle}
                        >
                            <img className="btn-icon" alt=""
                                src={wazeOnlyIcon}
                            />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Navigate</label>

                    </div>

                </div>
            </div>
        )
    }

    getDropzoneDiv = () => {
        let text1 = window.innerWidth < 1100 ? "click to add Image" : "Drag and Drop a file or click to add Image"
        let text2 = "accepted Image Sizes: 1MB or less"

        return (
            <div className="the-dropzone">
                <label className="the-dropzone-lbl1">
                    {text1}
                </label>
                <label className="the-dropzone-lbl1">
                    {text2}
                </label>
            </div>
        )
    }

    onCropCoverComplete = cropCover => {
        this.makeClientCropCover(cropCover);
    }

    onCoverImageLoaded = image => {
        this.imageRef2 = image;
    }

    async makeClientCropCover(cropCover) {
        if (this.imageRef2 && cropCover.width && cropCover.height) {
            const croppedCoverImageUrl = await this.getCroppedCoverImg(
                this.imageRef2,
                cropCover,
                'newFile2.jpeg'
            );
            this.setState({ coverImageUrl: croppedCoverImageUrl, croppedCoverImageUrl })
        }
    }

    onCropCoverChange = cropCover => {
        this.setState({ cropCover });
    }


    getCroppedCoverImg(image, cropCover, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = cropCover.width;
        canvas.height = cropCover.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            cropCover.x * scaleX,
            cropCover.y * scaleY,
            cropCover.width * scaleX,
            cropCover.height * scaleY,
            0,
            0,
            cropCover.width,
            cropCover.height
        );
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }

    getAcceptedFilesCover = async (acceptedFiles) => {
        let img = acceptedFiles[0]
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ srcCover: reader.result })
        );
        reader.readAsDataURL(img);

    }
    saveCoverImg = () => {
        this.setState({
            coverImgSelected: true,
            srcCover: null
        })
    }
    cancelCoverImg = () => {
        this.setState({
            coverImageUrl: undefined,
            coverImgSelected: true,
            srcCover: null,
            croppedCoverImageUrl: undefined
        })
    }

    saveGroupDesign = async () => {
        const {
            exampleGroupCard,
            coverImageUrl,
            selectedProfileImgOption,
            selectedIconImgOption,
            selectedCardOption,
            selectedBGColor,
            selectedTextColor,
            selectedIconBorderShape,
        } = this.state

        let groupName = exampleGroupCard.groupName

        if (coverImageUrl !== undefined &&
            coverImageUrl !== exampleGroupCard.coverImageUrl) {
            let coverUrl = await this.props.firebase.uploadBlobCover(coverImageUrl, groupName)
            await this.props.firebase.setCoverImgForEntireGroup(coverUrl, groupName)
        }

        if (selectedProfileImgOption !== null &&
            selectedProfileImgOption !== exampleGroupCard.profileImgOptions) {
            await this.props.firebase.setProfileImgOptionsForEntireGroup(
                selectedProfileImgOption.value, groupName)

        }
        if (selectedIconImgOption !== null &&
            selectedIconImgOption !== exampleGroupCard.iconOptions) {
            await this.props.firebase.setIconOptionsForEntireGroup(
                selectedIconImgOption.value, groupName)
        }
        if (selectedIconBorderShape !== null &&
            selectedIconBorderShape !== exampleGroupCard.iconBorder) {
            await this.props.firebase.setIconBorderOptionForEntireGroup(
                selectedIconBorderShape.value, groupName)
        }
        if (selectedCardOption !== null &&
            selectedCardOption !== exampleGroupCard.cardBGName) {
            await this.props.firebase.setCardBGNameForEntireGroup(
                selectedCardOption.value, groupName)
        }
        if (selectedBGColor !== "" &&
            selectedBGColor !== exampleGroupCard.selectedBGColor) {
            await this.props.firebase.setCardBGColorForEntireGroup(
                selectedBGColor.value, groupName)
        }
        if (selectedTextColor !== null &&
            selectedTextColor !== exampleGroupCard.textColor) {
            let textColor1 = this.getTextColor(exampleGroupCard)
            await this.props.firebase.setTextColorForEntireGroup(
                textColor1, groupName)
        }
        return true
    }
    
    saveGroupDesign1 = async () => {
        this.setState({
            loadingGroupEdit: true,
        }, async () => {
            let ans = await this.saveGroupDesign()
            if (ans === true) {
                this.setState({
                    loadingGroupEdit: false,
                    showDivEditSelectedGroup: false,
                }, () => {
                    alert("Group Saved Successfully!")
                })
            }
        })

    }

    getLoadingPopup = () => {
        return (
            <div className="loading-group-edit-popup">
                <label className="are-you-sure-title-lbl"> Saving ...</label>
                <ReactLoading type={"spinningBubbles"} color="crimson" />
            </div>
        )
    }

    getEditDesignThings = (device) => {
        const { exampleGroupCard, srcCover, cropCover, loadingGroupEdit } = this.state

        return (

            <div className="all-edit-divs">

                {loadingGroupEdit === true ? this.getLoadingPopup()
                    :
                    null
                }
                <div className="card-preview-div1">

                    <div className={device === "mobile" ? "preview-tiny-phone-div-container" : "main-preview-div"}>


                        <div className={device === "mobile" ?
                            "preview-tiny-phone-div" : "preview-phone-div2"}>
                            <div className={device === "mobile" ?
                                "preview-tiny-phone-inside-div" : "preview-phone-inside-div"}
                                style={this.getBgStyle(exampleGroupCard)}
                            >
                                <div className={"preview-phone-inside-div-scrollable"}
                                    id={"preview-phone-inside-div-scrollable"}
                                >
                                    {this.getPhoneMainImages(device, exampleGroupCard)}

                                    {this.getPhoneTitles(device, exampleGroupCard)}

                                    {this.getPhoneBtns(device, exampleGroupCard)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="all-edit-left-divs">

                    <div className="upload-cover-img-div">
                        <div className="upload-image-inside-div">
                            <label className="add-img-lbl">
                                Upload Cover Logo/Image
                            </label>

                            <div className="add-img-container">

                                {srcCover === null ?
                                    <Dropzone
                                        onDrop={acceptedFiles => this.getAcceptedFilesCover(acceptedFiles)}>

                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {this.getDropzoneDiv()}

                                                </div>
                                            </section>
                                        )}

                                    </Dropzone>
                                    :
                                    <ReactCrop
                                        src={srcCover}
                                        crop={cropCover}
                                        ruleOfThirds
                                        onImageLoaded={this.onCoverImageLoaded}
                                        onComplete={this.onCropCoverComplete}
                                        onChange={this.onCropCoverChange}
                                    />
                                }

                            </div>
                            <div className="add-img-btns-container">
                                <button className="add-img-btn" onClick={this.cancelCoverImg}>Cancel</button>
                                <button className="add-img-btn" onClick={this.saveCoverImg}>OK</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-design-div1">
                        <CreateCardDesign
                            person={exampleGroupCard}
                            setTextColor={this.setTextColor}
                            setCardBGColor={this.setCardBGColor}
                            setCardBGOption={this.setCardBGOption}
                            setProfileImgDesign={this.setProfileImgDesign}
                            setIconBorderShape={this.setIconBorderShape}
                            setIconBorderColor={this.setIconBorderColor}
                        />
                    </div>

                    <button className="save-group-design-btn"
                        onClick={this.saveGroupDesign1}>
                        {"Save Group Design"}
                    </button>
                </div>
            </div>
        )
    }

    render() {
        const { allCards, filteredAllCards,
            showAreYouSure, showAreYouSureGroup,
            showChangePlanPopup,
            showDivEditSelectedGroup, selectedGroupName
        } = this.state

        if (allCards.length === 0) {
            return (
                <div className="loading-main-div">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }

        if (showDivEditSelectedGroup === true) {
            return (
                <div className="edit-selected-group-div">
                    <div className="group-edit-lbls-div">
                        <label className="group-edit-lbl2"> group name: {selectedGroupName}</label>
                    </div>
                    {this.getEditDesignThings("desktop")}
                </div>
            )
        }

        let businessCardTitleA = this.getBusinessCardTitleA()

        const columns = this.getColumns()

        const customStyles = this.getCustomStyles()

        return (
            <div className="admin-cards-page-container">
                {showAreYouSure === true ? this.getAreYouSurePopup()
                    :
                    null}

                {showAreYouSureGroup === true ? this.getAreYouSureGroupPopup()
                    :
                    null}

                {showChangePlanPopup === true ? this.getChangePlanPopup() : null}

                <div className="admin-cards-page-container-browser">

                    <div className="admin-cards-inside">


                        <div className="title-and-exel-btn-div">   
                            <label className="admin-cards-title">
                                {businessCardTitleA} Digital Business Cards ({filteredAllCards.length})
                            </label>
                            <button
                                className="exel-btn"
                                onClick={() => { window.location.href = "/read-excel" }}
                            >Go To Excel
                            </button>
                        </div>

                        {this.getSearchDivs()}
                        {this.getSelectDivs()}
                        {this.getSelectDates()}

                        {this.getTabs()}
                        {/* <div className="table-line" /> */}

                        <DataTable
                            className="the-table"
                            columns={columns}
                            data={filteredAllCards}
                            defaultSortField="businessCardTitle"
                            noHeader={true}
                            customStyles={customStyles}
                        />

                    </div>
                </div>

            </div>
        )
    }
}

export default compose(withFirebase)(AdminSystem)