import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import sessionstorage from 'sessionstorage'
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { slide as Menu } from 'react-burger-menu'
import ReactLoading from "react-loading";
import './NavBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHeart, faUserCircle, faLock, faArrowLeft,
    faDownload, faMapMarkerAlt, faPlus
} from '@fortawesome/free-solid-svg-icons'

import emptyUser from '../images/user-empty.jpg'
import logo1 from '../images/logo-min.png'
import menuIcon from '../images/burger-menu-btn2.PNG'
import xIcon2 from '../images/x-menu-btn2.PNG'
import googlePlayBtn from '../images/google-play-btn1.png'
import applePlayBtn from '../images/apple-play-btn1.png'

class NavBar extends Component {

    constructor() {
        super()
        this.state = {
            userName: '',
            showProfileMenu: false,
            btnStyle1: { border: "none" },
            btnStyle2: { border: "none" },
            btnStyle3: { border: "none" },
            menuOpen: false,
            downloadUrl: "",
            gettingAppFromServer: false,
            needToChangePassword: false,
        }
    }

    componentDidMount = async () => {
        if (this.state.userName === "") {
            this.getUserName()
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.userName !== nextProps.userName1) {
            this.setState({ userName: nextProps.userName1 })
        }
    }

    getUserName = () => {
        this.props.firebase.getCurrentUser(
            async user => {
                this.setState({
                    userName: user.displayName,
                    userId: user.uid,
                }, () => {
                    this.checkIfAdmin(user)
                })
            },
            () => {
            })
    }

    checkIfAdmin = async (user) => {
        let role = await this.props.firebase.getUserRoleFromUserId(user.uid)
        if (role === "admin") {
            this.setState({
                userRole: role,
            })
        }
    }

    showProfileMenu = () => {
        this.setState({
            showProfileMenu: true,
        })
    }

    hideProfileMenu = () => {
        this.setState({
            showProfileMenu: false,
        })
    }

    logout = async () => {
        this.setState({ selected: 6 }, async () => {
            await this.props.firebase.doSignOut()
            sessionstorage.removeItem("userEmail")
            sessionstorage.removeItem("userId")
            sessionstorage.removeItem("userName")
            window.location.href = "/"
        })
    }

    goBackToHomePage = () => {
        this.setState({ selected: 7 }, () => {
            window.location.href = "/"
        })
    }

    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen })
    }

    closeMenu() {
        this.setState({ menuOpen: false })
    }

    goToSelected = (selected) => {
        this.setState({ selected }, () => {
            switch (selected) {
                case 1:
                    window.location.href = "/listing-author?dashboard=listings"
                    break
                case 2:
                    window.location.href = "/listing-author?dashboard=saved"
                    break
                case 3:
                    window.location.href = "/listing-author?dashboard=update-profile"
                    break
                default:
                    window.location.href = "/listing-author?dashboard=listings"
                    break
            }
        })
    }

    getDashboardMobileMenu = () => {
        const { menuOpen, gettingAppFromServer, selected } = this.state
        const selectedTabStyle = {
            borderLeft: "4px solid skyblue",
            backgroundColor: "#435363",
            padding: "5%"
        }

        return (
            <Menu
                isOpen={menuOpen}
                customBurgerIcon={<img src={menuIcon} alt="" />}
                customCrossIcon={<img src={xIcon2} alt="" />}
            >
                <div className="burger-things">

                    <div className="mobile-navbar-top-btn">
                        <label className="left-navbar-title">My Account</label>
                    </div>

                    <div className="mobile-navbar-btn"
                        style={selected === 1 ? selectedTabStyle : null}
                        onClick={() => this.goToSelected(1)}
                    >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <label className="left-navbar-label">My Buisness Cards</label>
                    </div>

                    <div className="mobile-navbar-btn"
                        style={selected === 2 ? selectedTabStyle : null}
                        onClick={() => this.goToSelected(2)}
                    >
                        <FontAwesomeIcon icon={faHeart} />
                        <label className="left-navbar-label">My Cards Book</label>
                    </div>

                    <div className="mobile-navbar-btn"
                        style={selected === 3 ? selectedTabStyle : null}
                        onClick={() => this.goToSelected(3)}
                    >
                        <FontAwesomeIcon icon={faUserCircle} />
                        <label className="left-navbar-label">My Profile</label>
                    </div>

                    <div className="mobile-navbar-btn"
                        style={selected === 6 ? selectedTabStyle : null}
                        onClick={this.logout}
                    >
                        <FontAwesomeIcon icon={faLock} />
                        <label className="left-navbar-label">Logout</label>
                    </div>

                    <div className="mobile-navbar-btn"
                        style={selected === 7 ? selectedTabStyle : null}
                        onClick={this.goBackToHomePage}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <label className="left-navbar-label">Back to Homepage</label>
                    </div>
                    <button
                        onClick={this.downloadAndroidApp}
                        className="mobile-navbar-btn"
                        style={selected === 8 ? selectedTabStyle : null}
                    >
                        {gettingAppFromServer === true ?
                            <ReactLoading type={"spinningBubbles"} color="crimson"
                                height={30} width={30}
                            />
                            :
                            <FontAwesomeIcon icon={faDownload} />
                        }
                        <label className="left-navbar-label">Download Android App</label>

                    </button>

                </div>
            </Menu>
        )
    }

    getMainMobileMenu = () => {
        const { menuOpen, userName, gettingAppFromServer } = this.state
        return (

            <Menu
                isOpen={menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}
                customBurgerIcon={<img src={menuIcon} alt="" />}
                customCrossIcon={<img src={xIcon2} alt="" />}
            >
                <img className="logo-link-img-menu" src={logo1} alt=""></img>

                <a className="menu-top-btn"
                    href="/pricing-plan">
                    Add Buisness Card
                </a>

                <a className="menu-top-btn"
                    href={userName === "" ? "/login" : "/listing-author?dashboard=listings"}
                >
                    My Buisness Cards
                </a>


                <a className="menu-top-btn"
                    href={userName === "" ? "/login" : "/listing-author?dashboard=saved"}
                >
                    My NG Cards Book
                </a>


                <a className="menu-top-btn"
                    href={userName === "" ? "/login" : "/listing-author?dashboard=update-profile"}
                >
                    My Profile
                </a>

                {userName === "" ?
                    <a className="menu-top-btn"
                        href="/login">
                        Sign In
                    </a>
                    :
                    <button className="menu-top-btn2"
                        onClick={this.logout}>
                        Sign Out
                    </button>
                }

                <br />

                <HashLink
                    className="menu-inside-link"
                    to="/#home"
                    onClick={() => this.closeMenu()}
                >
                    Home
                </HashLink>

                <HashLink
                    className="menu-inside-link"
                    to="/#advantages"
                    onClick={() => this.closeMenu()}>
                    Advantages
                </HashLink>

                <HashLink
                    className="menu-inside-link"
                    to="/#examples"
                    onClick={() => this.closeMenu()}>
                    Examples
                </HashLink>

                <HashLink
                    className="menu-inside-link"
                    to="/#contact"
                    onClick={() => this.closeMenu()}>
                    Contact Us
                </HashLink>

                {gettingAppFromServer === true ?
                    <label className="menu-download-app-lbl">
                        Loading...
                    </label>
                    :
                    <div className="download-app-btns-div">
                        <img alt="google-play-btn" src={googlePlayBtn}
                            className="menu-download-app-btn"
                            onClick={() => this.downloadAndroidApp()}
                        />
                        <img alt="apple-play-btn" src={applePlayBtn}
                            className="menu-download-app-btn"
                        />
                    </div>
                }
            </Menu>
        )
    }

    downloadAndroidApp = async () => {
        this.setState({
            selected: 8,
            gettingAppFromServer: true
        }, async () => {
            let downloadUrl = await this.props.firebase.getAndroidAppUrl()
            this.setState({
                gettingAppFromServer: false,
                downloadUrl,
            }, () => {
                this.downloadApp()
            })
        })
    }

    downloadApp = () => {
        const { downloadUrl } = this.state
        const link = document.createElement('a');
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    getDesktopView = () => {
        const {
            userName, showProfileMenu, userRole, gettingAppFromServer
        } = this.state

        return (

            <div className="navbar-browser-container">
                <img className="logo-link-img" src={logo1} alt=""
                    onClick={() => { window.location.href = "/" }}
                />
                {
                    window.location.href.includes("listing-author") ?
                        <label className="only-use-in-navbar">{this.state.userName}</label>
                        :
                        <div className="navbar-links-container">

                            {userRole === "admin" ?
                                <button
                                    className="admin-btn"
                                    onClick={() => { window.location.href = "/admin-system" }}
                                >Admin System </button>
                                :
                                null
                            }

                            <div className='link-container'>

                                <HashLink
                                    className="link"
                                    to="/#home">
                                    Home
                                </HashLink>

                            </div>

                            <div className='link-container'>
                                <HashLink
                                    className="link"
                                    to="/#advantages">
                                    Advantages
                                </HashLink>
                            </div>

                            <div className='link-container'>
                                <HashLink
                                    className="link"
                                    to="/#examples">
                                    Examples
                                </HashLink>
                            </div>

                            <div className='link-container'>
                                <HashLink className="link"
                                    to="/#contact" >
                                    Contact Us
                                </HashLink>
                            </div>

                            <button
                                onClick={this.downloadAndroidApp}
                                className="download-app-btn"
                            >
                                {gettingAppFromServer === true ?
                                    <ReactLoading type={"spinningBubbles"} color="crimson"
                                        height={30} width={30}
                                    />
                                    : null
                                }
                                Download Android App
                            </button>

                            {userName === "" ?

                                <div className='login-link-container'>
                                    <img
                                        className="signin-user-img"
                                        src={emptyUser} alt=""
                                    />
                                    <Link to="/login" className="link">
                                        Sign In
                                    </Link>
                                </div>
                                :
                                <div className='login-link-container'>
                                    <img
                                        className="name-user-img"
                                        src={emptyUser} alt=""
                                    />
                                    <div
                                        className="link-hi"
                                        onMouseEnter={this.showProfileMenu}
                                        onMouseLeave={this.hideProfileMenu}
                                    >
                                        Hi {userName}

                                        {showProfileMenu === true ?
                                            <div className="profile-menu"
                                            >
                                                <button className="profile-menu-btn"
                                                    onClick={
                                                        () => window.location.href = "/listing-author?dashboard=listings"
                                                    }
                                                >My Buisness Cards
                                                </button>

                                                <button className="profile-menu-btn"
                                                    onClick={
                                                        () => window.location.href = "/listing-author?dashboard=update-profile"
                                                    }
                                                >Update Profile
                                                </button>

                                                <button className="profile-menu-btn"
                                                    onClick={this.logout}
                                                >Sign Out</button>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>

                            }

                            <div className='add-card-btn2'>
                                <Link className="link"
                                    to={{ pathname: "/pricing-plan" }} >
                                    <FontAwesomeIcon icon={faPlus}
                                        className="add_card_plus_icon" />
                                    <label className="add-label1">
                                        Add Buisness Card
                                    </label>
                                </Link>
                            </div>

                        </div>
                }

            </div>
        )

    }
    getMobileView = () => {
        const { userName } = this.state
        return (
            <div className="navbar-mobile-container">

                {window.location.href.includes("/listing-author") ?
                    this.getDashboardMobileMenu()
                    :
                    this.getMainMobileMenu()
                }

                {this.state.userName === '' ?

                    <div className='login-link-mobile-container'>
                        <img
                            className="signin-user-img"
                            src={emptyUser} alt=""
                        />
                        <Link to="/login" className="signin-link-mobile">
                            Sign In
                        </Link>
                    </div>

                    :

                    window.location.href.includes("listing-author") ?
                        <div className="login-userName-div-mobile" >
                            <label className="login-userName-lbl-mobile">{userName}</label>
                        </div>
                        :
                        <div className='login-link-container-mobile'>
                            <img
                                className="name-user-img-mobile"
                                src={emptyUser} alt=""
                            />
                            <div
                                className="name-link-mobile"
                                onClick={
                                    () => window.location.href = "/listing-author?dashboard=listings"
                                }
                            >
                                Hi {userName}
                            </div>
                        </div>
                }

                <HashLink
                    className="logo-link-mobile"
                    to="/#home">
                    <img className="logo-link-img-mobile" src={logo1} alt=""></img>
                </HashLink>

            </div>
        )

    }

    render() {
        return (
            <div>
                {window.location.href.includes("/card") ?
                    null
                    :
                    <div className="navbar-main-container">
                        {window.innerWidth < 780 ?
                            this.getMobileView()
                            :
                            this.getDesktopView()
                        }
                    </div>
                }
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        userName1: state.userName,
    };
};


export default compose(
    withRouter, withFirebase, connect(mapStateToProps)
)(NavBar)
