import React, { Component } from 'react';
import { withFirebase } from '../firebase';
import './PasswordForget.scss'

class PasswordForget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
    };
  }


  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;
    if (email === '') {
      this.setState({
        error: "enter a valid email",
      });
    } else {
      this.props.firebase
        .doPasswordReset(email)
        .then(() => {
          alert("email sent successfuly!")
          window.location.href = "/login"
        })
        .catch(error => {
          this.setState({ error });
        });
    }
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <div className="pass-container">
        <div className="bg-overly">
          <form
            className="login-form-container"
            onSubmit={this.onSubmit}>

            <label className="pass-title">
              Enter the email you will want to get the passord change form to:
            </label>

            <input
              className="pass-input"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="email..."
            />
            <button
              className="login-ok-btn"
              style={{ opacity: isInvalid === true ? 0.5 : 1 }}
              disabled={isInvalid}
              type="submit">
              OK
            </button>
            {error ?
              <p className="error_lable">
                {error}
              </p>
              :
              null
            }

          </form>
        </div>
      </div>
    )
  }
}

export default withFirebase(PasswordForget);