import React, { Component } from 'react';
import './AdvantagesComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPaintBrush, faSms, faPhone, faMapMarkerAlt, faAddressCard,
    faImage, faGlobe, faShareSquare, faAddressBook,
} from '@fortawesome/free-solid-svg-icons'

import { FacebookIcon, WhatsappIcon } from "react-share";

import advImg from '../images/advantages-img.png'
import advIpadImg from '../images/advantages-ipad-img.PNG'
import send_during_call_icon from '../images/send_during_call_icon2.png'

class AdvantagesComponent extends Component {
    constructor() {
        super();
        this.state = {}
    }
    componentDidMount() { }

    getMobileView = () => {
        return (
            <div className="all-adv-boxes-div">
                {window.innerHeight > 1020 ?
                    <img className="adv-img-ipad" src={advIpadImg} alt="" />
                    :
                    <div className="all-adv-boxes">

                        
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FacebookIcon
                                    className="adv-mobile-icon"
                                    bgStyle={{ fill: "#50d0e3" }}
                                    round />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Social Media</label>
                                <label className="adv-inside-mini-title">
                                    Facebook, Instagram, Youtube, Linkdin, Twitter
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faSms} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">SMS no answer</label>
                                <label className="adv-inside-mini-title">
                                    Auto sending message when missed call
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <img src={send_during_call_icon} className="adv-mobile-icon2" alt="" />
                            </div>
                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Sending card during call</label>
                                <label className="adv-inside-mini-title">
                                    By one click to the number you are talking with
                                </label>
                            </div>
                        </div>

                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faAddressBook} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">NG Cards Book</label>
                                <label className="adv-inside-mini-title">
                                    keep all saved received cards in one place
                                </label>
                            </div>
                        </div>
                        
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faPhone} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Quick call</label>
                                <label className="adv-inside-mini-title">
                                    Quick call in one click
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">

                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faPaintBrush} />
                            </div>
                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Amazing Design</label>
                                <label className="adv-inside-mini-title">
                                    A wide range of themes
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">

                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faImage} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Buisness Gallery</label>
                                <label className="adv-inside-mini-title">
                                    Photoes and Video
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faMapMarkerAlt} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Navigation</label>
                                <label className="adv-inside-mini-title">
                                    Direct to Waze application
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faAddressCard} />
                            </div>
                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Save as Contact</label>
                                <label className="adv-inside-mini-title">
                                    Save the buisness card as contact
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <WhatsappIcon
                                    className="adv-mobile-icon"
                                    bgStyle={{ fill: "#50d0e3" }}
                                    size="30px"
                                    round />
                            </div>
                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Whatsapp</label>
                                <label className="adv-inside-mini-title">
                                    Send a message through Whatsapp
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faGlobe} />
                            </div>
                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Link to Website</label>
                                <label className="adv-inside-mini-title">
                                    Quick transition to the website
                                </label>
                            </div>
                        </div>
                        <div className="adv-box1">
                            <div className="round-blue-circle">
                                <FontAwesomeIcon className="adv-mobile-icon"
                                    icon={faShareSquare} />
                            </div>

                            <div className="adv-box1-titles">
                                <label className="adv-inside-title">Easy Card Sharing</label>
                                <label className="adv-inside-mini-title">
                                    By SMS, Whatsapp, Email and Facebook
                                </label>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
    render() {
        return (
            <div id="advantages" className="advantages-component-main-container">

                <div className="advantages-div">
                    <label
                        className={window.innerWidth < 1100 ?
                            "adv-title-mobile" : "adv-title"}>
                        Advantages
                    </label>

                    {window.innerWidth > 450 ?
                    <div className="adv-img-div">                        
                        < img className="adv-img"
                            src={advImg} alt=""
                        />
                        </div>
                        :
                        this.getMobileView()
                    }
                </div>


            </div>
        )
    }
}

export default AdvantagesComponent;