
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { cardNameForUrl, } from "../utils/utils";
import './MyCardsBookPage.scss';

import emptyUser from '../images/empty_user2.jpg'
import searchIcon from '../images/search-icon.png'

class MyCardsBookPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            usersData: [],
            filteredUsersData: [],
            groupUsersData: [],
            searchTerm: "",
            imgsLoaded: [],
            selected: 0,
            groupName: "",
            needToChangePassword: false
        }
    }
    componentDidMount = async () => {
        this.getData()
    }

    getData = async () => {
        this.props.firebase.getCurrentUser(
            async user1 => {
                let userId = user1.uid
                let user = await this.props.firebase.getUserById(userId)

                let favoritesCardIds = []
                if (user.favoritesCardIds !== undefined) {
                    favoritesCardIds = Object.values(user.favoritesCardIds)
                }

                let cards = []
                const promises = favoritesCardIds.map(async (id, i) => {
                    let c1 = await this.props.firebase.getCardById(id)
                    cards.push(c1)
                })

                let groupName = user.groupName
                let groupCards = []
                let promises2 = null

                if (groupName != undefined) {
                    let groupCardsIds = await this.props.firebase.getUserGroupCards(groupName)
                    promises2 = groupCardsIds.map(async (id, i) => {
                        let c2 = await this.props.firebase.getCardById(id)
                        groupCards.push(c2)
                    })
                }
                let allPromises = promises
                if (promises2 != null) {
                    allPromises = promises.concat(promises2)
                }


                Promise.all(allPromises).then(data => {
                    let imgsLoaded = []
                    cards.map((c, i) => {
                        let name = "img" + i + "_loaded"
                        imgsLoaded.push({ name, loaded: false })
                    })

                    cards.sort(function (a, b) {
                        if (a.businessCardTitle < b.businessCardTitle) { return -1; }
                        if (a.businessCardTitle > b.businessCardTitle) { return 1; }
                        return 0;
                    })

                    this.setState({
                        usersData: cards,
                        userId,
                        filteredUsersData: cards,
                        loading: false,
                        imgsLoaded,
                        groupName,
                        groupUsersData: groupCards,
                        needToChangePassword: user.needToChangePassword === true ? true : false
                    })
                })

            },
            () => {
            })
    }

    getIfImgLoaded = (i1) => {
        let ans = false
        this.state.imgsLoaded.map((obj, i) => {
            if (obj.name.includes(i)) {
                ans = obj.loaded
            }
        })
        return ans
    }
    setImgLoaded = (i1) => {
        let newImgsLoaded = []
        this.state.imgsLoaded.map((obj, i) => {
            if (obj.name.includes(i1)) {
                newImgsLoaded.push({ name: obj.name, loaded: true })
            } else {
                newImgsLoaded.push({ name: obj.name, loaded: obj.loaded })
            }
        })
        this.setState({
            imgsLoaded: newImgsLoaded
        })
    }
    removeFromFavorites = async (card) => {
        let cardId = card.id
        let userId = this.state.userId
        await this.props.firebase.removeFromFavorites(userId, cardId)
        await this.getData()
    }
    goToCard = (id, name) => {
        let name1 = cardNameForUrl(name)
        window.location.href = "/card/" + name1 + "/" + id
    }

    getList = () => {
        const { filteredUsersData, selected } = this.state
        let rows = []
        rows.push(
            <div className="saved-card-top-row" key={"abc"}>
            </div>
        )

        filteredUsersData.map((card, i) => {
            let if1 = this.getIfImgLoaded(i) === true
            let op1 = if1 === true ? 0 : 1
            let op2 = if1 === true ? 1 : 0

            rows.push(
                <div className="saved-card-row" key={i}>
                    <div className="saved-card-im-lbl-div"
                        onClick={() => { this.goToCard(card.id, card.businessCardTitle) }}
                    >
                        <img alt=""
                            className="saved-card-row-img"
                            src={card.faceImageUrl !== undefined && card.faceImageUrl !== "" ? card.faceImageUrl : emptyUser}
                            onLoad={() => {
                                this.setImgLoaded(i)
                            }}
                        />

                        <label className="saved-card-row-lbl">
                            {card.businessCardTitle}
                        </label>
                    </div>
                    {selected === 0 ?
                        <FontAwesomeIcon
                            className="saved-card-row-x-btn"
                            icon={faTimes}
                            color="grey"
                            onClick={() => this.removeFromFavorites(card)}
                        />
                        :
                        null
                    }
                </div>
            )
        })
        return rows
    }

    onChangeSearchTerm = event => {
        let term = event.target.value
        let usersData1 = this.state.usersData.filter(function (user) {
            return user.businessCardTitle.toLowerCase().includes(term.toLowerCase())
        })
        this.setState({
            searchTerm: event.target.value,
            filteredUsersData: usersData1
        })
    }

    selectTab = async (tab) => {
        const { usersData, groupName, groupUsersData } = this.state
        switch (tab) {
            case 0:
                this.setState({
                    selected: tab,
                    filteredUsersData: usersData.sort(function (a, b) {
                        if (a.businessCardTitle < b.businessCardTitle) { return -1; }
                        if (a.businessCardTitle > b.businessCardTitle) { return 1; }
                        return 0;
                    })
                })
                break
            case 1:
                let usersData1 = groupUsersData.filter(function (card) {
                    return card.groupName === groupName
                })
                this.setState({
                    selected: tab, filteredUsersData: usersData1.sort(function (a, b) {
                        if (a.businessCardTitle < b.businessCardTitle) { return -1; }
                        if (a.businessCardTitle > b.businessCardTitle) { return 1; }
                        return 0;
                    })
                })
                break
            default:
                break
        }
    }

    getAnnoyingChangePsw = () => {
        if (this.state.needToChangePassword === true) {
            return (
                <div className="annoying-change-psw">
                    <label className="annoying-change-psw-lbl">
                        please update your password
                    </label>
                    <button className="annoying-change-psw-btn"
                        onClick={() => {
                            window.location.href = "/listing-author?dashboard=update-profile"
                        }}>OK</button>
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const { loading, selected, filteredUsersData, searchTerm, needToChangePassword } = this.state

        if (loading === true) {
            return (
                <div className="card-page-inside-loading">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }
        return (
            <div className="saved-cards-main-container">

                {this.getAnnoyingChangePsw()}

                <div className="saved-cards-main-container">

                    <div className="title-and-search">
                        <label className="saved-card-title">
                            All my saved NG Cards ({filteredUsersData.length})
                        </label>

                        <div className="search-div">

                            <input
                                className="my-search"
                                type="text"
                                placeholder="Search Card"
                                name="searchTerm"
                                value={searchTerm}
                                onChange={this.onChangeSearchTerm}
                            />
                            <img src={searchIcon} className="search-img" alt="" />
                        </div>

                    </div>


                    <div className="fav-tabs-div">
                        <div className="fav-tabs">

                            <div className="table-tab2"
                                style={selected === 0 ? { borderBottom: "4px solid skyblue" } : null}
                                onClick={() => this.selectTab(0)}
                            >
                                My Favorite Cards
                            </div>
                            <div className="table-tab2"
                                style={selected === 1 ? { borderBottom: "4px solid skyblue" } : null}
                                onClick={() => this.selectTab(1)}
                            >
                                My Group Cards
                            </div>
                        </div>
                    </div>
                    <div className="saved-card-list-div">
                        {this.getList()}
                    </div>
                </div>

            </div>
        )
    }
}

export default compose(withFirebase)(MyCardsBookPage);