import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import ReactLoading from "react-loading";
import BottomBar from './BottomBar'
import { PaystackConsumer } from 'react-paystack';
import './CheckOutPage.scss';

import emptyUser from '../images/empty_user2.jpg'
import paystackImg from '../images/paystackLogo.png'

const CARD_PRICE_PLAN1_STR = "25,000"
const CARD_PRICE_PLAN2_STR = "53,000"

const CARD_PRICE_PLAN1_NUM = 25000
const CARD_PRICE_PLAN2_NUM = 53000

const PLAN1_NAME = "1 Digital Business Card"
const PLAN2_NAME = "Diamond Card"

class CheckOutPage extends Component {
    constructor() {
        super();
        this.state = {
            usersData: [],
            userId: "",
            userEmail: "",
            nowInStep: 1,
            selectedCards: [],
            selectedCardsIndexes: [0],
            selectedPaymentMethod: 0,
            recurringPaymentChecked: false,
            totalPrice: 0,
            payMethods: [
                {
                    name: "Paystack",
                    img: paystackImg
                }
            ]
        }
    }

    componentDidMount() {
        this.getData()
    }


    getData = async () => {
        this.props.firebase.getCurrentUser(
            async user1 => {
                let userId = user1.uid
                let userEmail = user1.email
                let user = await this.props.firebase.getUserByEmail(userEmail)
                let cardIds = Object.values(user.cardIds)
                let cards = []

                const promises = cardIds.map(async (id, i) => {
                    let c1 = await this.props.firebase.getCardById(id)
                    cards.push(c1)
                })
                Promise.all(promises).then(data => {
                    let filteredCards = cards.filter(card => {
                        return card.status !== "published"
                    })

                    this.setState({
                        usersData: filteredCards,
                        userEmail,
                        userId,
                    }, () => {
                        this.setState({
                            selectedCardsIndexes: [0],
                            selectedCards: [this.state.usersData[0]]
                        }, () => {
                            
                        })
                    })
                })
            },
            () => {
            }
        )
    }

    onPayMethodChanged = (i) => {
        this.setState({ selectedPaymentMethod: i })
    }

    getChosenPayMethod = () => {
        const { payMethods, selectedPaymentMethod, recurringPaymentChecked } = this.state
        let method = payMethods[selectedPaymentMethod]
        return (
            <div>
                <div className="pay-method-row">

                    <label className="pay-card-name-lbl2">
                        {method.name}
                    </label>

                    <img alt=""
                        className="pay-card-row-img"
                        src={method.img}
                    />

                </div>

                <div className="recurring-payment-div">                   
                </div>

            </div>
        )
    }
    getPayMethods = () => {
        const { payMethods, selectedPaymentMethod } = this.state

        let rows = []

        payMethods.map((method, i) => {

            rows.push(
                <div className="pay-method-row" key={i}>

                    <input type="radio" className="radio-btn"
                        checked={selectedPaymentMethod === i ? true : false}
                        onChange={() => this.onPayMethodChanged(i)}
                    />


                    <label className="pay-card-name-lbl">
                        {method.name}
                    </label>

                    <img
                        className="pay-card-row-img"
                        src={method.img} alt=""
                    />

                </div>
            )
        })
        return rows
    }

    removeFromCards = (i) => {
        const { usersData, selectedCards, selectedCardsIndexes } = this.state

        let array = [...selectedCards]
        let index = selectedCards.indexOf(usersData[i])

        let array2 = [...selectedCardsIndexes]
        let index2 = selectedCardsIndexes.indexOf(i)

        if (index !== -1) {
            array.splice(index, 1)
            array2.splice(index2, 1)
            this.setState({
                selectedCards: array,
                selectedCardsIndexes: array2,
            }, () => {
            })
        }
    }

    addToCards = (i) => {
        this.setState({
            selectedCards: this.state.selectedCards.concat(this.state.usersData[i]),
            selectedCardsIndexes: this.state.selectedCardsIndexes.concat(i),
        })
    }
    onCardChanged = (i) => {
        if (this.state.selectedCardsIndexes.includes(i)) {
            this.removeFromCards(i)
        } else {
            this.addToCards(i)
        }
    }

    getCardsToPay = () => {
        const { usersData, selectedCards, selectedCardsIndexes } = this.state
        let rows = []

        usersData.map((card, i) => {

            let cardPrice = card.associatedPlan === PLAN1_NAME ||
                card.associatedPlan === "plan1" ||
                card.associatedPlan === undefined ? "N" + CARD_PRICE_PLAN1_STR : "N" + CARD_PRICE_PLAN2_STR
           
            rows.push(
                <div className="pay-card-row" key={i}>

                    <input type="checkbox"
                        className="radio-btn"
                        checked={selectedCardsIndexes.includes(i) ? true : false}
                        onChange={() => this.onCardChanged(i)}
                    />
                    <img
                        className="pay-card-row-img" alt=""
                        src={card.faceImageUrl !== undefined && card.faceImageUrl !== "" ?
                            card.faceImageUrl : emptyUser}
                    />

                    <label className="pay-card-name-lbl">
                        {card.businessCardTitle}
                    </label>

                    <label className="pay-card-money-lbl">
                        {cardPrice}
                    </label>

                </div>
            )
        })

        return rows
    }

    proceed = () => {
        const { nowInStep, selectedCards } = this.state
        let next = nowInStep + 1
        if (selectedCards.length === 0) {
            alert("please choose a card to pay for!")
        } else {
            this.setState({ nowInStep: next })
        }
    }

    getSelectedCardsToPayFor = () => {
        let rows = []
        let selectedCards1 = this.state.selectedCards
        selectedCards1.map((card, i) => {

            rows.push(
                <div className="pay-card-row" key={i}>
                    <img
                        className="pay-card-row-img" alt=""
                        src={card.faceImageUrl !== undefined && card.faceImageUrl !== "" ?
                            card.faceImageUrl : emptyUser}
                    />

                    <label className="pay-card-name-lbl">
                        {card.businessCardTitle}
                    </label>


                    <label className="pay-card-money-lbl">
                        {card.associatedPlan === PLAN1_NAME ||
                            card.associatedPlan === "plan1" ? "N" + CARD_PRICE_PLAN1_STR : "N" + CARD_PRICE_PLAN2_STR}
                    </label>

                </div>
            )
        })

        let rows2 = []
        let totalPrice = 0
        selectedCards1.map((card, i) => {
            let plus = card.associatedPlan === PLAN1_NAME ||
                card.associatedPlan === "plan1" || card.associatedPlan === "" ||
                card.associatedPlan === undefined ? CARD_PRICE_PLAN1_NUM : CARD_PRICE_PLAN2_NUM
            totalPrice = totalPrice + plus

            let planName = card.associatedPlan === PLAN1_NAME ||
                card.associatedPlan === "plan1" ||
                card.associatedPlan === undefined ? PLAN1_NAME : PLAN1_NAME

            rows2.push(
                <div className="pay-row" key={i}>

                    <label className="pay-associatedPlan-lbl">
                        {planName}
                    </label>

                    <label className="pay-associatedPlan-price">
                        {card.associatedPlan === PLAN1_NAME ||
                            card.associatedPlan === "plan1" || card.associatedPlan === "" ||
                            card.associatedPlan === undefined ? "N" + CARD_PRICE_PLAN1_STR + ".00" : "N" + CARD_PRICE_PLAN2_STR + ".00"}
                    </label>

                </div>
            )
        })

        return (
            <div className="step2-cards-div">
                <label className="selected-items-title">Selected Items</label>
                {rows}
                <div className="step2-items-div">
                    <label className="selected-item-title">ITEM</label>
                    <label className="selected-price-title">PRICE</label>
                </div>
                {rows2}
                <div className="step2-items-div">
                    <label className="selected-total-title">Total</label>
                    <label className="selected-totalPrice-title">N {totalPrice}</label>
                </div>
            </div>
        )
    }
    changeCardsStatusAndGo = async () => {
        const { selectedCards } = this.state
        const promises = selectedCards.map(async (card, i) => {
            this.props.firebase.changeCardStatus(card.id, "published")
        })
        Promise.all(promises).then(data => {
            window.location.href = "/listing-author/?dashboard=listings"
        })
    }
    handlePaystackSuccess = (reference) => {
        this.changeCardsStatusAndGo()
    }

    handlePaystackClose = () => {
        alert('Transaction was not completed, window closed.');
        this.forceUpdate()
    }

    getTotalPrice = () => {
        const { selectedCards } = this.state
        let totalPrice = 0
        selectedCards.map((card, i) => {
            let plus = card.associatedPlan === PLAN1_NAME ||
                card.associatedPlan === "plan1" || card.associatedPlan === "" ||
                card.associatedPlan === undefined ? CARD_PRICE_PLAN1_NUM : CARD_PRICE_PLAN2_NUM
            totalPrice = totalPrice + plus
        })
        return totalPrice
    }

    getCopmponentProps = () => {
        const { userEmail, userId, selectedCards } = this.state

        // The key field here takes your Paystack _public_ key.
        let paystackPublicKey = process.env["REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY"]
        let email = userEmail

        // The amount field has to be converted to the lowest currency unit 
        // by multiplying the value by 100. So if you wanted to charge N50 or $50
        //  or GHS50, you have to multiply 50 * 100 and pass 5000 
        // in the amount field
        let myTotlalPrice = this.getTotalPrice()
        let amount = myTotlalPrice * 100

        let currency = 'USD'

        let time = (new Date()).getTime().toString()
        let ref = userId + "-" + time

        const config = {
            reference: ref,
            email: email,
            amount: amount,
            publicKey: paystackPublicKey,
        };

        const componentProps = {
            ...config,
            text: 'Pay For NG Cards',
            onSuccess: (reference) => this.handlePaystackSuccess(reference),
            onClose: this.handlePaystackClose
        };

        return componentProps
    }

    render() {
        const { usersData, selectedCards, nowInStep } = this.state
        if (usersData.length === 0) {
            return (
                <div className="loading-main-div">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }

        let componentProps = null
        if (nowInStep === 2) {
            componentProps = this.getCopmponentProps()
        }

        return (
            <div id="top" className="checkout-main-container">

                <div className="checkout-title-div">
                    <label className="checkout-title-lbl">Checkout</label>
                </div>

                <div className="top-div">

                    <div className="space-line" />

                    <div className="todo-btns">
                        <div className="btn-and-text">
                            <button className="todo-btn"
                                style={{
                                    background: nowInStep === 1 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >1</button>
                            <label className="todo-lbl"
                                style={{
                                    color: nowInStep === 1 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >SELECT PAYMENT</label>
                        </div>
                        <div className="btn-and-text">
                            <button className="todo-btn"
                                style={{
                                    background: nowInStep === 2 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >2</button>
                            <label className="todo-lbl"
                                style={{
                                    color: nowInStep === 2 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >REVIEW PAYMENT</label>
                        </div>
                        <div className="btn-and-text">
                            <button className="todo-btn"
                                style={{
                                    background: nowInStep === 3 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >3</button>
                            <label className="todo-lbl"
                                style={{
                                    color: nowInStep === 3 ?
                                        "#41a6df" : "gainsboro"
                                }}
                            >DONE</label>
                        </div>
                    </div>

                </div>


                <div className="checkout-bottom-div">

                    <div className="cards-div">
                        {nowInStep === 1 ? this.getCardsToPay() :
                            nowInStep === 2 ? this.getSelectedCardsToPayFor()
                                : null
                        }
                    </div>

                    <div className="pay-method-div">
                        {nowInStep === 1 ? this.getPayMethods() :
                            nowInStep === 2 ? this.getChosenPayMethod() :
                                nowInStep === 3 ? this.getChosenPayMethod()
                                    : null
                        }

                        {nowInStep === 1 ?
                            <div className="proceed-btn-div">
                                <button className="proceed-btn"
                                    onClick={this.proceed}
                                    style={{ background: selectedCards.length > 0 ? "black" : "#bbc2d2" }}
                                >
                                    PROCEED TO NEXT
                                </button>
                            </div>
                            :
                            nowInStep === 2 ?
                                <div className="proceed-btn-div">
                                   <PaystackConsumer {...componentProps} >
                                        {({ initializePayment }) =>
                                            <button
                                                className="proceed-btn"
                                                style={{ background: selectedCards.length > 0 ? "black" : "#bbc2d2" }}
                                                onClick={() => initializePayment(
                                                    this.handlePaystackSuccess, this.handlePaystackClose)}
                                            >Pay For NG Cards
                                            </button>
                                        }
                                    </PaystackConsumer>

                                </div>
                                :
                                <div className="proceed-btn-div">
                                    <button className="proceed-btn"
                                        onClick={this.done}
                                        style={{ background: selectedCards.length > 0 ? "black" : "#bbc2d2" }}
                                    >
                                        DONE!
                                    </button>
                                </div>
                        }

                    </div>
                </div>
                <BottomBar />

            </div>
        )
    }
}

export default compose(withFirebase)(CheckOutPage)