
import React, { Component } from 'react';
import BottomBar from './BottomBar'
import sessionstorage from 'sessionstorage'
import ContactUsComponent from '../homepage components/ContactUsComponent'

import './ChoosePlanPage.scss';

class ChoosePlanPage extends Component {
    constructor() {
        super();
        this.state = {
            inContactUs: false
        }
    }
    componentDidMount() { }

    choosePlan1 = async () => {
        sessionstorage.setItem("associatedPlan", "plan1")
        window.location.href = "/submit-listing/"
    }

    goToContactUs = () => {
        this.setState({
            inContactUs: true
        })
    }

    goToChoosePlan = () => {
        this.setState({
            inContactUs: false
        })
    }

    render() {

        return (
            <div className="choose-plan-main-container">

                <div className="choose-plan-title-bg"></div>
                <div className="title-overly">
                    <label className="title-overly-lbl">---Choose Your Plan---</label>
                </div>
                {this.state.inContactUs === true ?
                    <div className="contact-diamond-div">
                        <div className="need-contact-div" id="contact" >
                            <ContactUsComponent
                                bgColor={"#eff3f6"} textColor={"black"}
                                contactTiltle={"Contact Us For Diamond Plan"}
                            />
                        </div>

                        <button className="contact-diamond-back-btn"
                            onClick={this.goToChoosePlan}
                        >Back</button>

                    </div>

                    :


                    <div className="plan-cards-div">

                        <div className="plan-card-div">
                            <label className="plan-card-top-lbl">
                                Premium NG Card
                            </label>

                            <label className="plan-card-duration-lbl">
                                Single subscription
                            </label>

                            <label className="price-lbl">
                                N 25,000 / year
                            </label>

                            <label className="plan-card-includes-lbl">
                                The price includes card setup,
                                all fetchers, management app and local support
                            </label>

                            <button
                                className="choose-plan-btn"
                                onClick={this.choosePlan1}
                            >
                                CHOOSE PLAN
                            </button>
                        </div>

                        <div className="plan-card-div">
                            <label className="plan-card-top-lbl2">
                                Diamond NG Card
                            </label>
                            <label className="plan-card-duration-lbl">
                                For organizations
                            </label>

                            <label className="price-lbl">
                                Contact Us for price offer
                            </label>

                            <label className="plan-card-includes-lbl">
                                Special package for your organization,
                                Includes all premium features plus special
                                securing {"&"} managment tools
                            </label>

                            <button
                                className="choose-plan-btn"
                                onClick={this.goToContactUs}
                            >
                                CONTACT US
                            </button>

                        </div>

                    </div>
                }

                <div className="bottom-bar-wrapper">
                    <BottomBar />
                </div>

            </div>
        )
    }
}

export default ChoosePlanPage