import React, { Component } from 'react';
import emailjs from 'emailjs-com';

import './ContactUsComponent.scss';

class ContactUsComponent extends Component {
    constructor() {
        super();
        this.state = {
            fullName: "",
            email: "",
            phone: "",
            msg: "",

            showFullNameError: false,
            showEmailError: false,
            showPhoneError: false,
            showMsgError: false,
        }
    }
    componentDidMount() { }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.setState({
                showFullNameError: this.state.fullName === "",
                showEmailError: this.state.email === "",
                showPhoneError: this.state.phone === "",
                showMsgError: this.state.msg === "",
            })
        })
    }
    sendEmail = async () => {
        const { fullName, email, phone, msg } = this.state
        if (fullName === "" || email === "" || phone === "" || msg === "") {
            this.setState({
                showFullNameError: fullName === "",
                showEmailError: email === "",
                showPhoneError: phone === "",
                showMsgError: msg === "",
            })
        }
        else {
            const env = process.env;

            let userId = env["REACT_APP_EMAIL_JS_USER_ID"]
            let serviceId = env["REACT_APP_EMAIL_JS_SERVICE_ID"]
            let templateId = env["REACT_APP_EMAIL_JS_HOME_PAGE_TEMPLATE_ID"]

            const templateParams = {
                fullName: fullName,
                email: email,
                phone: phone,
                msg: msg,
            }
            emailjs.send(
                serviceId,
                templateId,
                templateParams,
                userId
            )
                .then((response) => {
                    alert("email sent Successfully!")
                    this.setState({
                        fullName: "",
                        email: "",
                        phone: "",
                        msg: "",
                    })
                }, (err) => {
                    console.error('FAILED...', err)
                    alert("An error occurred, Please try again")
                });
        }

    }

    getMobileView = () => {
        const {
            fullName, email, phone, msg,
            showFullNameError,
            showEmailError,
            showPhoneError,
            showMsgError,
        } = this.state

        return (
            <div className="contact-inside-div-mobile">
                <div>
                    <div className="contact-mobile-div">
                        <div className="title-div-mobile">
                            <label className="contact-input-label-mobile">Full Name</label>
                            <label className="contact-input-label-must-mobile">*</label>
                        </div>

                        <input
                            className="contact-input-mobile"
                            name="fullName"
                            value={fullName}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Full Name"
                            style={{
                                border: showFullNameError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                        <div className="title-div-mobile">
                            <label className="contact-input-label-mobile">Email</label>
                            <label className="contact-input-label-must-mobile">*</label>
                        </div>

                        <input
                            className="contact-input-mobile"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="email"
                            placeholder="Email"
                            style={{
                                border: showEmailError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                        <div className="title-div-mobile">
                            <label className="contact-input-label-mobile">Phone</label>
                            <label className="contact-input-label-must-mobile">*</label>
                        </div>
                        <input
                            className="contact-input-mobile"
                            name="phone"
                            value={phone}
                            onChange={this.onChange}
                            type="phone"
                            placeholder="Phone"
                            style={{
                                border: showPhoneError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                        <div className="title-div-mobile">
                            <label className="contact-input-label-mobile">Message</label>
                        </div>
                        <textarea
                            className="contact-msg-mobile"
                            name="msg"
                            value={msg}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Write a Message"
                            style={{
                                textAlignVertical: "top",
                                border: showMsgError === true ?
                                    "2px solid red" : "none"
                            }}
                        />

                        <button className="send-btn-mobile"
                            onClick={this.sendEmail}
                        >Send</button>
                    </div>
                </div>
            </div>
        )
    }

    getDesktopView = () => {
        const {
            fullName, email, phone, msg,
            showFullNameError,
            showEmailError,
            showPhoneError,
            showMsgError,
        } = this.state

        return (
            <div className="contact-inside-div">

                <div>
                    <div className="contact-labels-div" >
                        <div className="title-div">
                            <label className="contact-input-label"
                            >Full Name</label>
                            <label className="contact-input-label-must">*</label>
                        </div>
                        <div className="title-div">
                            <label className="contact-input-label">Email</label>
                            <label className="contact-input-label-must">*</label>
                        </div>
                        <div className="title-div">
                            <label className="contact-input-label">Phone</label>
                            <label className="contact-input-label-must">*</label>
                        </div>
                    </div>

                    <div className="contact-top-div">

                        <input
                            className="contact-input"
                            name="fullName"
                            value={fullName}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Full Name"
                            style={{
                                border: showFullNameError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                        <input
                            className="contact-input"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="email"
                            placeholder="Email"
                            style={{
                                border: showEmailError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                        <input
                            className="contact-input"
                            name="phone"
                            value={phone}
                            onChange={this.onChange}
                            type="phone"
                            placeholder="Phone"
                            style={{
                                border: showPhoneError === true ?
                                    "2px solid red" : "none"
                            }}
                        />
                    </div>

                    <div className="contact-msg-box" >

                        <label className="contact-msg-label">Message</label>

                        <textarea
                            className="msg-input"
                            name="msg"
                            value={msg}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Write a Message"
                            style={{
                                textAlignVertical: "top",
                                border: showMsgError === true ?
                                    "2px solid red" : "none"
                            }}
                        />

                        <button
                            onClick={this.sendEmail}
                            className="send-btn">Send</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="contact-component-main-container" id="contact"
                style={{ backgroundColor: this.props.bgColor, color: this.props.textColor }}
            >
                <label
                    className={window.innerWidth < 1100 ? "contact-title-mobile" : "contact-title"}
                >{this.props.contactTiltle}</label>
                {window.innerWidth > 1100 ?
                    this.getDesktopView()
                    :
                    this.getMobileView()
                }
            </div>
        )
    }
}

export default ContactUsComponent;