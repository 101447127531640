
import React, { Component } from 'react';
import QRCode from "qrcode.react";
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import ReactPlayer from 'react-player'

import vCardsJS from 'vcards-js'
import {
    EmailShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import {
    getBackGroundStyle,
    getLblStyle,
    getWazeBtnStyle,
    getBtnStyle,
    getProfileOption,
} from "../utils/utils";

import { withFirebase } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser, faUserCircle, faBookmark, faInfoCircle, faImage, faPaperPlane,
    faPhoneAlt, faComments, faGlobeAmericas,
    faChevronDown, faEdit, faHome, faShareAlt,
    faVideo, faLink, faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import './CardPage.scss';
import DOMPurify from 'dompurify';

import phoneOfficeIcon from '../images/phone-office.png'
import ghoustImg from '../images/ghoust404.PNG'

import share_email_icon from '../images/share_email_icon.png'
import share_link_icon from '../images/share_link_icon.png'
import share_sms_icon from '../images/share_sms_icon.png'
import share_sms_keyboard_icon from '../images/share_sms_keyboard_icon.png'
import share_whatsapp_icon from '../images/share_whatsapp_icon.png'
import emptyUser from '../images/empty_user2.jpg'
import wazeOnlyIcon from '../images/waze-only-icon.PNG'
import instagramIcon from '../images/instagram_icon.png'
import youtubeIcon from '../images/youtube_icon.png'
import saveToHomeScreenAndroidImg from '../images/save_to_homescreen_android.png'
import saveToHomeScreenAppleImg from '../images/save_to_homescreen_apple.png'
import certifiedImg from '../images/certified.png'

class CardPage extends Component {
    constructor() {
        super();
        this.state = {
            userId: "",
            errorGettingCard: false,
            loading: false,
            faceImgLoaded: false,
            coverImgLoaded: false,
            person: null,
            showingEditBar: true,
            showContactInfo: false,
            showAbout: false,
            showGallery: false,
            urlName: "",
            cardId: "",
            userRole: null,
            status: null,

            showHiddenContactDiv: false,
            showHiddenAboutDiv: false,
            showHiddenPhotosGalleryDiv: false,
            showHiddenVideossGalleryDiv: false,
            showHiddenDirectMsgDiv: false,
            showHiddenLinkDiv: false,
            showHiddenShareDiv: false,

            fullName: "",
            email: "",
            phone: "",
            msg: "",
            sendEmailLoading: false,

            showFullNameError: false,
            showEmailError: false,
            showPhoneError: false,
            showMsgError: false,

            isInPersonCardIds: false,
            showShrePopup: false,
            fullSizeOpen: false,
            imgForFullScreen: null,
            videoForFullScreen: null,

            showAppleOrAndroidPopup: false,

            showGhoust: false,
            showSmsKeyboard: false,
            smsNumber: "",

            isInPortriat: true,

            needToChangePassword: false,
            bgNames: [],
        }
    }
    componentDidMount = async () => {
        this.getPerson()
    }

    createDynamicManifest = () => {

        let name = this.state.urlName
        name = name.replaceAll(/%20/g, " ")
        name = name.replaceAll("-", " ")
        let iconUrl = this.state.person.faceImageUrl

        if (iconUrl === "") {
            iconUrl = emptyUser
        }

        var myDynamicManifest = {
            "name": name,
            "short_name": name,
            "description": "a NetGo Card",
            "start_url": window.location.href,
            "background_color": "#000000",
            "theme_color": "#0f4a73",
            "icons": [
                {
                    "src": iconUrl,
                    "sizes": "192x192",
                    "type": "image/png"
                },
                {
                    "src": iconUrl,
                    "sizes": "512x512",
                    "type": "image/png"
                },
                {
                    "src": iconUrl,
                    "sizes": "64x64 32x32 24x24 16x16",
                    "type": "image/png"
                }
            ],
            "display": "standalone",
            "orientation": "portrait",
        }
        const stringManifest = JSON.stringify(myDynamicManifest)
        const blob = new Blob([stringManifest], { type: 'application/json' })
        const manifestURL = URL.createObjectURL(blob)
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL)

    }

    getPerson = async () => {
        this.setState({
            loading: true,
        }, async () => {
            this.props.firebase.getCurrentUser(
                async user => {
                    let email = user.email
                    let us = await this.props.firebase.getUserById(user.uid)
                    let needToChangePassword1 = us.needToChangePassword === true ? true : false

                    let userRole = us.userRole1
                    this.getCard(email, userRole, user.uid, needToChangePassword1)
                },
                () => {
                    this.getCard(null, null, null, false)
                }
            )

        })
    }

    getCard = async (email, userRole, userId, needToChangePassword) => {
        let url = window.location.pathname
        let strings = url.split("/")
        if (strings.length < 2) {
            this.setState({ loading: false, errorGettingCard: true, needToChangePassword })
        } else {
            let cardId = strings[strings.length - 1]
            let name = strings[strings.length - 2]
            let card = await this.props.firebase.getCardById(cardId)
            if (card) {

                let isInPersonCardIds = false
                // let bgNames = await this.props.firebase.getAllBackgroundNames()
                if (email !== null && card !== null) {
                    isInPersonCardIds = await this.props.firebase.getIsInPersonCardIds(
                        email, card.id)
                }

                if (card && card.status !== "published" && isInPersonCardIds === false && userRole !== "admin") {
                    this.setState({ loading: false, showGhoust: true, needToChangePassword })
                } else {
                    let urlName = name

                    if (userRole !== "admin" && isInPersonCardIds === false) {
                        this.props.firebase.addOneToCardViews(cardId)
                    }

                    const favicon = document.getElementById('favicon')
                    const favicon2 = document.getElementById('favicon2')

                    let iconUrl = card.faceImageUrl

                    if (iconUrl === "") {
                        iconUrl = emptyUser
                    }
                    favicon.href = iconUrl
                    favicon2.href = iconUrl

                    const og_img_icon = document.getElementById('og_img')
                    og_img_icon.content = iconUrl

                    const og_img_icon_secure = document.getElementById('og_img_secure')
                    og_img_icon_secure.content = iconUrl

                    if (card !== null) {
                        this.setState({
                            person: card,
                            loading: false,
                            urlName, cardId,
                            isInPersonCardIds,
                            userId,
                            userRole,
                            needToChangePassword,
                            status: card.status
                            // bgNames
                        }, () => {
                            this.createDynamicManifest()
                        })
                    } else {
                        this.setState({
                            loading: false,
                            errorGettingCard: true,
                            isInPersonCardIds,
                            userRole,
                            needToChangePassword
                        })
                    }
                }
            } else {
                this.setState({
                    loading: false,
                    errorGettingCard: true,
                    isInPersonCardIds: false,
                    userRole,
                    needToChangePassword
                })
            }

        }
    }


    getSaveAsBottomBtns = () => {

        const { isInPersonCardIds, status } = this.state

        if (isInPersonCardIds === true && status !== "published") {
            return (
                <div className="sticky-save-btns-div">
                    <span className='go-to-pay-div'>
                        <label className='go-to-pay' onClick={() => window.location.href = "/payment-checkout/"}>
                            Your card is pending!
                        </label>
                        <div className='go-to-pay-div-row'>

                            <label className='go-to-pay' onClick={() => window.location.href = "/payment-checkout/"}>
                                click
                            </label>
                            <label className='go-to-pay-checkout' onClick={() => window.location.href = "/payment-checkout/"}>
                                checkout
                            </label>
                            <label className='go-to-pay' onClick={() => window.location.href = "/payment-checkout/"}>
                                to complete purchase
                            </label>
                        </div>
                    </span >
                </div>
            )
        } else {
            return (
                <div className="sticky-save-btns-div">
                    <div className="save-div"
                        onClick={this.saveVcard}
                    >
                        <FontAwesomeIcon className="save-btn-icon" icon={faUser} />
                        <label className="save-btn-lbl">Save as</label>
                        <label className="save-btn-lbl">Contact</label>
                    </div>

                    <div className="save-div"
                        onClick={this.saveToNgCardBook}
                    >
                        <FontAwesomeIcon className="save-btn-icon" icon={faBookmark} />
                        <label className="save-btn-lbl">Save to</label>
                        <label className="save-btn-lbl">My Cards Book</label>
                    </div>

                    <div className="save-div"
                        onClick={this.showAppleOrAndroidPopup}
                    >
                        <FontAwesomeIcon className="save-btn-icon" icon={faHome} />
                        <label className="save-btn-lbl">Save to</label>
                        <label className="save-btn-lbl">HomeScreen</label>
                    </div>
                </div >
            )
        }
    }

    showEditBarIf = () => {
        if (this.state.showingEditBar === false) {
            this.setState({
                showingEditBar: true
            })
        } else {
            this.hideEditBar()
        }
    }

    hideEditBar = () => {
        this.setState({
            showingEditBar: false
        })
    }

    createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    getPersotSocialValue = (socialType) => {
        const { person } = this.state
        let ans = ""
        if (person.allSocialMedia !== undefined) {
            var result = person.allSocialMedia.filter(obj => {
                return obj.type === socialType
            })
            if (result.length > 0) {
                if (result[0] !== undefined) {
                    ans = result[0].value
                }
            }
        }
        return ans
    }

    getHiddenContactInformationDiv = () => {
        const { person } = this.state
        return (
            <div className="hidden-div">
                <div className="hidden-info-div">

                    {person.phone !== "" && person.phone !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Phone:</label>
                            <label className="info-val">{person.phone}</label>
                        </div>
                        : null}

                    {person.landline !== "" && person.landline !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Landline:</label>
                            <label className="info-val">{person.landline}</label>
                        </div>
                        : null}

                    {person.whatsapp !== "" && person.whatsapp !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Whatsapp:</label>
                            <label className="info-val">{person.whatsapp}</label>
                        </div>
                        : null}


                    {person.email !== "" && person.email !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Email:</label>
                            <label className="info-val">{person.email}</label>
                        </div>
                        : null}

                    {person.fullAddress !== "" && person.fullAddress !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Address:</label>
                            <label className="info-val">{person.fullAddress}</label>
                        </div>
                        : null}

                    {person.website !== "" && person.website !== undefined ?
                        <div className="hidden-inside-row">
                            <label className="info-title">Website:</label>
                            <label className="info-val">{person.website}</label>
                        </div>
                        : null}
                </div>
            </div>
        )
    }


    getHiddenShareDiv = () => {
        let url = window.location.href
        let encodedURL = encodeURIComponent(url)
        let msg = "Click this link to open the card of " +
            this.state.person.businessCardTitle + " " + encodedURL

        return (
            <div className="hidden-div">
                <div className="hidden-share-div">
                    <img alt="" src={share_link_icon} className="share_img_btn"
                        onClick={() => {
                            if (navigator.clipboard !== undefined) {
                                navigator.clipboard.writeText(window.location.href)
                                alert("Link Copied Successfully")
                            } else {
                                const elem = document.createElement('textarea');
                                elem.value = window.location.href;
                                document.body.appendChild(elem);
                                elem.select();
                                document.execCommand('copy');
                                document.body.removeChild(elem);
                                alert("Link Copied Successfully")
                            }
                        }}
                    />

                    <img alt="" src={share_email_icon} className="share_img_btn"
                        onClick={() => {
                            window.location.href = "mailto:?subject=Check out this NG CARD&body=" + msg
                        }}
                    />
                    <img alt="" src={share_whatsapp_icon} className="share_img_btn"
                        onClick={() => {
                            window.open("whatsapp://send?text=" + msg)
                        }}
                    />

                    <img alt="" src={share_sms_icon} className="share_img_btn"
                        onClick={() => { window.open('sms:' + "" + "?body=" + msg) }}
                    />
                    <img alt="" src={share_sms_keyboard_icon} className="share_img_btn"
                        onClick={() => {
                            this.setState({ showSmsKeyboard: true }, () => {
                                this.myInp.focus()
                            })
                        }}
                    />
                </div>
            </div>
        )
    }
    getHiddenAboutDiv = () => {
        const { person } = this.state
        return (
            <div className="hidden-div">
                <div className="hidden-about-div"
                    dangerouslySetInnerHTML={
                        this.createMarkup(person.currentContentAsHTML)}>
                </div>
            </div>
        )
    }
    getHiddenLinkDiv = () => {
        const { person } = this.state
        return (
            <div className="hidden-div">
                <div className="hidden-link-div">
                    <label className="hidden-link-lbl">{person.linkDescription}</label>
                    <Link to={"//" + person.linkUrl} target="_blank"
                        className="hidden-link-url">
                        {person.linkUrl}
                    </Link>
                </div>
            </div>
        )
    }

    getHiddenVideosGalleryDiv = () => {
        const { person } = this.state
        let arr = null
        if (person !== undefined && person !== null &&
            person.videoObjects !== undefined && person.videoObjects !== null) {
            arr = Object.values(person.videoObjects)
        }
        return (
            <div className="hidden-video-div">
                {arr !== undefined && arr !== null ?
                    arr.map((v, i) => {
                        return (
                            <div key={i}
                                className={"video-div-in-gallery"}
                                onClick={() => {
                                    this.setState({
                                        videoForFullScreen: v,
                                        imgForFullScreen: null,
                                        fullSizeOpen: true
                                    })
                                }}
                            >
                                {v !== undefined ?
                                    <button className="video-btn-in-gallery"
                                    >{v.videoTitle}</button>
                                    :
                                    null
                                }
                            </div>
                        )
                    })
                    :
                    null
                }
            </div>
        )
    }

    getHiddenPhotosGalleryDiv = () => {
        const { person } = this.state
        let arr = null
        if (person !== undefined && person !== null &&
            person.galleryImageUrls !== undefined && person.galleryImageUrls !== null) {
            arr = Object.values(person.galleryImageUrls)
        }
        return (
            <div className="hidden-div">
                <div className="gallery-hidden-div">

                    {arr !== undefined && arr !== null ?
                        arr.map((img, i) => {
                            return (
                                <div key={i}
                                    className={"img-div-in-gallery"}
                                    onClick={() => {
                                        this.setState({
                                            imgForFullScreen: img,
                                            videoForFullScreen: null,
                                            fullSizeOpen: true
                                        })
                                    }}
                                >
                                    {img !== undefined ?
                                        <img className="img-in-gallery"
                                            src={img}
                                            alt="img"
                                        />
                                        :
                                        null
                                    }
                                </div>
                            )
                        })
                        :
                        null
                    }

                </div>
            </div>
        )
    }

    onChangeDirectMsg = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.setState({
                showFullNameError: this.state.fullName === "",
                showEmailError: this.state.email === "",
                showPhoneError: this.state.phone === "",
                showMsgError: this.state.msg === "",
            })
        })
    }

    sendEmail = async () => {
        const { userId, person, fullName, email, phone, msg } = this.state

        this.setState({
            sendEmailLoading: true
        }, async () => {

            let theSenderId = person.userId
            if (theSenderId === undefined || theSenderId === null) {
                theSenderId = ""
            }

            let theReciverId = userId
            if (theReciverId === undefined || theReciverId === null) {
                theReciverId = ""
            }

            await this.props.firebase.sendSendgridEmail(
                fullName,
                email,
                person.email,
                phone,
                msg,
                person.id,
                theReciverId,
                theSenderId,
            )

            this.setState({
                sendEmailLoading: false,
                fullName: "",
                email: "",
                phone: "",
                msg: "",
            })
        })
    }

    getHiddenDirectMsgDiv = () => {
        const {
            fullName, email, phone, msg,
            sendEmailLoading,
            showFullNameError,
            showEmailError,
            showPhoneError,
            showMsgError,
        } = this.state

        let btnDisabled = fullName === "" || email === "" ||
            phone === "" || msg === ""

        return (
            <div className="hidden-div">
                <input
                    className="hidden-contact-input"
                    name="fullName"
                    value={fullName}
                    onChange={this.onChangeDirectMsg}
                    type="text"
                    placeholder="Full Name"
                    style={{
                        border: showFullNameError === true ?
                            "2px solid red" : "none"
                    }}
                />
                <input
                    className="hidden-contact-input"
                    name="email"
                    value={email}
                    onChange={this.onChangeDirectMsg}
                    type="email"
                    placeholder="Email"
                    style={{
                        border: showEmailError === true ?
                            "2px solid red" : "none"
                    }}
                />
                <input
                    className="hidden-contact-input"
                    name="phone"
                    value={phone}
                    onChange={this.onChangeDirectMsg}
                    type="phone"
                    placeholder="Phone"
                    style={{
                        border: showPhoneError === true ?
                            "2px solid red" : "none"
                    }}
                />
                <input
                    className="hidden-contact-input"
                    name="msg"
                    value={msg}
                    onChange={this.onChangeDirectMsg}
                    type="text"
                    placeholder="Write Message"
                    style={{
                        border: showMsgError === true ?
                            "2px solid red" : "none"
                    }}
                />

                {sendEmailLoading === true ?
                    <div className="hidden-contact-send-btn-loading-div">
                        <ReactLoading type={"spinningBubbles"} color="white"
                            height={30} width={30}
                        />
                    </div>
                    :
                    <button
                        onClick={this.sendEmail}
                        className="hidden-contact-send-btn"
                        disabled={btnDisabled}
                        style={{
                            opacity: btnDisabled === true ? 0.6 : 1,
                            cursor: btnDisabled === true ? "not-allowed" : "pointer"
                        }}
                    >
                        Send
                    </button>
                }
            </div>
        )
    }

    getNumOfBtns = (twitter, facebook, instagram, linkedin, youtube) => {
        const { person } = this.state
        let ans = 0;
        if (person.phone !== undefined && person.phone !== undefined && person.phone !== "") {
            ans = ans + 2 //phone + sms
        }
        if (person.whatsapp !== undefined && person.whatsapp !== undefined && person.whatsapp !== "") {
            ans++
        }
        if (person.landline !== undefined && person.landline !== undefined && person.landline !== "") {
            ans++
        }
        if (person.fullAddress !== undefined && person.fullAddress !== undefined && person.fullAddress !== "") {
            ans++
        }
        if (person.website !== undefined && person.website !== undefined && person.website !== "") {
            ans++
        }
        if (person.email !== undefined && person.email !== undefined && person.email !== "") {
            ans++
        }
        if (twitter !== null) {
            ans++
        }
        if (facebook !== null) {
            ans++
        }
        if (instagram !== null) {
            ans++
        }
        if (linkedin !== null) {
            ans++
        }
        if (youtube !== null) {
            ans++
        }
        return ans
    }

    getCardBtns = () => {
        const {
            person, selectedIconBorderShape, selectedIconBorderColor, selectedTextColor
        } = this.state

        let btnStyle = getBtnStyle(person, selectedIconBorderShape, selectedIconBorderColor)
        let lblStyle = getLblStyle(person, selectedTextColor)
        let wazeBtnStyle = getWazeBtnStyle(person, selectedIconBorderShape, selectedIconBorderColor)

        let twitter = this.getPersotSocialValue("Twitter") || null
        let facebook = this.getPersotSocialValue("Facebook") || null
        let instagram = this.getPersotSocialValue("Instagram") || null
        let linkedin = this.getPersotSocialValue("LinkedIn") || null
        let youtube = this.getPersotSocialValue("YouTube") || null

        let theAddress = ""
        if (person.fullAddress !== undefined) {
            theAddress = person.fullAddress
        }

        let iconSize = 35
        if (window.innerWidth === 1280) {
            iconSize = 25
        }

        return (
            <div className="the-card-btns" >
                {person.phone !== "" && person.phone !== undefined ?
                    <div className="card-btn-div">
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                            onClick={() => {
                                window.location.href = 'tel://' + person.phone
                            }}
                        >
                            <FontAwesomeIcon icon={faPhoneAlt} className="btn-icon" />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Phone</label>
                    </div>
                    : null}

                {person.phone !== "" && person.phone !== undefined ?
                    <div className="card-btn-div">
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                            onClick={() => {
                                window.open('sms:' + person.phone)
                            }}
                        >
                            <FontAwesomeIcon icon={faComments} className="btn-icon" />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>SMS</label>
                    </div>
                    : null}

                {person.whatsapp !== "" && person.whatsapp !== undefined ?
                    <div className="card-btn-div">
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                            onClick={() => {
                                let newNumber = person.phone
                                if (newNumber[0] === "0") {
                                    let a = "+972"
                                    let b = newNumber.substring(1, newNumber.length)
                                    newNumber = a + b
                                }
                                window.open("whatsapp://send?text=" + "" + "&phone=" + newNumber)
                            }}
                        >
                            <WhatsappIcon
                                size={iconSize}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Whatsapp
                        </label>
                    </div>
                    : null}

                {person.landline !== "" && person.landline !== undefined ?
                    <div className="card-btn-div">
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                            onClick={() => {
                                window.location.href = 'tel://' + person.landline
                            }}
                        >
                            <img src={phoneOfficeIcon} className="btn-icon" alt="" />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Landline
                        </label>
                    </div>
                    : null}


                {theAddress !== "" ?
                    <div className="card-btn-div">

                        <Link className="card-btn-circle-div"
                            to={"//www.waze.com/ul?q=66%" +
                                theAddress.replaceAll(" ", "%20").replaceAll(",", "%20") +
                                "&navigate=yes"
                            }
                            target="_blank"
                            style={wazeBtnStyle}
                        >
                            <img className="btn-icon"
                                src={wazeOnlyIcon} alt=""
                            />
                        </Link>
                        <label className="card-btn-lbl"
                            style={lblStyle}>Navigate</label>

                    </div>
                    : null}

                {person.website !== "" && person.website !== undefined ?
                    <div className="card-btn-div">
                        <Link className="card-btn-circle-div"
                            to={"//" + person.website} target="_blank"
                            style={btnStyle}
                        >
                            <FontAwesomeIcon icon={faGlobeAmericas} className="btn-icon" />
                        </Link>
                        <label className="card-btn-lbl"
                            style={lblStyle}>Website</label>
                    </div>
                    : null}

                {person.email !== undefined && person.email !== null && person.email !== "" ?
                    <div className="card-btn-div">
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                            onClick={() => {
                                window.location.href = "mailto:" + person.email;
                            }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} className="btn-icon" />
                        </div>
                        <label className="card-btn-lbl"
                            style={lblStyle}>Email</label>
                    </div>
                    : null}

                {twitter !== null ?

                    <div className={"card-btn-div"}>
                        <Link className="card-btn-circle-div"
                            to={"//" + twitter} target="_blank"
                            style={btnStyle}
                        >
                            <TwitterIcon
                                size={iconSize}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </Link>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Twitter
                        </label>
                    </div>
                    : null}

                {facebook !== null ?

                    <div className={"card-btn-div"}>
                        <Link className="card-btn-circle-div"
                            to={"//" + facebook} target="_blank"
                            style={btnStyle}
                        >

                            <FacebookIcon
                                size={iconSize}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </Link>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Facebook
                        </label>
                    </div>
                    : null}

                {instagram !== null ?

                    <div className={"card-btn-div"}>
                        <Link className="card-btn-circle-div"
                            to={"//" + instagram} target="_blank"
                            style={btnStyle}
                        >
                            <img src={instagramIcon} className="btn-icon" alt="" />
                        </Link>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Instagram
                        </label>
                    </div>
                    : null}

                {linkedin !== null ?

                    <div className={"card-btn-div"}>
                        <Link className="card-btn-circle-div"
                            to={"//" + linkedin} target="_blank"
                            style={btnStyle}
                        >
                            <LinkedinIcon
                                size={iconSize}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </Link>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Linkedin
                        </label>
                    </div>
                    : null}

                {youtube !== null ?

                    <div className={"card-btn-div"}>
                        <Link className="card-btn-circle-div"
                            to={"//" + youtube} target="_blank"
                            style={btnStyle}
                        >
                            <img src={youtubeIcon} className="btn-icon" alt="" />
                        </Link>
                        <label className="card-btn-lbl" style={lblStyle}>
                            Youtube
                        </label>
                    </div>
                    : null}

            </div>


        )
    }

    getShareWithDiv = () => {

        let shareUrl = "www.google.com"
        let title = "Check out this NG Card!"

        let msg = "Click on the link and enter the card of " +
            this.state.person.businessCardTitle + ": " + window.location.href
        return (
            <div className="share-popup">

                <EmailShareButton
                    url={msg}
                    subject={title}
                    className="share-button1">
                    <EmailIcon
                        size={45}
                        round />
                </EmailShareButton>

                <WhatsappShareButton
                    url={msg}
                    className="share-button1">
                    <WhatsappIcon
                        size={45}
                        round />
                </WhatsappShareButton>

                <div
                    className="share-button1"
                    onClick={() => { window.open('sms:' + "" + "?body=" + msg) }}
                >
                    <FontAwesomeIcon icon={faComments} color="white" />
                </div>
            </div>
        )
    }

    getShareButton = () => {
        const { showShrePopup } = this.state
        return (
            <div className="the-share-div">

                {showShrePopup === true ? this.getShareWithDiv() : null}

                <button className="the-share-btn" id="the-share-btn"
                    onClick={() => this.setState({
                        showShrePopup: !showShrePopup
                    })}
                >
                    Share this card

                    <FontAwesomeIcon icon={faShareAlt} size="2x"
                        className="share-btn-icon3" />
                </button>

            </div>
        )
    }
    getBottomCreateYourOwnDiv = () => {
        return (
            <div className="all-bottom-div">

                <label className="click-to-create-div">
                    <label className="click-here-lbl"
                        onClick={() => { window.location.href = "/pricing-plan" }}
                    >
                        Click Here
                    </label>
                    <label className="to-create-lbl">
                        to create your own card
                    </label>
                </label>
            </div>
        )
    }

    getFaceImages = () => {
        const { person } = this.state
        let profileOption = getProfileOption(person, null)
        return (
            <div className="top-person-images">

                {person !== undefined && person !== null && person.coverImageUrl !== "" ?
                    <img alt=""
                        src={person.coverImageUrl}
                        className={"card-cover-img"}
                        onLoad={() => this.setState({ coverImgLoaded: true })}
                    />
                    :
                    <div className={"card-cover-img"} style={{ width: "100vw", height: "5cm" }}></div>
                }

                {person !== undefined && person !== null && person.faceImageUrl !== undefined && person.faceImageUrl !== "" ?

                    <img
                        src={person.faceImageUrl} alt=""
                        className={"card-face-img"}
                        style={{
                            borderRadius: profileOption === "Circle" ? "50%" :
                                profileOption === "Rounded Rectangle" ? "10%" : "0%",
                        }}
                        onLoad={() => this.setState({ faceImgLoaded: true })}
                    />
                    :
                    <div>
                        <img src={emptyUser} alt=""
                            className={"card-face-img"}
                            style={{
                                borderRadius: profileOption === "Circle" ? "50%" :
                                    profileOption === "Rounded Rectangle" ? "10%" : "0%",
                            }}
                            onLoad={() => this.setState({ faceImgLoaded: true })}
                        />
                    </div>

                }
            </div>
        )
    }

    getPersonTitles = () => {
        const { person, selectedTextColor } = this.state
        const lblStyle = getLblStyle(person, selectedTextColor)
        return (
            <div className="card-titles3">
                <label className="name-lbl" style={lblStyle}>
                    {person.businessCardTitle}
                </label>

                <label className="job-lbl" style={lblStyle}>
                    {person.businessJobTitle}
                </label>
            </div>
        )
    }

    getHiddenInfo = () => {
        const {
            person,
            showHiddenContactDiv,
            showHiddenAboutDiv,
            showHiddenPhotosGalleryDiv,
            showHiddenVideossGalleryDiv,
            showHiddenDirectMsgDiv,
            showHiddenLinkDiv,
            showHiddenShareDiv
        } = this.state

        return (

            <div className="hidden-info-btns-div">

                {person.currentContentAsHTML !== undefined && person.currentContentAsHTML !== "" ?
                    <div className="hidden-info-btn" style={{
                        borderBottom: showHiddenAboutDiv === false ? "1px solid gray" : ""
                    }}>
                        <div className="bottom-btn-and-icon-div"
                            onClick={() =>
                                this.setState({
                                    showHiddenAboutDiv: !showHiddenAboutDiv
                                })} >
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faInfoCircle} />
                            <label className="bottom-btn-lbl2">
                                About
                            </label>
                        </div>
                        <FontAwesomeIcon className="bottom-btn-arrow-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                this.setState({
                                    showHiddenAboutDiv: !showHiddenAboutDiv
                                })} />
                    </div>
                    :
                    null
                }

                {showHiddenAboutDiv === true ?
                    this.getHiddenAboutDiv()
                    :
                    null
                }

                {person.galleryImageUrls !== undefined ?
                    <div className="hidden-info-btn" style={{
                        borderBottom: showHiddenPhotosGalleryDiv === false ? "1px solid gray" : ""
                    }}>
                        <div className="bottom-btn-and-icon-div"
                            onClick={() =>
                                this.setState({
                                    showHiddenPhotosGalleryDiv: !showHiddenPhotosGalleryDiv
                                })} >
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faImage} />
                            <label className="bottom-btn-lbl2">
                                Photos Gallery
                            </label>
                        </div>
                        <FontAwesomeIcon className="bottom-btn-arrow-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                this.setState({
                                    showHiddenPhotosGalleryDiv: !showHiddenPhotosGalleryDiv
                                })} />
                    </div>
                    :
                    null
                }

                {person.galleryImageUrls !== "" && showHiddenPhotosGalleryDiv === true ?
                    this.getHiddenPhotosGalleryDiv()
                    :
                    null
                }


                {person.videoObjects !== undefined ?
                    <div className="hidden-info-btn" style={{
                        borderBottom: showHiddenVideossGalleryDiv === false ? "1px solid gray" : ""
                    }}>
                        <div className="bottom-btn-and-icon-div"
                            onClick={() =>
                                this.setState({
                                    showHiddenVideossGalleryDiv: !showHiddenVideossGalleryDiv
                                })} >
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faVideo} />
                            <label className="bottom-btn-lbl2">
                                Videos Gallery
                            </label>
                        </div>
                        <FontAwesomeIcon className="bottom-btn-arrow-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                this.setState({
                                    showHiddenVideossGalleryDiv: !showHiddenVideossGalleryDiv
                                })} />
                    </div>
                    :
                    null
                }

                {person.videoObjects !== "" && showHiddenVideossGalleryDiv === true ?
                    this.getHiddenVideosGalleryDiv()
                    :
                    null
                }




                {person.linkUrl !== undefined && person.linkUrl !== "" ?
                    <div className="hidden-info-btn" style={{
                        borderBottom: showHiddenLinkDiv === false ? "1px solid gray" : ""
                    }}>
                        <div className="bottom-btn-and-icon-div"
                            onClick={() =>
                                this.setState({
                                    showHiddenLinkDiv: !showHiddenLinkDiv
                                })} >
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faLink} />
                            <label className="bottom-btn-lbl2">
                                {person.linkTitle !== undefined && person.linkTitle !== "" ?
                                    person.linkTitle
                                    :
                                    "Check Out This Link"
                                }
                            </label>
                        </div>
                        <FontAwesomeIcon className="bottom-btn-arrow-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                this.setState({
                                    showHiddenLinkDiv: !showHiddenLinkDiv
                                })} />
                    </div>
                    :
                    null
                }

                {showHiddenLinkDiv === true ?
                    this.getHiddenLinkDiv()
                    : null
                }



                <div className="hidden-info-btn" style={{
                    borderBottom: showHiddenContactDiv === false ? "1px solid gray" : ""
                }}>

                    <div className="bottom-btn-and-icon-div"
                        onClick={() =>
                            this.setState({
                                showHiddenContactDiv: !showHiddenContactDiv
                            })} >
                        <FontAwesomeIcon className="bottom-btn-icon"
                            icon={faUserCircle} />
                        <label className="bottom-btn-lbl2">
                            Full Contact Info
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className="bottom-btn-arrow-icon" icon={faChevronDown}
                        onClick={() =>
                            this.setState({
                                showHiddenContactDiv: !showHiddenContactDiv
                            })} />
                </div>

                {showHiddenContactDiv === true ?
                    this.getHiddenContactInformationDiv()
                    :
                    null
                }


                {person.email !== "" ?
                    <div className="hidden-info-btn" style={{
                        borderBottom: showHiddenDirectMsgDiv === false ? "1px solid gray" : ""
                    }} onClick={() =>
                        this.setState({
                            showHiddenDirectMsgDiv: !showHiddenDirectMsgDiv
                        })} >
                        <div className="bottom-btn-and-icon-div">
                            <FontAwesomeIcon className="bottom-btn-icon"
                                icon={faPaperPlane} />
                            <label className="bottom-btn-lbl2">
                                Send Direct Message
                            </label>
                        </div>
                        <FontAwesomeIcon className="bottom-btn-arrow-icon"
                            icon={faChevronDown}
                            onClick={() =>
                                this.setState({
                                    showHiddenDirectMsgDiv: !showHiddenDirectMsgDiv
                                })} />
                    </div>
                    :
                    null
                }

                {person.email !== "" && showHiddenDirectMsgDiv === true ?
                    this.getHiddenDirectMsgDiv()
                    : null
                }

                <div className="hidden-info-btn-share" style={{
                    borderBottom: showHiddenContactDiv === false ? "1px solid gray" : ""
                }}>

                    <div className="bottom-btn-and-icon-div"
                        onClick={() =>
                            this.setState({
                                showHiddenShareDiv: !showHiddenShareDiv
                            })} >
                        <FontAwesomeIcon className="bottom-btn-icon"
                            icon={faShareAlt} />
                        <label className="bottom-btn-lbl2">
                            Share this card
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className="bottom-btn-arrow-icon" icon={faChevronDown}
                        onClick={() =>
                            this.setState({
                                showHiddenShareDiv: !showHiddenShareDiv
                            })} />
                </div>

                {showHiddenShareDiv === true ?
                    this.getHiddenShareDiv()
                    :
                    null
                }

            </div>
        )
    }
    saveToNgCardBook = async () => {
        const { person, userId } = this.state
        let cardId = person.id
        if (userId === null) {
            window.location.href = "/login"
        }
        let error = await this.props.firebase.saveCardToUserFavorites(userId, cardId)
        if (error === null) {
            alert("card saved successfully!")
        } else {
            alert("error saving card - " + error)
        }

    }
    saveVcard = () => {
        const { person } = this.state
        const vCard = new vCardsJS();

        vCard.firstName = person.businessCardTitle;

        vCard.photo.attachFromUrl(person.faceImageUrl, 'JPEG');
        vCard.workPhone = person.phone;
        vCard.title = person.businessJobTitle;
        vCard.cellPhone = person.landline;
        vCard.email = person.email;
        vCard.url = window.location.href;

        let fileName = person.businessCardTitle + '.vcf'
        this.writeFile(fileName, vCard.getFormattedString())
    }

    chooseAndroid = () => {
        this.setState({
            imgForFullScreen: saveToHomeScreenAndroidImg,
            videoForFullScreen: null,
            fullSizeOpen: true
        })
    }
    chooseApple = () => {
        this.setState({
            imgForFullScreen: saveToHomeScreenAppleImg,
            videoForFullScreen: null,
            fullSizeOpen: true
        })
    }
    showAppleOrAndroidPopup = async () => {
        this.setState({ showAppleOrAndroidPopup: true })
    }
    hideAppleOrAndroidPopup = () => {
        this.setState({ showAppleOrAndroidPopup: false })
    }
    getAppleOrAndroidPopup = () => {
        return (
            <div className="apple-or-android-popup">
                <button className="apple-or-android-btn"
                    onClick={this.chooseAndroid}
                >Android</button>
                <button className="apple-or-android-btn"
                    onClick={this.chooseApple}
                >iPhone</button>
            </div>
        )

    }
    writeFile = (fileName, vCard) => {
        const url = window.URL.createObjectURL(new Blob([vCard]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    getBackGroundStyle = () => {
        const { person } = this.state
        let style = getBackGroundStyle(person, person.selectedCardOption, person.selectedBGColor)
        return style
    }

    getCardEditBtn = () => {
        const { isInPersonCardIds, urlName, cardId, userRole } = this.state

        if (isInPersonCardIds === true || userRole === "admin") {
            return (
                <button className="edit-main-btn"
                    onClick={() => {
                        window.location.href = "/editcard/" + urlName + "/" + cardId
                    }} >
                    <FontAwesomeIcon icon={faEdit} size="2x"
                        onClick={() => {
                            window.location.href = "/editcard/" + urlName + "/" + cardId
                        }} />
                </button>
            )
        } else {
            return null
        }
    }

    getUrlForQR = () => {
        let url = window.location.href
        return url
    }
    onChangeSmsNumber = event => {
        this.setState({ smsNumber: event.target.value });
    }
    sendSmsToNumber = () => {
        const { person, smsNumber } = this.state
        let url = window.location.href
        let msg = "Click on the link and enter the card of " +
            person.businessCardTitle + ": " + url

        this.setState({
            showSmsKeyboard: false
        }, () => {
            window.open('sms:' + smsNumber + "?body=" + msg)
            //for ios:
            // window.open('sms:' + smsNumber + ";body=" + msg)
            //for ios8:
            // window.open('sms:' + smsNumber + "&body=" + msg)
        })
    }

    getAnnoyingChangePsw = () => {
        if (this.state.needToChangePassword === true) {
            return (
                <div className="annoying-change-psw2">
                    <label className="annoying-change-psw-lbl2">
                        please update your password
                    </label>
                    <button className="annoying-change-psw-btn2"
                        onClick={() => {
                            window.location.href = "/listing-author?dashboard=update-profile"
                        }}>OK</button>
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const {
            person, loading, errorGettingCard,
            faceImgLoaded, coverImgLoaded,
            fullSizeOpen, imgForFullScreen, videoForFullScreen,
            showAppleOrAndroidPopup,
            showGhoust,
            showSmsKeyboard, smsNumber,
        } = this.state

        if (loading === true) {
            return (
                <div className="card-page-main-container">
                    <div className="phone-bg-div">
                        <div className="card-page-inside-container">
                            <div className="card-page-inside-loading">
                                <label className="main-loading-lbl"> Loading ...</label>
                                <ReactLoading type={"spinningBubbles"} color="crimson" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (showGhoust === true) {
            return (
                <div className="card-page-main-container">
                    <div className="phone-bg-div">
                        <div className="card-page-inside-container">
                            <div className="card-page-inside-loading">
                                <img className="ghoust-img" src={ghoustImg} alt="" />
                                <label className="main-ghoust-lbl1"> Ooops, Ghoust Here</label>
                                <label className="main-ghoust-lbl2">The page you are looking for might have been</label>
                                <label className="main-ghoust-lbl2">removed or is temporarily unavailable....</label>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (errorGettingCard === true) {
            return (
                <div className="card-page-main-container">
                    <div className="phone-bg-div">
                        <div className="card-page-inside-container">
                            <div className="card-page-inside-loading">
                                <label className="main-loading-lbl"> An Error Occured</label>
                                <label className="main-loading-lbl2"> please try again later</label>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (person === undefined || person === null) {
            return <h1>Error</h1>
        }
        let op = 1
        let ans1 = false
        let ans2 = false

        if (person.faceImageUrl !== undefined && person.faceImageUrl !== "") {
            if (faceImgLoaded) {
                ans1 = true
            }
        } else {
            ans1 = true
        }

        if (person.coverImageUrl !== undefined && person.coverImageUrl !== "") {
            if (coverImgLoaded) {
                ans2 = true
            }
        } else {
            ans2 = true
        }

        if (ans1 && ans2) {
            op = 1
        } else {
            op = 0
        }
        return (
            <div className="card-page-main-container">
                <div className="phone-bg-div">

                    {showAppleOrAndroidPopup === true ?
                        this.getAppleOrAndroidPopup() : null}

                    {fullSizeOpen === true ?
                        <div className="fullscreen-div">
                            <button className="full-size-x-btn"
                                onClick={() => {
                                    this.setState({
                                        imgForFullScreen: null,
                                        fullSizeOpen: false,
                                        showAppleOrAndroidPopup: false,
                                    })
                                }}
                            >X</button>

                            {videoForFullScreen !== null ?
                                <ReactPlayer
                                    className="full-size-img"
                                    url={videoForFullScreen.videoUrl}
                                    playing={true}
                                    controls={false}
                                    width={"100%"}
                                />
                                :
                                imgForFullScreen !== null ?
                                    imgForFullScreen === saveToHomeScreenAndroidImg || imgForFullScreen === saveToHomeScreenAppleImg ?
                                        <img src={imgForFullScreen} className="full-size-img2" alt="" />
                                        :
                                        <img src={imgForFullScreen} className="full-size-img" alt="" />
                                    :
                                    null
                            }
                        </div>
                        :
                        null
                    }

                    {showSmsKeyboard === true ?
                        <div className="sms-keyboard-div">
                            <input
                                ref={(ip) => this.myInp = ip}
                                className="sms-keyboard-input"
                                name="smsNumber"
                                value={smsNumber}
                                onChange={this.onChangeSmsNumber}
                                type="tel"
                                placeholder="phone number"
                            />

                            <button className="sms-keyboard-btn"
                                onClick={this.sendSmsToNumber}>
                                Send SMS
                            </button>

                            <button
                                className="send-sms-x-btn"
                                onClick={() => { this.setState({ showSmsKeyboard: false }) }}
                            >Cancel</button>
                        </div>
                        :
                        null
                    }


                    <div className="card-page-inside-container"
                        id="card-page-inside-container"
                    >
                        <div className="top-stuff-div"
                            style={this.getBackGroundStyle()}
                        >
                            {this.getFaceImages()}

                            {this.getPersonTitles()}

                            {this.getCardBtns()}
                        </div>

                        {person.groupName !== undefined ?
                            <img className="certified-img" src={certifiedImg} alt=""
                                onContextMenu={(e) => {
                                    e.preventDefault()
                                    alert("sorry, no right click here")
                                }}
                            />
                            :
                            null
                        }

                        <div className="inside-except-images"
                            style={{
                                opacity: op,
                                transition: "opacity 1s"
                            }}
                        >
                            {this.getHiddenInfo()}

                            <div className="my-qr">
                                <QRCode
                                    value={this.getUrlForQR()}
                                />
                            </div>

                            {this.getBottomCreateYourOwnDiv()}

                        </div>

                        {this.getSaveAsBottomBtns()}
                    </div>


                    {this.getCardEditBtn()}

                </div>
            </div >

        )
    }
}

export default compose(
    withRouter,
    withFirebase,
)(CardPage);