
import React, { Component } from 'react';
import { HashLink } from "react-router-hash-link";
import './BottomBar.scss';

import logo1 from '../images/logo-min.png'

class BottomBar extends Component {
    constructor() {
        super();
        this.state = {}
    }
    
    componentDidMount() { }

    render() {
        return (
            <div className="bottom-bar-main-container">

                {window.innerWidth > 1100 ?
                    <div id="div5" className="div5-BrowserView">

                        <div id="div5" className="bottom-bar-inside-container">

                            <HashLink
                                className="hashlink-bottom"
                                to="/#home">
                                <img className="logo-link-bottom-img" src={logo1} alt=""></img>

                                <label className="rights-2021-lbl">
                                    © 2021 All rights Reserved. NetGo Card / NG Card
                                </label>
                            </HashLink>

                            <div className="links-bottom">
                                <HashLink
                                    className="hashlink-bottom"
                                    to="/privacy-policy#top">
                                    Privacy Policy
                                </HashLink>
                            </div>
                        </div>
                    </div>
                    :
                    <div id="div5" className="div5-MobileView">

                        <div id="div5" className="bottom-bar-inside-container-mobile">

                            <HashLink
                                className="hashlink-bottom-mobile-img"
                                to="/#home">
                                <img className="logo-link-bottom-img-mobile" src={logo1} alt=""></img>

                                <label className="rights-2021-lbl-mobile">
                                    © 2021 All rights Reserved. NetGo Card / NG Card
                                </label>
                            </HashLink>

                            <div className="links-bottom-mobile">
                                <HashLink
                                    className="hashlink-bottom-mobile"
                                    to="/privacy-policy#top">
                                    Privacy Policy
                                </HashLink>
                            </div>

                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default BottomBar;