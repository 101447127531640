import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import readXlsxFile from 'read-excel-file'
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop';
import ReactLoading from "react-loading";
import CreateCardDesign from '../createCard/CreateCardDesign';
import './ExcelToMultipeCardsPage.scss';

import { WhatsappIcon } from "react-share";

import './EditCardPage.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faComments } from '@fortawesome/free-solid-svg-icons'

import {
    getTextColor,
    getLblStyle,
    getWazeBtnStyle,
    getBtnStyle,
    getProfileOption,
    getIconBorderShape,
    getIconBorderColor,
    getCardOption,
    getBackGroundStyle
} from "../utils/utils";

import phoneOfficeIcon from '../images/phone-office.png'
import wazeOnlyIcon from '../images/waze-only-icon.PNG'
import emptyUser from '../images/empty_user2.jpg'

class ExcelToMultipeCardsPage extends Component {
    constructor() {
        super();
        this.state = {
            errors: [],
            fileOk: false,
            people: [],
            selectedTextColor: { value: 'Black', label: 'Black' },
            selectedProfileImgOption: { value: 'Rectangle', label: 'Rectangle' },
            selectedIconBorderShape: { value: 'Rounded Rectangle Icons', label: 'Rounded Rectangle Icons' },
            selectedCardOption: { value: 'Classic', label: 'Classic' },
            selectedBGColor: '#fff',
            selectedIconBorderColor: { value: 'Black', label: 'Black' },
            srcCover: null,
            cropCover: {
                unit: '%',
                width: 200,
                aspect: 16 / 9,
            },
            showAreYouSure: false,
            loading: false,
            adminEmail: "",
            adminEmailOk: false,
            typingTimeout: 0,
            checkAdminEmail: false,
            redLabelTxt: "Enter Group Admin Email first",
            peopleWithIds: [],
            bgNames:[]
        }
        this.myRef = React.createRef()
        this.setAdminEmail = this.setAdminEmail.bind(this);
    }
    componentDidMount = async ()=> {        
        this.bgNames = await this.props.firebase.getAllBackgroundNames()
        this.nameInput.focus();
        this.setExelReder()
    }
    setExelReder = () => {

        const input = document.getElementById('input')

        input.addEventListener('change', (e) => {
            let fileName = e.target.files[0].name
            let extentions = fileName.split('.')
            let fileType = extentions[1]

            if (fileType === "xlsx") {
                readXlsxFile(input.files[0]).then((rows) => {
                    let errors = []
                    let people = []

                    let companyName = rows[2][4]
                    if (companyName === null) {
                        errors.push("please enter customer Name")
                    }

                    let line = 6

                    for (let index = line; index < rows.length; index++) {
                        let personNum = rows[index][1]
                        let personName = rows[index][2]
                        let personJob = rows[index][3]
                        let personEmail = rows[index][4]
                        let personPhone = rows[index][5]
                        let personLandline = rows[index][6]
                        let personWhatsapp = rows[index][7]

                        people.push({
                            personNum,
                            personName,
                            personJob,
                            personEmail,
                            personPhone,
                            personLandline,
                            personWhatsapp,
                            companyName
                        })

                        if (personName === null) {
                            errors.push("please enter a card title at column: C, line:" + (line + 1))
                        }
                        if (personEmail === null) {
                            errors.push("please enter an email at column: E, line:" + (line + 1))
                        }
                        if (personPhone === null) {
                            errors.push("please enter a phone number at column: F, line:" + (line + 1))
                        }

                    }
                    this.setState({ errors, fileOk: true, people, companyName })
                })
            } else {
                alert("please enter only xlsx files")
            }

        })
    }

    setTextColor = (color) => {
        this.setState({ selectedTextColor: color })
    }

    setCardBGOption = (color) => {
        this.setState({ selectedCardOption: color })
    }

    setCardBGColor = (color) => {
        this.setState({ selectedBGColor: color })
    }

    setProfileImgDesign = (option) => {
        this.setState({ selectedProfileImgOption: option })
    }

    setIconBorderShape = (color) => {
        this.setState({ selectedIconBorderShape: color })
    }

    setIconBorderColor = (color) => {
        this.setState({ selectedIconBorderColor: color })
    }

    getPhoneMainImages = (device) => {

        const { coverImageUrl, selectedProfileImgOption } = this.state

        let profileOption = getProfileOption(null, selectedProfileImgOption)

        return (
            <div className="top-person-images">

                {coverImageUrl !== undefined ?

                    <img alt=""
                        src={coverImageUrl !== undefined ? URL.createObjectURL(coverImageUrl) : null}
                        className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"} />
                    :
                    <div className={device === "mobile" ? "preview-tiny-cover-img" : "preview-cover-img"}></div>
                }

                <img
                    src={emptyUser} alt=""
                    className={device === "mobile" ? "preview-tiny-face-img" : "preview-face-img"}
                    style={{
                        borderRadius: profileOption === "Circle" ? "50%" :
                            profileOption === "Rounded Rectangle" ? "10%" : "0%",
                    }}
                />
            </div>
        )
    }

    getPhoneTitles = (device) => {
        const { selectedTextColor } = this.state

        let textColor = getTextColor(null, selectedTextColor)

        let lblStyle = { color: textColor }

        let title = "The Person Name"

        let job = "The Person Job Description"

        return (
            <div className={device === "mobile" ? "preview-tiny-titles" : "preview-titles"}>

                <label className={device === "mobile" ? "preview-tiny-title1" : "preview-title1"}
                    style={lblStyle}>
                    {title}
                </label>

                <label className={device === "mobile" ? "preview-tiny-title2" : "preview-title2"}
                    style={lblStyle}>
                    {job}
                </label>
            </div>
        )
    }

    getPhoneBtns = (device) => {
        const {
            selectedTextColor,
            selectedIconBorderColor,
            selectedIconBorderShape,
        } = this.state

        let btnStyle = getBtnStyle(null, selectedIconBorderShape, selectedIconBorderColor)

        let lblStyle = getLblStyle(null, selectedTextColor)

        let wazeBtnStyle = getWazeBtnStyle(null, selectedIconBorderShape, selectedIconBorderColor)

        return (
            <div>
                <div className={device === "mobile" ? "card-btns-tiny" : "card-btns"}>

                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <FontAwesomeIcon icon={faPhoneAlt} />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Phone</label>
                    </div>

                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <FontAwesomeIcon icon={faComments} />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>SMS</label>
                    </div>


                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <WhatsappIcon
                                size={35}
                                iconFillColor="black"
                                bgStyle={{ fill: "transparent" }}
                            />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Whatsapp</label>
                    </div>


                    <div className={"card-btn-div"}>
                        <div className="card-btn-circle-div"
                            style={btnStyle}
                        >
                            <img src={phoneOfficeIcon} className="btn-icon" alt="" />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Landline</label>
                    </div>

                    <div className={"card-btn-div"}>

                        <div className="card-btn-circle-div"
                            style={wazeBtnStyle}
                        >
                            <img className="btn-icon" alt=""
                                src={wazeOnlyIcon}
                            />
                        </div>
                        <label className="card-btn-lbl" style={lblStyle}>Navigate</label>

                    </div>

                </div>
            </div>
        )
    }

    getDropzoneDiv = () => {
        let text1 = window.innerWidth < 1100 ? "click to add Image" : "Drag and Drop a file or click to add Image"
        let text2 = "accepted Image Sizes: 1MB or less"

        return (
            <div className="the-dropzone">
                <label className="the-dropzone-lbl1">
                    {text1}
                </label>
                <label className="the-dropzone-lbl1">
                    {text2}
                </label>
            </div>
        )
    }

    onCropCoverComplete = cropCover => {
        this.makeClientCropCover(cropCover);
    }

    onCoverImageLoaded = image => {
        this.imageRef2 = image;
    }

    async makeClientCropCover(cropCover) {
        if (this.imageRef2 && cropCover.width && cropCover.height) {
            const croppedCoverImageUrl = await this.getCroppedCoverImg(
                this.imageRef2,
                cropCover,
                'newFile2.jpeg'
            );
            this.setState({ coverImageUrl: croppedCoverImageUrl, croppedCoverImageUrl })
        }
    }

    onCropCoverChange = cropCover => {
        this.setState({ cropCover });
    }


    getCroppedCoverImg(image, cropCover, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = cropCover.width;
        canvas.height = cropCover.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            cropCover.x * scaleX,
            cropCover.y * scaleY,
            cropCover.width * scaleX,
            cropCover.height * scaleY,
            0,
            0,
            cropCover.width,
            cropCover.height
        );
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }

    getAcceptedFilesCover = async (acceptedFiles) => {
        let img = acceptedFiles[0]
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ srcCover: reader.result })
        );
        reader.readAsDataURL(img);

    }
    saveCoverImg = () => {
        this.setState({
            coverImgSelected: true,
            srcCover: null
        })
    }
    cancelCoverImg = () => {
        this.setState({
            coverImgSelected: true,
            srcCover: null,
            croppedCoverImageUrl: undefined
        })
    }

    checkIfSafeToSave = () => {
        const { croppedCoverImageUrl } = this.state

        if (croppedCoverImageUrl === undefined) {
            alert("please chose a cover image first")
            this.setState({
                showAreYouSure: false,
            }, () => {
                this.myRef.current.scrollTo(0, 0)
            })
        } else {
            this.showAreYouSure()
        }
    }

    createAll = async () => {

        this.setState({
            showAreYouSure: false,
            loading: true
        }, async () => {
            const {
                people, selectedBGColor, companyName, coverImageUrl,
                userId, selectedTextColor, selectedIconBorderColor,
                selectedIconBorderShape, selectedCardOption, selectedProfileImgOption
            } = this.state

            let textColor = getTextColor(null, selectedTextColor)
            let iconOption = getIconBorderShape(null, selectedIconBorderShape)
            let iconBorderOption = getIconBorderColor(null, selectedIconBorderColor)
            let cardOption = getCardOption(null, selectedCardOption, selectedBGColor)
            let profileOption = getProfileOption(null, selectedProfileImgOption)

            let companyName1 = companyName.split('.').join('')
            companyName1 = companyName1.replaceAll(/\s/g, '_')
            let coverUrl = await this.props.firebase.uploadBlobCover(coverImageUrl, companyName1)

            let cardsData = {
                cardBGName: cardOption,
                selectedBGColor: selectedBGColor,
                iconBorder: iconBorderOption,
                iconOptions: iconOption,
                profileImgOptions: profileOption,
                textColor: textColor,
                coverImageUrl: coverUrl,
                status: "pending",
                userId,
                createdAt: Date.now(),
            }

            await this.props.firebase.setAdminTrueForUser(userId)

            let ans = await this.props.firebase.createMultipleUsersWithFirebaseFunctions(
                people,
                companyName,
                cardsData,
                userId
            )
            if (ans !== undefined && ans.includes("error") === false) {
                this.setState({
                    loading: false,
                }, () => {
                    alert("cards and users created successfully!")
                    window.location.href = "/"
                })
            } else {
                this.setState({
                    loading: false,
                })
                alert("cards and users created not successfully... please try again later")
            }

        })
    }

    setAllUsersGroupName = async () => {
        const { peopleWithIds, companyName, userId } = this.state
        this.props.firebase.setUserGroupName(companyName, userId)
        peopleWithIds.map(async (person, i) => {
            this.props.firebase.setUserGroupName(companyName, person.id)
        })
    }

    showAreYouSure = () => {
        this.setState({
            showAreYouSure: true,
        })
    }

    hideAreYouSure = () => {
        this.setState({
            showAreYouSure: false,
        })
    }
    getLoadingPopup = () => {
        return (
            <div className="are-you-sure-popup">
                <label className="are-you-sure-title-lbl"> Loading ...</label>
                <ReactLoading type={"spinningBubbles"} color="crimson" />
            </div>
        )
    }
    getAreYouSurePopup = () => {
        return (
            <div className="are-you-sure-popup">
                <label className="are-you-sure-title-lbl">
                    Are you sure you want to add all these cards?
                </label>

                <div className="are-you-sure-btns-div">
                    <button className="are-you-sure-btn"
                        onClick={this.createAll}
                    >Yes</button>
                    <button className="are-you-sure-btn"
                        onClick={this.hideAreYouSure}
                    >No</button>
                </div>
            </div>
        )
    }
    getEditDesignThings = (device) => {
        const { bgNames, srcCover, cropCover,
            selectedCardOption, selectedBGColor
        } = this.state
        
        return (

            <div className="all-divs">

                <div className="card-preview-div1">

                    <div className={device === "mobile" ? "preview-tiny-phone-div-container" : "main-preview-div"}>


                        <div className={device === "mobile" ?
                            "preview-tiny-phone-div" : "preview-phone-div2"}>
                            <div className={device === "mobile" ?
                                "preview-tiny-phone-inside-div" : "preview-phone-inside-div"}
                                style={getBackGroundStyle(null, selectedCardOption, selectedBGColor)}//,bgNames)}
                            >
                                <div className={"preview-phone-inside-div-scrollable"}
                                    id={"preview-phone-inside-div-scrollable"}
                                >
                                    {this.getPhoneMainImages(device)}

                                    {this.getPhoneTitles(device)}

                                    {this.getPhoneBtns(device)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="all-left-divs">

                    <div className="upload-cover-img-div">
                        <div className="upload-image-inside-div">
                            <label className="add-img-lbl">
                                Upload Cover Logo/Image
                            </label>

                            <div className="add-img-container">

                                {srcCover === null ?
                                    <Dropzone
                                        onDrop={acceptedFiles => this.getAcceptedFilesCover(acceptedFiles)}>

                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {this.getDropzoneDiv()}

                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    :
                                    <ReactCrop
                                        src={srcCover}
                                        crop={cropCover}
                                        ruleOfThirds
                                        onImageLoaded={this.onCoverImageLoaded}
                                        onComplete={this.onCropCoverComplete}
                                        onChange={this.onCropCoverChange}
                                    />
                                }

                            </div>
                            <div className="add-img-btns-container">
                                <button className="add-img-btn" onClick={this.cancelCoverImg}>Cancel</button>
                                <button className="add-img-btn" onClick={this.saveCoverImg}>OK</button>
                            </div>

                        </div>
                    </div>
                    <div className="card-design-div1">
                        <CreateCardDesign
                            setTextColor={this.setTextColor}
                            setCardBGColor={this.setCardBGColor}
                            setCardBGOption={this.setCardBGOption}
                            setProfileImgDesign={this.setProfileImgDesign}
                            setIconBorderShape={this.setIconBorderShape}
                            setIconBorderColor={this.setIconBorderColor}
                        />
                    </div>

                </div>
            </div>
        )
    }
    setAdminEmail = event => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            checkAdminEmail: true,
            adminEmail: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.checkAdminEmail(self.state.adminEmail);
            }, 1000)
        });
    }
    checkAdminEmail = (adminEmail) => {
        this.setState({
            checkingEmail: true,
        }, async () => {
            let userId = await this.props.firebase.doesEmailUserExist(adminEmail)
            if (userId !== null) {
                this.setState({
                    redLabelTxt: "email exists",
                    checkAdminEmail: false,
                    adminEmailOk: true,
                    userId,
                })
            } else {
                this.setState({
                    redLabelTxt: "email does not exists!",
                    checkAdminEmail: false,
                    adminEmailOk: false,
                })
            }
        })
    }

    render() {
        const {
            errors, fileOk, people,
            adminEmail, adminEmailOk, checkAdminEmail, redLabelTxt,
            showAreYouSure, loading
        } = this.state
        let device = window.innerWidth > 1100 ? "desktop" : "mobile"
        return (
            <div className="read-excel-page-main-container" ref={this.myRef}>

                {showAreYouSure === true ? this.getAreYouSurePopup()
                    :
                    null
                }

                {loading === true ? this.getLoadingPopup()
                    :
                    null
                }

                {errors.length > 0 ?
                    <div className="all-errors-div">
                        <label className="all-errors-lbl">Errors in File:</label>
                        {
                            errors.map((e, i) => {
                                return <h1 key={i}>{i + 1}) {e}</h1>
                            })
                        }
                    </div>
                    :
                    fileOk === false ?
                        <div className="enter-file-and-admin-div">
                            <div className="admin-input-and-loading-div">
                                <input
                                    className="add-admin-input"
                                    name="adminEmail"
                                    value={adminEmail}
                                    onChange={this.setAdminEmail}
                                    type="email"
                                    placeholder="Enter Group Admin Email"
                                    ref={(input) => { this.nameInput = input; }}
                                />
                                {checkAdminEmail === true ?
                                    <ReactLoading type={"spinningBubbles"} color="crimson"
                                        className="loading-admin" />
                                    :
                                    null
                                }

                            </div>
                            <input
                                type="file" id="input"
                                accept=".xlsx, .xls, .csv"
                                className="read-excel-input"
                                disabled={adminEmailOk === false ? "disabled" : ""}
                            />
                            <label className="admin-error-label"
                                style={{ color: redLabelTxt === "email exists" ? "green" : "red" }}
                            >{redLabelTxt}</label>
                        </div>
                        :
                        <div className="all2">
                            {this.getEditDesignThings(device)}
                            <div className="all-people-div" >
                                <label className="people-title">People: </label>
                                {
                                    people.map((p, i) => {
                                        return <div className="a-person-div" key={i}>
                                            <label>num: {p.personNum}</label>
                                            <label>name: {p.personName}</label>
                                            <label>job: {p.personJob}</label>
                                            <label>email: {p.personEmail}</label>
                                            <label>phone: {p.personPhone}</label>
                                            <label>landline: {p.personLandline}</label>
                                            <label>whatsapp: {p.personWhatsapp}</label>
                                            <label>companyName: {p.companyName}</label>
                                        </div>
                                    })
                                }
                            </div>

                            <button
                                onClick={this.checkIfSafeToSave}
                                className="save-all-btn">Save</button>
                        </div>
                }
            </div>
        )
    }
}

export default compose(withFirebase)(ExcelToMultipeCardsPage);
