import React, { Component } from 'react';
import Select from 'react-select';
import './CreateSocialMedia.scss';

class CreateSocialMedia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            socialMediaOptions: [
                { value: 'Instagram', label: 'Instagram' },
                { value: 'YouTube', label: 'YouTube' },
                { value: 'LinkedIn', label: 'LinkedIn' },
                { value: 'Facebook', label: 'Facebook' },
                { value: 'Twitter', label: 'Twitter' },
            ],
            selectedSocialMediaOption: null,
            socialMediaUrl: '',
            allSocialMedias: this.props.allSocialMedia,
            showErrorSocialMedia1: false,
            showErrorSocialMedia2: false,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.allSocialMedias !== this.props.allSocialMedia) {
            this.setState({
                allSocialMedias: this.props.allSocialMedia
            })
        }
    }

    handleSocialMediaOptionsChange = selectedOption => {
        this.setState({
            selectedSocialMediaOption: selectedOption,
            showErrorSocialMedia1: false,
            showErrorSocialMedia2: false,
        });
    }

    addSocialMedia = () => {
        const { selectedSocialMediaOption, socialMediaUrl } = this.state

        if (socialMediaUrl === "" && selectedSocialMediaOption === null) {
            this.setState({
                showErrorSocialMedia1: true,
                showErrorSocialMedia2: true,
            })
            return
        } else if (socialMediaUrl === "" && selectedSocialMediaOption !== null) {
            this.setState({
                showErrorSocialMedia1: false,
                showErrorSocialMedia2: true,
            })
            return
        } else if (socialMediaUrl !== "" && selectedSocialMediaOption === null) {
            this.setState({
                showErrorSocialMedia1: true,
                showErrorSocialMedia2: false,
            })
            return
        }
        let newSocialMediaUrl = socialMediaUrl
        newSocialMediaUrl = socialMediaUrl.replaceAll("https://", "")

        this.setState({
            selectedSocialMediaOption: null,
            socialMediaUrl: "",
            allSocialMedias: this.state.allSocialMedias.concat(
                {
                    type: selectedSocialMediaOption.value,
                    value: newSocialMediaUrl
                }
            )
        }, () => {
            this.props.setSocialMediaOptions(this.state.allSocialMedias)
        })
    }
    getAllSocialMedias = () => {
        let all = []
        if (this.state.allSocialMedias !== undefined && this.state.allSocialMedias.length > 0) {
            let arr = Object.values(this.state.allSocialMedias)
            arr.forEach((med, i) => {
                all.push(med)
            })
        }
        return all
    }


    getSocialMediaList = () => {
        let all = this.getAllSocialMedias()
        let list = []

        if (window.innerWidth > 1100) {
            all.map((m, i) => {
                list.push(<div className="a-social-media-row" key={i}>
                    <label className="a-social-media-lbl1">{m.type}</label>
                    <label className="a-social-media-lbl2">{m.value}</label>
                    <button className="social-media-remove-btn"
                        onClick={() => { this.removeSocialMedia(m) }}
                    >X</button>
                </div>
                )
            })
        } else {
            all.map((m, i) => {
                list.push(<div className="a-social-media-row" key={i}>
                    <div className="social-name-and-remove-btn">
                        <label className="a-social-media-lbl1">{m.type}
                        </label>
                        <button className="social-media-remove-btn"
                            onClick={() => { this.removeSocialMedia(m) }}
                        >X</button>
                    </div>
                    <label className="a-social-media-lbl2">{m.value}</label>
                </div>
                )
            })
        }
        return list
    }
    removeSocialMedia = (m) => {
        let all = this.getAllSocialMedias()
        let newList = []

        all.map((m1, i) => {
            if (m1 === m) {
            } else {
                newList.push(m1)
            }
        })
        this.props.setSocialMediaOptions(newList)
    }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { selectedSocialMediaOption, socialMediaOptions,
            socialMediaUrl, showErrorSocialMedia1, showErrorSocialMedia2,
        } = this.state

        let colourStyles = null
        if (showErrorSocialMedia1 === true) {
            colourStyles = {
                control: () => ({
                    border: "1px solid red",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "5px"
                }),
            }
        } else {
            colourStyles = {
                control: () => ({
                    border: "1px solid gainsboro",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "5px"
                }),
            }
        }

        return (

            <div className="add-social-media-container">

                <label className="add-top-lbl">
                    Social Media
                </label>

                <div className="select-media-div">

                    {window.innerWidth < 1100 ?
                        null
                        :
                        <label className="media-select-lbl">
                            Select
                        </label>
                    }

                    <Select
                        styles={colourStyles}
                        className="my-select"
                        value={selectedSocialMediaOption}
                        onChange={this.handleSocialMediaOptionsChange}
                        options={socialMediaOptions}
                        isSearchable={false}
                    />

                    <input
                        style={{ border: showErrorSocialMedia2 === true ? "1px solid red" : null }}
                        className="add-social-media-input"
                        name="socialMediaUrl"
                        value={socialMediaUrl}
                        onChange={this.onChange}
                        type="url"
                        placeholder="Enter Url"
                    />
                    <button className="add-social-media-btn"
                        onClick={this.addSocialMedia}>
                        Add
                    </button>
                </div>

                {this.getSocialMediaList()}
            </div>
        )
    }
}

export default CreateSocialMedia;