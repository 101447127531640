import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import sessionstorage from 'sessionstorage'
import ReactLoading from "react-loading";
import AlertPopup from '../app/AlertPopup'
import './SignInPage.scss'


class SignInPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: null,
            showAlert: false,
            alertMsg: '',
            loading: false,
        };
    }

    componentDidMount = () => {

    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    onSubmit = async () => {
        const { email, password } = this.state;

        const isInvalid = password === '' || email === '';
        if (isInvalid === false) {
            this.setState({ loading: true }, async () => {

                let ans = await this.props.firebase
                    .doSignInWithEmailAndPassword(email, password)
                if (ans.user !== undefined) {
                    if (ans.user.emailVerified === true) {
                        sessionstorage.setItem("userEmail", email)
                        sessionstorage.setItem("userId", ans.user.uid)
                        sessionstorage.setItem("userName", ans.user.displayName)
                        window.location.href = "/"
                    } else {
                        this.setState({
                            loading: false,
                            showAlert: true,
                            alertMsg: "please verify your email before logging in",
                        })
                    }
                } else if (ans.message !== undefined) {
                    this.setState({
                        loading: false,
                        showAlert: true,
                        alertMsg: ans.message,
                    })
                }
            })
        }
    }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
    goToRegister() {
        window.location.href = "/register"
    }
    goToForgotPassword() {
        window.location.href = "/forgotpassword"
    }
    removeAlertPopUp = () => {
        this.setState({
            showAlert: false
        })
    }
    showAlertPopUp = () => {
        return (
            <AlertPopup
                stopPopup={this.removeAlertPopUp}
                msg={this.state.alertMsg}></AlertPopup>
        )
    }
    render() {
        const { email, password, showAlert, loading } = this.state;
        const isInvalid = password === '' || email === '';

        if (loading === true) {
            return (
                <div className="loading-signin-container">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }
        return (
            <div className="login-container">
                <div className="login-bg-overly">

                    <div
                        className="login-form-container">
                        {showAlert ?
                            this.showAlertPopUp()
                            :
                            null
                        }
                        <h1 className="login-title">Sign In</h1>
                        <input
                            className="login-input"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDown}
                            type="text"
                            placeholder="email"
                        />

                        <input
                            className="login-input"
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDown}
                            type="password"
                            placeholder="password"
                        />
                        <div
                            className="goToForgot-container"
                            onClick={this.goToForgotPassword}
                        >
                            <span className="goToRegister-link">Forgot Password *</span>
                        </div >

                        <button
                            className="login-ok-btn"
                            disabled={isInvalid}
                            onClick={this.onSubmit}>
                            OK
                        </button>

                        <div
                            className="goToRegister-container"
                            onClick={this.goToRegister}
                        >
                            <span className="goToRegister-link">Sign up</span>
                            <span className="goToRegister">?Don't have a user</span>
                        </div >

                    </div>
                </div>
            </div>
        )
    }
}


export default compose(withFirebase)(SignInPage)