import { SET_USER_NAME } from "../constants/action-types";


const initialState = {
    userName: "",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_NAME:
            return {
                userName: action.payload
            }
        default:
            return state
    }
}

export default reducer