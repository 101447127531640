
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import { connect } from "react-redux";
import { setUserName } from "../store/actions/index";
import './MyProfilePage.scss';

class MyProfilePage extends Component {
    constructor() {
        super();
        this.state = {
            userName: "",
            userId: "",
            email: "",
            oldPsw: "",
            psw1: "",
            psw2: "",
            invalid1: false,
            invalid2: false,
            invalid3: false,
            invalid4: false,
            errorMsg: "",
            showError: false,
            type1: "text",
            type2: "text",
            type3: "text",
        }
    }
    componentDidMount = async () => {
        this.getCurrentUser()
    }

    getCurrentUser = () => {
        this.props.firebase.getCurrentUser(
            (user) => {
                this.setState({
                    userName: user.displayName,
                    userId: user.uid
                })
            },
            () => {
            })
    }

    updateUserName = async () => {
        const { userName, userId } = this.state;
        if (userId !== "" && userName !== "") {
            this.props.setUserName(userName)
            let ans = await this.props.firebase.setUserFromName(userId, userName)
            sessionStorage.setItem("userName", "taaal")
            if (ans === true) {
                alert("user name changed successfully!")
            } else {
                alert("error updating user name. please try again later")
            }
        }
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    updatePassword = async () => {
        const { oldPsw, email, psw1, psw2 } = this.state;

        const isInvalid = psw1 !== psw2 || psw1 === '' || email === ''

        if (isInvalid === true) {
            let msg = ''
            let invalid1 = false
            let invalid2 = false
            let invalid3 = false
            let invalid4 = false
            if (email === '') {
                msg = 'enter a valid email'
                invalid1 = true
            } else if (oldPsw === '') {
                msg = 'enter a old password'
                invalid2 = true
            } else if (psw1 === '') {
                msg = 'enter password'
                invalid3 = true
            } else if (psw1 !== psw2) {
                msg = 'confiremed password does not match the first pasword'
                invalid3 = true
                invalid4 = true
            }
            this.setState({
                errorMsg: msg,
                invalid1,
                invalid2,
                invalid3,
                invalid4,
                showError: invalid1 || invalid2 || invalid3 || invalid4
            })
        } else {
            let error = await this.props.firebase.doPasswordUpdate(oldPsw, email, psw1)
            if (error === null) {
                alert("password changed successfully!")
                this.setState({
                    email: "",
                    oldPsw: "",
                    psw1: "",
                    psw2: "",
                    invalid1: false,
                    invalid2: false,
                    invalid3: false,
                    invalid4: false,
                    errorMsg: "",
                    showError: false,
                    type1: "text",
                    type2: "text",
                    type3: "text",
                })
            } else {
                alert(error)
            }
        }
    }

    render() {
        const { userName, errorMsg, oldPsw, email, psw1, psw2,
            invalid1, invalid2, invalid3, invalid4, showError,
            type1, type2, type3,
        } = this.state

        let msg = "Enter same password in both fields. Use an uppercase letter and a number for stronger password."
        let lblStyle = { color: "black" }

        if (showError === true) {
            msg = errorMsg
            lblStyle = { color: "red" }
        }

        return (
            <div className="my-profile-main-container">
                <label className="my-profile-title">
                    Update Profile Details
                </label>

                <label className="profile-title-psw">
                    Update User Name
                </label>

                <div className="profile-psw-div">
                    <input
                        className="profile-usermane-input"
                        name="userName"
                        value={userName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Your User Name"
                    />

                    <button className="update-profile-btn"
                        onClick={this.updateUserName}
                    >Update User Name</button>

                </div>



                <label className="profile-title-psw">
                    Update Password
                </label>

                <div className="profile-psw-div">

                    <div className="inputs-2-row-div">

                        <div className="inputs-1-row-div">

                            <label className="detail-title2">User Email</label>

                            <input
                                className="profile-psw-input"
                                name="email"
                                value={email}
                                onChange={this.onChange}
                                type="text"
                                placeholder="your user email"
                                style={{ borderColor: invalid1 === true ? "red" : "gainsboro" }}
                            />
                        </div>
                        <div className="inputs-1-row-div">

                            <label className="detail-title2">User Password</label>

                            <input
                                className="profile-psw-input"
                                name="oldPsw"
                                value={oldPsw}
                                onChange={this.onChange}
                                type={type1}
                                onFocus={() => { this.setState({ type1: "password" }) }}
                                placeholder="Your Old Password"
                                style={{ borderColor: invalid2 === true ? "red" : "gainsboro" }}
                            />
                        </div>
                    </div>

                    <div className="inputs-2-row-div">

                        <div className="inputs-1-row-div">

                            <label className="detail-title2">New Password</label>

                            <input
                                className="profile-psw-input"
                                name="psw1"
                                value={psw1}
                                onChange={this.onChange}
                                type={type2}
                                onFocus={() => { this.setState({ type2: "password" }) }}
                                placeholder="New Password"
                                style={{ borderColor: invalid3 === true ? "red" : "gainsboro" }}
                            />
                        </div>

                        <div className="inputs-1-row-div">

                            <label className="detail-title2">Repeat Password</label>

                            <input
                                className="profile-psw-input"
                                name="psw2"
                                value={psw2}
                                onChange={this.onChange}
                                type={type3}
                                onFocus={() => { this.setState({ type3: "password" }) }}
                                placeholder="Repeat New Password"
                                style={{ borderColor: invalid4 === true ? "red" : "gainsboro" }}
                            />
                        </div>
                    </div>
                    <label className="psw-bottom-lbl" style={lblStyle}>{msg}</label>

                    <button className="update-profile-btn"
                        onClick={this.updatePassword}>
                        Update Password
                    </button>

                </div>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        userName1: state.userName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserName: name => dispatch(setUserName(name))
    };
}

export default compose(
    withFirebase,
    connect(mapStateToProps,mapDispatchToProps)
)(MyProfilePage);