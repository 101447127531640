
import React, { Component } from 'react';
import ReactLoading from "react-loading";
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Resizer from "react-image-file-resizer";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';

import MainInfo from '../createCard/MainInfo';
import CreateCardImages from '../createCard/CreateCardImages';
import CreateGallery from '../createCard/CreateGallery';
import CreateContactInfo from '../createCard/CreateContactInfo';
import CreateCardDesign from '../createCard/CreateCardDesign';
import CreateSocialMedia from '../createCard/CreateSocialMedia';
import CreateVideos from '../createCard/CreateVideos';
import PreviewPhone from '../app/PreviewPhone'

import {
    getTextColor,
    getIconBorderShape,
    getIconBorderColor,
    getCardOptionForEdit,
    cardNameForUrl,
} from "../utils/utils";

import './EditCardPage.scss';

class EditCardPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            userName: "",
            userId: "",
            galleryImageUrls: [],
            aGalleryImageUrl: "",

            selectedTextColor: null,
            selectedProfileImgOption: null,
            selectedIconBorderShape: null,
            selectedCardOption: null,
            selectedBGColor: '',
            selectedIconBorderColor: null,

            businessCardTitle: '',
            businessJobTitle: '',
            industry: "",

            fullAddress: '',
            additionalDirections: '',
            phone: '',
            email: '',
            landline: '',
            whatsapp: '',
            website: '',
            videoUrl: "",

            linkTitle: '',
            linkDescription: '',
            linkUrl: '',

            allSocialMedia: [],
            videoObjects: [],

            editorState: EditorState.createEmpty(),

            convertedContent: "",

            theCard: null,

            canEditStyle: true,
        }
    }

    componentDidMount() {
        this.gettheCard()
    }

    gettheCard = async () => {
        this.setState({
            loading: true,
        }, async () => {
            let url = window.location.pathname

            let strings = url.split("/")

            let cardId = strings[strings.length - 1]
            let name = strings[strings.length - 2]

            let urlName = name
            name = name.replaceAll("-", " ")
            let theCard = await this.props.firebase.getCardById(cardId)
            if (theCard !== null) {
                this.props.firebase.getCurrentUser(
                    async user => {
                        let userId = user.uid
                        let userName = user.displayName
                        let role = await this.props.firebase.getUserRoleFromUserId(user.uid)
                        let canEditStyle = userId === theCard.userId || role === "admin"
                        let editorDataState = EditorState.createEmpty()
                        if (theCard.currentContentAsHTML !== undefined &&
                            theCard.currentContentAsHTML !== "") {

                            const contentDataState =
                                ContentState.createFromBlockArray(
                                    convertFromHTML(`${theCard.currentContentAsHTML}`)
                                )

                            editorDataState = EditorState.createWithContent(contentDataState)
                        }
                        let allSocialMedia1 = []
                        if (theCard.allSocialMedia !== undefined) {
                            allSocialMedia1 = theCard.allSocialMedia
                        }
                        let videoObjects1 = []
                        if (theCard.videoObjects !== undefined) {
                            videoObjects1 = theCard.videoObjects
                        }

                        let iconBorder = theCard.iconBorder
                        if (iconBorder !== "") {
                            iconBorder = iconBorder.value
                        }

                        this.setState({
                            theCard, loading: false, urlName,
                            userName, userId,
                            editorState: editorDataState,
                            allSocialMedia: allSocialMedia1,
                            videoObjects: videoObjects1,
                            selectedIconBorderShape: iconBorder,
                            canEditStyle
                        })
                    },
                    () => {
                    })
            } else {
                this.setState({ loading: false, errorGettingCard: true })
            }
        })
    }

    saveEditedCard = async () => {
        const {
            selectedProfileImgOption,
            selectedIconBorderShape, selectedIconBorderColor,
            selectedCardOption, selectedBGColor,
            faceImageUrl, coverImageUrl,
            businessCardTitle, businessJobTitle,
            industry,
            fullAddress,
            additionalDirections,
            phone,
            email,
            landline,
            whatsapp,
            website,
            linkTitle,
            linkDescription,
            linkUrl,
            currentContentAsHTML,
            allSocialMedia,
            videoObjects,
            galleryImageUrls,
            theCard, selectedTextColor
        } = this.state


        let hasAllMust = (businessCardTitle !== '' || theCard.businessCardTitle !== "")

        if (hasAllMust === true) {

            this.showLoading()
            let cardName = theCard.businessCardTitle
            let cardId = await this.props.firebase.getCardIdFromCardName(cardName)
            let name1 = cardName.replaceAll(/\s/g, '_')

            let faceUrl = null
            let coverUrl = null
            //////////////////////Main Contact Information//////////////////////
            if (businessCardTitle !== "" && businessCardTitle !== theCard.businessCardTitle) {
                cardName = businessCardTitle
                await this.props.firebase.setBusinessCardTitle(cardId, businessCardTitle)
            }
            if (businessJobTitle !== "" && businessJobTitle !== theCard.businessJobTitle) {
                await this.props.firebase.setBusinessJobTitle(cardId, businessJobTitle)
            }

            if (industry !== "" && industry !== theCard.industry) {
                await this.props.firebase.setIndustry(cardId, industry)
            }

            //////////////////////Images//////////////////////
            if (coverImageUrl !== undefined) {
                await this.props.firebase
                    .removeFirebaseCardImg(cardName, name1 + "_cover.png")
            }

            if (coverImageUrl !== undefined) {
                coverUrl = await this.props.firebase.uploadBlobCover(coverImageUrl, name1)
                await this.props.firebase.setCoverImgUrl(cardId, coverUrl)
            }

            if (faceImageUrl !== undefined) {
                await this.props.firebase
                    .removeFirebaseCardImg(cardName, name1 + "_face.png")
            }

            if (faceImageUrl !== undefined) {
                faceUrl = await this.props.firebase.uploadBlobFace(faceImageUrl, name1)
                await this.props.firebase.setFaceImgUrl(cardId, faceUrl)
            }


            //////////////////////Design//////////////////////
            let profileImgOptions = selectedProfileImgOption !== null ? selectedProfileImgOption.value : null

            if (profileImgOptions !== null &&
                profileImgOptions !== theCard.profileImgOptions) {
                await this.props.firebase.setProfileImgOptions(cardId, profileImgOptions)
            }

            let iconBorderShape = getIconBorderShape(theCard, selectedIconBorderShape)

            if (iconBorderShape !== undefined && iconBorderShape !== null &&
                iconBorderShape !== theCard.iconOptions) {
                await this.props.firebase.setIconOptions(cardId, iconBorderShape)
            }

            let textColor = getTextColor(theCard, selectedTextColor)
            if (textColor !== null && textColor !== theCard.textColor) {
                await this.props.firebase.setTextColorOptions(cardId, textColor)
            }

            let bgOption = getCardOptionForEdit(theCard, selectedCardOption, selectedBGColor)
            let cardBGType = bgOption[1]
            if (cardBGType === "selectedBGColor") {
                let bgColor = bgOption[0]
                if (bgColor !== theCard.cardBGName) {
                    await this.props.firebase.setBgColor(cardId, bgColor)
                }
            } else {
                let bgName = bgOption[0]
                if (bgName !== theCard.cardBGName) {
                    await this.props.firebase.setBgNameOptions(cardId, bgName)
                }
            }

            let iconBorderColor = getIconBorderColor(theCard, selectedIconBorderColor)
            if (iconBorderColor !== null && iconBorderColor !== theCard.iconBorder) {
                await this.props.firebase.setIconBorder(cardId, iconBorderColor)
            }


            //////////////////////Contact Information//////////////////////

            if (email !== "" && email !== theCard.email) {
                await this.props.firebase.setEmail(cardId, email)
            }
            if (fullAddress !== "" && fullAddress !== theCard.fullAddress) {
                await this.props.firebase.setFullAddress(cardId, fullAddress)
            }
            if (additionalDirections !== "" && additionalDirections !== theCard.additionalDirections) {
                await this.props.firebase.setAdditionalDirections(cardId, additionalDirections)
            }
            if (phone !== "" && phone !== theCard.phone) {
                await this.props.firebase.setPhone(cardId, phone)
            }
            if (landline !== "" && landline !== theCard.landline) {
                await this.props.firebase.setLandline(cardId, landline)
            }
            if (whatsapp !== "" && whatsapp !== theCard.whatsapp) {
                await this.props.firebase.setWhatsapp(cardId, whatsapp)
            }
            if (website !== "" && website !== theCard.website) {
                await this.props.firebase.setWebsite(cardId, website)
            }
            if (linkTitle !== "" && linkTitle !== theCard.linkTitle) {
                await this.props.firebase.setFreeLinkTitle(cardId, linkTitle)
            }
            if (linkDescription !== "" && linkDescription !== theCard.linkDescription) {
                await this.props.firebase.setFreeLinkDescription(cardId, linkDescription)
            }
            if (linkUrl !== "" && linkUrl !== theCard.linkUrl) {
                await this.props.firebase.setFreeLinkUrl(cardId, linkUrl)
            }

            //////////////////////Write About Yourself//////////////////////

            if (currentContentAsHTML !== undefined && currentContentAsHTML !== "" && currentContentAsHTML !== theCard.currentContentAsHTML) {
                await this.props.firebase.setCurrentContentAsHTML(cardId, currentContentAsHTML)
            }
            if (allSocialMedia !== theCard.allSocialMedia) {
                if (allSocialMedia.length === 0 && theCard.allSocialMedia === undefined) {

                } else {
                    await this.props.firebase.setAllSocialMedia(cardId, allSocialMedia)
                }
            }

            if (videoObjects !== theCard.videoObjects) {
                if (videoObjects.length === 0 && theCard.videoObjects === undefined) {

                } else {
                    await this.props.firebase.setVideos(cardId, videoObjects)
                }
            }

            await this.props.firebase.setLastEditedAt(cardId)

            let promises = []
            //////////////////////Gallery//////////////////////

            let theCardGalleryImageUrlsArr = []
            if (theCard.galleryImageUrls !== null && theCard.galleryImageUrls !== undefined) {
                theCardGalleryImageUrlsArr = Object.values(theCard.galleryImageUrls)
            }

            if (JSON.stringify(galleryImageUrls) != JSON.stringify(theCardGalleryImageUrlsArr)) {
                promises = galleryImageUrls.map(async (img, i) => {
                    if (img.blob != undefined) {
                        let url3 = await this.props.firebase.doUploadToUserGallery(img.blob, name1, i)
                        await this.props.firebase.addImgUrlToGallery(cardId, url3)
                    }
                })
            }
            Promise.all(promises).then(data2 => {
                setTimeout(function () { //Start the timer
                    //After 0.5 second
                    let cardName1 = cardNameForUrl(cardName)
                    window.location.href = "/card/" + cardName1 + "/" + cardId
                }.bind(this), 500)

            })
        }
    }

    signout = () => { }

    onChangeP = event => {
        let value = event.target.value
        if (value === "") {
            value = null
        }
        this.setState({ [event.target.name]: value })
    }

    gettheCardSocialValue = (socialType) => {
        const { allSocialMedia } = this.state
        var result = allSocialMedia.filter(obj => {
            return obj.type === socialType
        })
        let ans = ""
        if (result.length > 0) {
            if (result[0] !== undefined) {
                ans = result[0].value
            }
        }
        return ans
    }

    setSocialMediaOptions = (options) => {
        this.setState({ allSocialMedia: options })
    }

    setVideos = (arr) => {
        this.setState({ videoObjects: arr })
    }

    setFaceImg = (imgBlob) => {
        var file = new File([imgBlob], "name11");
        this.reduceImgSizeTo200x200(file)
    }

    setCoverImg = (imgBlob) => {
        this.setState({ coverImageUrl: imgBlob })
    }

    reduceImgSizeTo200x200 = async (file) => {
        try {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                "JPEG",
                100,
                0,
                async uri => {
                    const response = await fetch(uri);
                    const blob = await response.blob();
                    blob.name = 'newFile1.jpeg'
                    this.setState({ faceImageUrl: blob })
                },
                "base64",
                200,
                200
            );

        } catch (err) {
            console.error(err);
        }
    }

    cancelCroppedFaceImg = () => {
        this.setState({ faceImageUrl: undefined })
    }
    cancelCroppedCoverImg = () => {
        this.setState({ coverImageUrl: undefined })
    }

    addImg = (imgUrl) => {
        this.setState({ galleryImageUrls: this.state.galleryImageUrls.concat(imgUrl) })
    }

    setImg = (imgUrl) => {
        this.setState({ aGalleryImageUrl: imgUrl })
    }
    setAllGalleryImgUrls = (imgsUrls) => {
        this.setState({ galleryImageUrls: imgsUrls })
    }
    cancelImg = (imgUrl) => {
        this.setState({ aGalleryImageUrl: "" })
    }
    cancelThisImg = (index) => {

        const { galleryImageUrls, theCard } = this.state

        let theCardGalleryImageUrls = Object.values(theCard.galleryImageUrls)

        let realIndex = index - theCardGalleryImageUrls.length
        let arr = [...galleryImageUrls]

        arr.splice(realIndex, 1)

        this.setState({
            galleryImageUrls: arr
        }, () => {
        })
    }
    cancelAllImgs = () => {
        this.setState({ galleryImageUrls: [] })
    }
    removeImg = (imgUrl) => {
        let array = [...this.state.galleryImageUrls]
        let index = array.indexOf(imgUrl)
        if (index !== -1) {
            array.splice(index, 1)
            this.setState({ galleryImageUrls: array })
        }
    }
    setIndustry = (option) => {
        this.setState({ industry: option.value })
    }
    setTextColor = (color) => {
        this.setState({ selectedTextColor: color })
    }

    setCardBGOption = (option) => {
        this.setState({ selectedCardOption: option })
    }

    setCardBGColor = (color) => {
        this.setState({ selectedBGColor: color })
    }

    setProfileImgDesign = (color) => {
        this.setState({ selectedProfileImgOption: color })
    }

    setIconBorderShape = (shape) => {
        this.setState({ selectedIconBorderShape: shape })
    }

    setIconBorderColor = (color) => {
        this.setState({ selectedIconBorderColor: color })
    }

    showError = () => { }

    showLoading = () => {
        this.setState({
            loading: true
        })
    }

    scrollToEndIdInPreview = () => {
    }

    onEditorStateChange = (editorState) => {
        this.scrollToEndIdInPreview()
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())

        this.setState({
            editorState,
            currentContentAsHTML,
        })
    }

    convertContentToHTML = () => {
        const { editorState } = this.state
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
        this.setState({
            currentContentAsHTML
        })
    }

    createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    rerender = () => {
        this.gettheCard()
    }

    getPhonePreview = (device) => {
        return (
            <PreviewPhone
                theState={this.state}
                device={device}
                rerender={this.rerender}
                saveEditedCard={this.saveEditedCard}
            />
        )
    }


    goToGalleryInPreview = () => {
    }


    getPage = (boolMobile) => {
        const { theCard, editorState, canEditStyle } = this.state
        return (

            <div className={boolMobile === true ?
                "create-card-page-inside-main-container-mobile" :
                "create-card-page-inside-main-container"}
            >
                <label className="add-listing-title1">
                    LETS GO!
                </label>
                <label className="add-listing-sub-title">
                    Start creating your digital card
                </label>

                <MainInfo
                    theCard={theCard}
                    onChangeP={this.onChangeP}
                    setIndustry={this.setIndustry}
                />

                <CreateCardImages
                    theCard={theCard}
                    setFaceImg={this.setFaceImg}
                    setCoverImg={this.setCoverImg}
                    cancelCroppedFaceImg={this.cancelCroppedFaceImg}
                    cancelCroppedCoverImg={this.cancelCroppedCoverImg}
                    canEditStyle={canEditStyle}
                />
                {canEditStyle === true ?

                    <CreateCardDesign
                        theCard={theCard}
                        setTextColor={this.setTextColor}
                        setCardBGColor={this.setCardBGColor}
                        setCardBGOption={this.setCardBGOption}
                        setProfileImgDesign={this.setProfileImgDesign}
                        setIconBorderShape={this.setIconBorderShape}
                        setIconBorderColor={this.setIconBorderColor}
                    />
                    :
                    null
                }


                <CreateContactInfo
                    onChangeP={this.onChangeP}
                    theCard={theCard}
                />

                <div className="editor-div">

                    <label className="add-top-lbl">
                        Write About Yourself
                    </label>

                    <Editor
                        className="my-text-editor"
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: [
                                'inline',
                                'fontSize',
                                'list',
                                'textAlign',
                                "link", 
                            ],
                            list: {
                                options: ["ordered", "unordered"],
                            },
                            inline: {
                                options: ["bold", "italic", "underline"],
                            },
                        }}

                    />
                </div>

                <CreateSocialMedia
                    setSocialMediaOptions={this.setSocialMediaOptions}
                    allSocialMedia={this.state.allSocialMedia}
                />
                <CreateVideos
                    theCard={theCard}
                    videoObjects={this.state.videoObjects}
                    setVideos={this.setVideos}
                />

                <CreateGallery
                    theCard={theCard}
                    addImg={this.addImg}
                    setImg={this.setImg}
                    cancelImg={this.cancelImg}
                    cancelThisImg={this.cancelThisImg}
                    cancelAllImgs={this.cancelAllImgs}
                    removeImg={this.removeImg}
                    setAllGalleryImgUrls={this.setAllGalleryImgUrls}
                    goToGalleryInPreview={this.goToGalleryInPreview}
                />
                <button className="save-details-btn"
                    onClick={this.saveEditedCard}>
                    {"Save Edited Card"}
                </button>


            </div>
        )
    }

    render() {
        const { loading, theCard } = this.state
        if (theCard === null) {
            return (
                <div className="loading-main-div">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }

        return (
            <div className="create-card-page-main-container">

                {window.innerWidth < 1250 ?

                    <div className="edit-card-render-div">
                        {this.getPhonePreview("mobile")}
                        {this.getPage(true)}
                    </div>
                    :

                    <div className="edit-card-render-div">
                        {this.getPhonePreview("desktop")}
                        {this.getPage(false)}
                    </div>
                }


                {loading === true ?
                    <div
                        className={window.innerWidth < 1250 ?
                            "loading-div-mobile" : "loading-div"}
                    >
                        <label className="loading-lbl"> Saving Card</label>
                        <label className="loading-lbl2"> this might take a few seconds...</label>
                        <ReactLoading type={"spinningBubbles"} color="crimson" />
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}



export default compose(withFirebase)(EditCardPage)

