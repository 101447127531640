import app from "firebase/app";
import "firebase/storage";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

import { cardImgNameForUrl } from "../utils/utils";

const env = process.env;

const firebaseConfig = {
    apiKey: env["REACT_APP_FIREBASE_API_KEY"],
    authDomain: env["REACT_APP_FIREBASE_AUTH_DOMAIN"],
    databaseURL: env["REACT_APP_FIREBASE_DATABASE_URL"],
    projectId: env["REACT_APP_FIREBASE_PROJECT_ID"],
    storageBucket: env["REACT_APP_FIREBASE_STORAGE_BUCKET"],
    appId: env["REACT_APP_FIREBASE_APP_ID"],
};



class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.storage = app.storage();
        this.db = app.database();
        this.functions = app.functions();
    }

    getAllBackgroundNames = async () => {
        let items = [];
        let snapshot = await this.db.ref('/background-names').once('value')
        snapshot.forEach(data => {
            const dataKey = data.key
            const dataVal = data.val()
            items.push({key:dataKey, value:dataVal})
        })
        return items
    }

    createMultipleUsersWithFirebaseFunctions = async (people, groupName, cardsData, toUserId) => {
        let data = { people: people, cardsData: cardsData, groupName: groupName, toUserId: toUserId }
        const callableReturnMessage = this.functions.httpsCallable('createrUsersFromExel');
        let ans = await callableReturnMessage(data).then(async (result) => {
            if (result !== null && result !== undefined) {
                return "cards and users created successfully!"
            } else {
                return "cards and users created not successfully... please try again later"
            }
        }).catch((error) => {
            return "cards and users created not successfully... error: " + error
        })
        return ans;
    }

    sendNewCardEmail = async (userEmail, cardTitle) => {
        let data = { userEmail, cardTitle }
        const callableReturnMessage = this.functions.httpsCallable('newCardEmail');
        let ans = await callableReturnMessage(data).then(async (result) => {
            if (result !== null && result !== undefined) {
                return "new card email sent successfully!"
            } else {
                return "new card email not sent successfully... please try again later"
            }
        }).catch((error) => {
            return "new card email not sent successfully... error: " + error
        })
        return ans;
    }


    sendSendgridEmail = async (name, fromEmail, toEmail, phone, msg1,
        cardId, senderId, reciverId) => {

        const callableReturnMessage = this.functions.httpsCallable('emailMessage');

        let data = {
            name: name,
            fromEmail: fromEmail,
            toEmail: toEmail,
            phone: phone,
            msg1: msg1,

        }

        await callableReturnMessage(data).then(async (result) => {

            if (result.data.response.includes("err:Error") === false) {
                await this.saveDirectMessageToDatabase(name, fromEmail, toEmail, phone, msg1
                    , cardId, senderId, reciverId)
                alert("email sent successfully!")
                return true
            } else {
                alert("error sending email... please try again later")
                return false
            }
        }).catch((error) => {
            alert("error sending email... please try again later")
            return false
        });

    }
    saveDirectMessageToDatabase = async (name, fromEmail, toEmail, phone, msg1
        , cardId, senderId, reciverId) => {

        let ans = false
        let msgData = {
            toEmail,
            reciverId,
            senderId,
            cardId,
            fromName: name,
            fromEmail,
            fromPhone: phone,
            msg: msg1,
        }
        if (senderId !== "") {
            await this.db.ref('users/').child(senderId).child("sentMsgs").push(msgData)
                .then(
                    ans = true
                ).catch(err => {
                    ans = false
                })
        }


        if (reciverId !== "") {
            await this.db.ref('users/').child(reciverId).child("recivedMsgs").push(msgData)
                .then(
                    ans = true
                ).catch(err => {
                    ans = false
                })
        }

        return ans
    }
    doesEmailUserExist = async (email) => {
        let ans = await this.db.ref('/users').orderByChild("email").equalTo(email)
            .once('value')
            .then(snapshot => {
                const users = snapshot.val();
                if (users === null) {
                    return null
                } else {

                    let user = Object.values(users);

                    let userId = user[0].id
                    return userId
                }
            })
            .catch(error => {
                console.error("snapshot error:", error)
                return null
            })
        return ans
    }
    getAllCards = async () => {

        let items = [];
        let snapshot = await this.db.ref('/cards').once('value')
        snapshot.forEach(data => {
            const dataVal = data.val()
            items.push(dataVal)
        })

        return items
    }
    getAllUsers = async () => {

        let items = [];
        let snapshot = await this.db.ref('/users').once('value')
        snapshot.forEach(data => {
            const dataVal = data.val()
            items.push(dataVal)
        })

        return items
    }
    setCoverImgForEntireGroup = (coverUrl, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            coverImageUrl: coverUrl
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                console.error("setCoverImgForEntireGroup - error - e:", e)
                ans = false
            })
        return ans
    }

    setProfileImgOptionsForEntireGroup = (selectedProfileImgOption, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            profileImgOptions: selectedProfileImgOption
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }

    setIconOptionsForEntireGroup = (selectedIconImgOption, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            iconOptions: selectedIconImgOption
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }
    setIconBorderOptionForEntireGroup = (iconBorderOption, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            iconBorder: iconBorderOption
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }

    setCardBGNameForEntireGroup = (selectedCardOption, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            cardBGName: selectedCardOption,
                            selectedBGColor: ""
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }
    setCardBGColorForEntireGroup = (selectedCardOption, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            cardBGName: "",
                            selectedBGColor: selectedCardOption
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }


    setTextColorForEntireGroup = (textColor, groupName) => {
        let ans = false
        let DB = this.db
        DB.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        var cardRef = DB.ref("cards/" + value.key);
                        cardRef.update({
                            textColor
                        })
                        ans = true
                    })
                }
            }).catch(e => {
                ans = false
            })
        return ans
    }

    setUserPlan = (plan, id) => {
        let ans = false
        this.db.ref('users/').child(id).update({ plan }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    //////////////////////Images//////////////////////
    setCoverImgUrl = (cardId, coverImageUrl) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ coverImageUrl }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setFaceImgUrl = (cardId, faceImageUrl) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ faceImageUrl }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    //////////////////////Design//////////////////////
    setProfileImgOptions = (cardId, profileImgOptions) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ profileImgOptions }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setIconOptions = (cardId, iconOptions) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ iconOptions }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setTextColorOptions = (cardId, textColor) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ textColor }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setIconBorder = (cardId, iconBorder) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ iconBorder }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setBgNameOptions = (cardId, cardBGName) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ cardBGName, selectedBGColor: "" }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setBgColor = (cardId, selectedBGColor) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ selectedBGColor, cardBGName: "" }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    //////////////////////Main Contact Information//////////////////////
    setBusinessCardTitle = (cardId, businessCardTitle) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ businessCardTitle }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setBusinessJobTitle = (cardId, businessJobTitle) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ businessJobTitle }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setIndustry = (cardId, industry) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ industry }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    //////////////////////Contact Information//////////////////////
    setEmail = (cardId, email) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ email }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setFullAddress = (cardId, fullAddress) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ fullAddress }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setAdditionalDirections = (cardId, additionalDirections) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ additionalDirections }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setPhone = (cardId, phone) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ phone }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setLandline = (cardId, landline) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ landline }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setWhatsapp = (cardId, whatsapp) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ whatsapp }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setWebsite = (cardId, website) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ website }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setFreeLinkTitle = (cardId, linkTitle) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ linkTitle }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setFreeLinkDescription = (cardId, linkDescription) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ linkDescription }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setFreeLinkUrl = (cardId, linkUrl) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ linkUrl }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    //////////////////////Write About Yourself//////////////////////

    setCurrentContentAsHTML = (cardId, currentContentAsHTML) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ currentContentAsHTML }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setLastEditedAt = (cardId) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ lastEditAt: Date.now() }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }


    setAllSocialMedia = (cardId, allSocialMedia) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ allSocialMedia }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    setVideos = (cardId, videoObjects) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ videoObjects }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setCardPlan = (cardId, associatedPlan) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ associatedPlan }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    changeCardStatus = (cardId, status) => {
        let ans = false
        this.db.ref('cards/').child(cardId).update({ status }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    changeAllCardsInGroupStatus = async (groupName, status) => {
        let ans = false

        let DB = this.db
        await DB.ref('cards')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        const childObject = value.val();
                        let card = childObject
                        DB.ref('cards/' + card.id).update({ status })
                    });
                }
            });
        return ans
    }

    setAdminTrueForUser = (userId) => {
        let ans = false
        this.db.ref('users/').child(userId).update({ groupAdmin: true }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    setUserGroupName = (groupName, userId) => {
        let ans = false
        this.db.ref('users/').child(userId).update({ groupName }).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    getAllUserCards = () => {
    }


    addOneToCardViews = async (cardId) => {

        let ans = false
        let date = Date.now()
        this.db.ref('cards/').child(cardId).child("views").push(date).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }






    getCardByName = async (name) => {
        let card = null
        await this.db.ref('cards')
            .orderByChild("name_uppercase")
            .equalTo(name.toUpperCase())
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        const childObject = value.val();
                        card = childObject
                    });
                }
            });
        return card
    }

    saveCardToUserFavorites = async (userId, cardId) => {
        let error = null

        await this.db.ref('users/' + userId + "/favoritesCardIds")
            .once('value')
            .then(snapshot => {
                const cards = snapshot.val()
                if (cards !== null) {
                    let arr = Object.values(cards)
                    let matched = false
                    arr.forEach(id1 => {
                        if (id1 === cardId) {
                            matched = true
                            error = "this card is already in your ng card book"
                        }
                    })
                    if (matched === false) {
                        this.db.ref('users/' + userId + "/favoritesCardIds").push(cardId)
                            .then(
                                error = null
                            ).catch(err => {
                                error = "something went wrong/ please try again later..."
                            })
                        return error
                    }
                    return error

                } else {
                    this.db.ref('users/' + userId + "/favoritesCardIds").push(cardId)
                        .then(
                            error = null
                        ).catch(err => {
                            error = "something went wrong/ please try again later..."
                        })
                }
            })
            .catch(error => {
                error = "something went wrong/ please try again later..."
            })
        return error
    }

    addCardToDbAndUserCards = async (card, userId) => {
        let ans1 = false
        let ans2 = false

        let ref1 = await this.db.ref('cards/').push(card).then(
            ans1 = true
        ).catch(err => {
            ans1 = false
        })

        await this.db.ref('cards/' + ref1.key).child("id").set(ref1.key).then(
            ans1 = true
        ).catch(err => {
            ans1 = false
        })

        await this.db.ref('users/' + userId + "/cardIds").push(ref1.key)
            .then(
                ans2 = true
            ).catch(err => {
                ans2 = false
            })

        return ref1.key
    }


    addCardIdToUserId = async (cardId, userId) => {
        let ans = await this.db.ref('users/' + userId + "/cardIds").push(cardId)
            .then(() => {
                return true
            }).catch(err => {
                return false
            })

        return ans
    }

    addCard = async (card) => {
        let ref1 = await this.db.ref('cards/').push(card)
        await this.db.ref('cards/').child(ref1.key).update({ id: ref1.key })
        return ref1.key
    }
    addCardIdToPersonWithCardEmail = async (card, cardId) => {
        let email = card.email
        let DB = this.db
        let ans1 = await this.db.ref('/users').orderByChild("email").equalTo(email)
            .once('value')
            .then(async snapshot => {
                const users = snapshot.val();
                if (users === null) {
                    return null
                } else {
                    let user = Object.values(users);
                    let userId = user[0].id
                    let ans = await DB.ref('users/' + userId + "/cardIds").push(cardId)
                        .then(() => {
                            return true
                        }).catch(err => {
                            return false
                        })

                    return ans
                }
            })
        return ans1
    }

    getUserFromUserId = async (id) => {
        let ans = ""
        await this.db.ref('users/').child(id)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                ans = user.name
            })
            .catch(error => { })
        return ans
    }


    getUserRoleFromUserId = async (id) => {
        let ans = await this.db.ref('users/').child(id)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let ans1 = user.userRole1
                return ans1
            })
            .catch(error => { })
        return ans
    }
    getUserGroupCards = async (groupName) => {
        let cards = []
        let cardsIds = []
        await this.db.ref('cards/')
            .orderByChild("groupName")
            .equalTo(groupName)
            .once("value", function (snapshot2) {
                if (snapshot2.exists()) {
                    snapshot2.forEach(function (value) {
                        cardsIds.push(value.key)
                    })
                }
            }).catch(e => {
            })

        return cardsIds
    }

    getUserByEmail = async (email) => {
        let ans = await this.db.ref('users/').orderByChild("email").equalTo(email)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let arr = Object.values(user); //Converting an Object into an array
                return arr[0]
            })
            .catch(error => { console.error("firebase - getUserByEmail error:", error) })
        return ans
    }

    getUserById = async (userId) => {
        let ans = await this.db.ref('users/').orderByChild("id").equalTo(userId)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let arr = Object.values(user); //Converting an Object into an array
                return arr[0]
            })
            .catch(error => { console.error("firebase - getUserByEmail error:", error); })
        return ans
    }


    doAddUserToDb = async (userData) => {
        return this.db.ref('users/' + userData.id).set(userData).then(ans => {
            return true
        })
            .catch(err => {
                return false
            })
    }

    removeVideoFromVideoUrls = async (cardId, video) => {
        let db2 = this.db
        let ans = this.db.ref('cards/' + cardId + "/videoUrls")
            .once('value')
            .then(snapshot => {
                const videos = snapshot.val()
                let ans2 = false
                for (const [key, value] of Object.entries(videos)) {
                    if (value === video) {
                        db2.ref('cards/' + cardId + "/videoUrls/" + key).remove().then(ans => {
                            ans2 = true
                        }).catch(function (error) {
                            ans2 = false
                        })
                    }
                }
                return ans2
            })
        return ans
    }

    removeCardFromGalleryStorage = async (cardName, cardId, img) => {
        let name = img.substr(img.indexOf('%2F') + 3,
            (img.indexOf('?')) - (img.indexOf('%2F') + 3));
        name = name.replace('%20', ' ');
        let desertRef = this.storage.ref("card_galleries/").child(name)// + cardName)

        let ans = false
        let ans2 = false

        let db2 = this.db
        // Delete the file
        let ans3 = await desertRef.delete()
            .then(async function () {
                // File deleted successfully
                ans = true
                let ans2 = false
                let ans5 = db2.ref('cards/' + cardId + "/galleryImageUrls")
                    .once('value')
                    .then(snapshot => {
                        const images = snapshot.val()

                        for (const [key, value] of Object.entries(images)) {
                            if (value === img) {
                                let ans4 = db2.ref('cards/' + cardId + "/galleryImageUrls/" + key).remove().then(ans => {
                                    return true
                                }).catch(function (error) {
                                    return false
                                })
                                return ans4
                            }
                        }
                        return ans2
                    })
                return ans5
            }).catch(function (error) {
                ans2 = false
                return ans2
            })

        return ans && ans3
    }

    removeCardFromGalleryDB = async (cardId, img) => {
        let ans = false
        this.db.ref('cards/' + cardId + "/galleryImageUrls")
            .once('value')
            .then(snapshot => {
                const images = snapshot.val()
                for (const [key, value] of Object.entries(images)) {
                    if (value === img) {
                        this.db.ref('cards/' + cardId + "/galleryImageUrls/" + key)
                            .remove().then(ans => {
                                ans = true
                            })

                    } else {
                    }
                }
            })
        return ans
    }
    removeAllCardsInGroupPermanently = async (groupName, allCardIds, userId) => {
        let ans1 = false
        let ans2 = false
        let ans3 = false
        const promises = allCardIds.map(async (id, i) => {
            ans1 = await this.removeCardIdFromAllFavorites(userId, id)
            ans2 = await this.removeCardIdFromUserCardIds(userId, id)
            ans3 = await this.db.ref('cards/' + id).remove()
        })
        Promise.all(promises).then(data => {
            return ans1 && ans2 && ans3
        })
    }
    removeCardPermanently = async (userId, cardId, cardName) => {
        await this.db.ref('users').child(userId)
            .once('value')
            .then(async snapshot => {
                const user = snapshot.val();
                if (user !== null) {
                    let groupName = user.groupName
                    if (groupName !== undefined) {
                        return this.removeCardIdFromUserByCardEmail(cardId)
                    }
                    else {
                        return false
                    }
                } else {
                    return false
                }
            })

        let ans1 = await this.removeCardIdFromAllFavorites(userId, cardId)
        let ans2 = await this.removeCardIdFromUserCardIds(userId, cardId)
        let ans3 = await this.db.ref('cards/' + cardId).remove()

        this.removeAllCardImages(cardName)
        return ans1 && ans2 && ans3
    }

    removeAllCardImages = (cardName) => {
        let name1 = cardImgNameForUrl(cardName) + "face"
        let name2 = cardImgNameForUrl(cardName) + "cover"
        const ST = this.storage
        this.storage.ref("cards/").listAll()
            .then(async result => {
                result.items.forEach(async imageRef => {
                    if (imageRef !== undefined && imageRef !== null) {
                        if (imageRef.location !== undefined && imageRef.location !== null) {
                            if (imageRef.location.path !== undefined && imageRef.location.path !== null) {
                                if (imageRef.location.path.includes(name1)) {
                                    ST.ref(imageRef.location.path).delete()
                                } else if (imageRef.location.path.includes(name2)) {
                                    ST.ref(imageRef.location.path).delete()
                                }
                            }
                        }
                    }

                });
            }).catch(function (error) {
                console.error("removeAllCardImages - error1:", error)
            });

        let name3 = cardImgNameForUrl(cardName) + "img_"
        this.storage.ref("card_galleries/").listAll()
            .then((result) => {
                result.items.forEach(async imageRef => {
                    if (imageRef !== undefined && imageRef !== null) {
                        if (imageRef.location !== undefined && imageRef.location !== null) {
                            if (imageRef.location.path !== undefined && imageRef.location.path !== null) {
                                if (imageRef.location.path.includes(name3)) {
                                    ST.ref(imageRef.location.path).delete()
                                }
                            }
                        }
                    }

                });
            }).catch(function (error) {
                console.error("removeAllCardImages - error2:", error)
            });
    }

    removeCardIdFromUserByCardEmail = async (cardId) => {
        let DB = this.db
        let ans = this.db.ref('cards').child(cardId)
            .once('value')
            .then(async snapshot => {
                const card = snapshot.val();
                if (card !== null) {
                    let cardEmail = card.email
                    this.db.ref('users').orderByChild("email").equalTo(cardEmail)
                        .once('value')
                        .then(async snapshot => {
                            const user = snapshot.val();
                            if (user !== null) {
                                let userId = Object.keys(user)[0];

                                DB.ref("users/" + userId + "/cardIds")
                                    .once('value')
                                    .then(async snapshot => {
                                        const cardIds = snapshot.val();
                                        if (cardIds !== null) {
                                            const cardIds1 = Object.keys(cardIds);
                                            for (let i = 0; i < cardIds1.length; i++) {
                                                const key = Object.keys(cardIds)[i];
                                                const value = Object.values(cardIds)[i];
                                                if (value === cardId) {
                                                    var userRef = DB.ref("users/" + userId + "/cardIds");
                                                    userRef.child(key).remove()
                                                }
                                            }
                                            return true
                                        }
                                        return false
                                    })
                            }
                        })
                }
            })
        return ans
    }
    removeCardIdFromUserCardIds = async (userId, cardId) => {
        let ans1 = false
        let ans = await this.db.ref('users/' + userId + "/cardIds")
            .once('value')
            .then(snapshot => {
                const cardIds = snapshot.val()
                for (const [key, value] of Object.entries(cardIds)) {
                    if (value === cardId) {
                        let ans2 = this.db.ref('users/' + userId + "/cardIds/" + key)
                            .remove().then(ans => {
                                return true
                            })
                    }
                    else {
                        ans1 = false
                    }
                }
            })
        return ans
    }

    removeCardIdFromAllFavorites = async (userId, cardId) => {
        let ans1 = false
        let ans = await this.db.ref('users/')
            .once('value')
            .then(snapshot => {
                const users = snapshot.val()
                if (users !== undefined) {
                    for (const [key, userValue] of Object.entries(users)) {
                        let favs = userValue.favoritesCardIds
                        if (favs !== undefined) {
                            for (const [key, value] of Object.entries(favs)) {
                                if (value === cardId) {
                                    this.db.ref('users/' + userValue.id + "/favoritesCardIds/" + key)
                                        .remove().then(ans => {
                                            return true
                                        })
                                }
                                else {
                                    ans1 = false
                                }
                            }
                        } else {
                            ans1 = false
                        }
                    }
                } else {
                    ans1 = false
                }
            })
        return ans && ans1
    }

    removeFromFavorites = async (userId, cardId) => {
        let ans1 = false
        let ans = await this.db.ref('users/' + userId + "/favoritesCardIds")
            .once('value')
            .then(snapshot => {
                const favoritesCardIds = snapshot.val()
                if (favoritesCardIds !== null && favoritesCardIds !== undefined) {
                    let arr = Object.entries(favoritesCardIds)
                    for (const [key, value] of arr) {
                        if (value === cardId) {
                            let ans2 = this.db.ref('users/' + userId + "/favoritesCardIds/" + key)
                                .remove().then(ans => {
                                    return true
                                })
                        }
                        else {
                            ans1 = false
                        }
                    }
                } else {
                    return null
                }
            })
        return ans

    }
    removeFirebaseCardImg = async (userId, name) => {
        name = name.replaceAll(/\s/g, '_')
        let desertRef = this.storage.ref("cards/" + name)
        let ans = false
        // Delete the file
        await desertRef.delete().then(async function () {
            // File deleted successfully
            ans = true
        }).catch(function (error) {
            ans = false
        })
        return ans
    }
    getCardIdFromCardName = async (userName) => {
        let id = this.db.ref('cards/').orderByChild("businessCardTitle").equalTo(userName)
            .once('value')
            .then(snapshot => {
                const card = snapshot.val();
                let key = Object.keys(card)[0];
                return key
            })
            .catch(error => { console.error("firebase - getCardIdFromCardName error:", error); })
        return id
    }
    removeFaceUrlFromCard = (cardId) => {
        let ans = false
        this.db.ref('cards/' + cardId + "/faceImageUrl").set("").then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }
    uploadBlobFace = async (blob, userId) => {
        let name = blob.name
        let index = name.indexOf(".")
        let blobType = name.substr(index, name.length)
        var ImgUploadRef = this.storage.ref("cards/" + userId + "_face" + blobType)
        var upload = await ImgUploadRef.put(blob)
        let url = ImgUploadRef.getDownloadURL()
        return url
    }

    getAndroidAppUrl = async () => {
        var ImgUploadRef = this.storage.ref("android_app/ng-card-app.apk")
        let url = await ImgUploadRef.getDownloadURL()
        return url
    }
    uploadBlobCover = async (blob, userId) => {
        let name = blob.name
        let index = name.indexOf(".")
        let blobType = name.substr(index, name.length)
        var ImgUploadRef = this.storage.ref("cards/" + userId + "_cover" + blobType)
        var upload = await ImgUploadRef.put(blob)
        let url = ImgUploadRef.getDownloadURL()
        return url
    }

    uploadBlobGallery = async (blob, name1, i) => {
        var ImgUploadRef = this.storage.ref("card_galleries/" + name1 + "_img_" + Date.now() + "" + i + ".png")
        var upload = await ImgUploadRef.put(blob)
        let url = ImgUploadRef.getDownloadURL()
        return url
    }
    removeImageFromUser = async (imgUrl, cardId) => {
        let ex1 = ".appspot.com/o/"
        let index = imgUrl.indexOf(ex1)
        let url2 = imgUrl.substr(index + ex1.length, imgUrl.length)
        let ex2 = "alt=media"
        let index2 = url2.indexOf(ex2)
        url2 = url2.substr(0, index2 - 1)
        let strs = url2.split("%2F")
        let imgName = strs[1]
        let desertRef = this.storage.ref("card_galleries/").child(imgName)

        let ans = false
        let ans2 = false

        let db2 = this.db
        // Delete the file
        let ans3 = await desertRef.delete()
            .then(async function () {
                // File deleted successfully
                ans = true
                let ans2 = false
                let ans5 = db2.ref('cards/' + cardId + "/galleryImageUrls")
                    .once('value')
                    .then(snapshot => {
                        const images = snapshot.val()
                        for (const [key, value] of Object.entries(images)) {
                            if (value === imgUrl) {
                                let ans4 = db2.ref('cards/' + cardId + "/galleryImageUrls/" + key).remove().then(ans => {
                                    return true

                                }).catch(function (error) {
                                    return false
                                })
                                return ans4
                            }
                        }
                        return ans2
                    })
                return ans5
            }).catch(function (error) {
                console.error("removeCardFromGallery - error:", error)
                ans2 = false
                return ans2
            })
        return ans && ans3
    }
    doUploadToUserGallery = async (blob, name1, i) => {
        let name = name1.replaceAll(/\s/g, '_')
        var ImgUploadRef = this.storage.ref(
            "card_galleries/" + name1 + "_img_" + Date.now() + "" + i + ".png")
        var upload = await ImgUploadRef.put(blob)
        let url = ImgUploadRef.getDownloadURL()
        return url
    }

    addImgUrlToGallery = (cardId, imgUrl) => {
        let ans = false
        this.db.ref('cards/').child(cardId).child("galleryImageUrls").push(imgUrl).then(
            ans = true
        ).catch(err => {
            ans = false
        })
        return ans
    }

    getCardById = async (id) => {
        let c2 = await this.db.ref('cards')
            .child(id)
            .once("value")
            .then(snapshot => {
                const c = snapshot.val()
                return c
            })
        return c2
    }
    getCardsFromCardIds = async (cardIds) => {
        let cards = []
        const promises = cardIds.map(async (id, i) => {
            await this.db.ref('cards')
                .child(id)
                .once("value")
                .then(snapshot => {
                    const c = snapshot.val()
                    cards.push(c)
                })
                .catch(error => ({
                    errorCode: error.code,
                    errorMessage: error.message
                }));
        })

        Promise.all(promises).then(allCards => {
            return cards
        })
    }

    doUploadUserImage = async (data) => {
        const metadata = {
            contentType: "image/jpeg"
        };
        return this.storage.ref("images/users")
            .child(data.name)
            .put(data, metadata)
            .then(snap => {
                return snap.ref.getDownloadURL();
            })
            .then(downloadURL => {
                return downloadURL;
            })
            .catch(error => {
            });
    }
    doUploadItemImage = async (data) => {
        const metadata = {
            contentType: "image/jpeg"
        };
        return this.storage.ref("images/items")
            .child(data.name)
            .put(data, metadata)
            .then(snap => {
                return snap.ref.getDownloadURL();
            })
            .then(downloadURL => {
                return downloadURL;
            })
            .catch(error => {
            });
    }

    doGetUserImage = (image) =>
        this.storage.ref("images/users")
            .child(image).getDownloadURL().catch(error => {
                alert(error.message)
            })

    doGetItemImage = (image) =>
        this.storage.ref("images/items")
            .child(image).getDownloadURL().catch(error => {
                alert(error.message)
            })


    doGetBGImage = (image) =>
        this.storage.ref("theme")
            .child(image).getDownloadURL().catch(error => {
                alert(error.message)
            })

    doGetCardImage = (image) =>
        this.storage.ref("cards")
            .child(image).getDownloadURL().catch(error => {
                alert(error.message)
            })

    doGetInfoImage = (image) =>
        this.storage.ref("info images")
            .child(image).getDownloadURL().catch(error => {
                alert(error.message)
            })


    addImageUrlToUser = (email, imageUrl) => {
        this.db.ref('users/').orderByChild("email").equalTo(email)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let arr = Object.values(user); //Converting an Object into an array
                this.db.ref('users/' + arr[0].id + '/imageUrl').set(imageUrl)
            })
            .catch(error => { console.error("addImageUrlToUser error:", error) })

    }

    getIsInPersonCardIds = async (email, cardId) => {
        let cardIds = await this.db.ref('users/').orderByChild("email").equalTo(email)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let arr = Object.values(user); //Converting an Object into an array
                let arr2 = Object.values(arr[0].cardIds)
                return arr2
            })
            .catch(error => { console.error("firebase - getIsInPersonCardIds error:", error); })

        let ans = false
        if (cardIds !== undefined) {
            cardIds.map((id, i) => {
                if (id === cardId) {
                    ans = true
                }
            })
        }
        return ans
    }


    // *** Auth API ***

    doUpdateUserDisplayName = async (name) => {
        let ans = false
        await this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                authUser.updateProfile({
                    displayName: name
                })
                ans = true
            }
        })
        return ans
    }

    setUserFromName = async (userId, username) => {
        let ans = false
        await this.db.ref('users/').child(userId).update({ name: username })
            .then(async () => {
                ans = true
                ans = await this.doUpdateUserDisplayName(username)
            }
            ).catch(err => {
                ans = false
            })
        return ans
    }
    getCurrentUser = (next, fallback) => {
        this.auth.onAuthStateChanged(authUser => {
            let currentUser = this.auth.currentUser
            if (currentUser !== null) {
                currentUser.getIdTokenResult()
                    .then(res => {
                    })
            }

            if (authUser) {
                next(authUser)
            } else {
                fallback()
            }
        })
    }

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password)


    doCreateUserWithEmailAndPasswordWithName = (email, password, username) =>
        this.auth.createUserWithEmailAndPassword(email, password)
            .then(userCred => {
                userCred.user.updateProfile({
                    displayName: username
                }).then(function () {
                    // Update successful.
                }, function (error) {
                    console.error(error);
                });
            }, function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.');
                } else {
                    console.error(error);
                }
            });
            
    doSendEmailVerification = () => {
        return this.auth.currentUser.sendEmailVerification({
            url: env["REACT_APP_CONFIRMATION_EMAIL_REDIRECT"]
        })
    }

    doSignInWithEmailAndPassword = async (email, password) => {
        let ans = null
        ans = await this.auth.signInWithEmailAndPassword(email, password)
            .catch(error => {
                console.error("firebase - login - error:", error.message)
                ans = error
                return ans
            })
        return ans
    }

    doSignOut = () => {
        this.auth.signOut().catch(error => {
            alert(error.message)
        })
    }

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email).catch(error => {
        alert(error.message)
    })

    doPasswordUpdate = async (oldPsw, email, password) => {        
        let ans = null
        await this.auth.signInWithEmailAndPassword(email, oldPsw)
            .catch(error => {
                console.error("firebase - login - error:", error.message)
                ans = error
                return ans
            })

        await this.auth.currentUser.updatePassword(password)
            .catch(error => {
                ans = error
                return ans
            })

        let DB = this.db
        await this.db.ref('users/').orderByChild("email").equalTo(email)
            .once('value')
            .then(snapshot => {
                const user = snapshot.val();
                let arr = Object.values(user); //Converting an Object into an array
                
                let id = arr[0].id
                var userRef = DB.ref("users/" + id);
                userRef.update({
                    needToChangePassword: false
                })
                return true
            })
            .catch(error => {
                ans = error
                return ans
            })
        return ans
    }

}

export default Firebase
