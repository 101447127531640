import bg1 from '../images/bg1.png'
import bg2 from '../images/bg2.png'
// import bg4 from '../images/bg4.png'
import bg5 from '../images/bg5.png'
import bg6 from '../images/bg6.png'

export const getBackGroundStyle = (person, selectedCardOption, selectedBGColor) => {
  if (person !== null) {//from all pages except admin
    let cardOption = getCardOption(person, selectedCardOption, selectedBGColor)
    let style = {
      // backgroundImage: `url(${bg6})`,
      backgroundImage: 
      // selectedCardOption !== null ?
        cardOption === "Glory" ? `url(${bg1})` :
          cardOption === "Midnight" ? `url(${bg2})` :
            // cardOption === "Midnight2" ? `url(${bg4})` :
              cardOption === "Metal Blue" ? `url(${bg5})` :
                cardOption === "Peaceful White" ? `url(${bg6})` 
                // :null
        :
        null,
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: cardOption === "Classic" ? "#f7fbfe" :
        cardOption.value === undefined ? cardOption :
          selectedBGColor !== "#fff" ? selectedBGColor : "white",
    }
    return style
  } else {//From Admin group card
    let bgName = selectedCardOption
    let style = {
      backgroundImage: bgName !== null ?
        bgName === "Glory" ? `url(${bg1})` :
          bgName === "Midnight" ? `url(${bg2})` :
            // bgName === "Midnight2" ? `url(${bg4})` :
              bgName === "Metal Blue" ? `url(${bg5})` :
                bgName === "Peaceful White" ? `url(${bg6})` :
                  null
        :
        null,
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: selectedBGColor !== null ? selectedBGColor === "Classic" ? "#f7fbfe" :
        selectedBGColor.value === undefined ? selectedBGColor :
          selectedBGColor !== "#fff" ? selectedBGColor : "white"
        : null,
    }
    return style
  }
}

export const getTextColor = (person, selectedTextColor) => {
  let textColor = null

  if (person !== undefined && person !== null && person.textColor !== "") {
    if (selectedTextColor !== undefined && selectedTextColor !== null) {
      if (selectedTextColor.value !== undefined && selectedTextColor.value !== null) {
        textColor = selectedTextColor.value
      } else {
        textColor = selectedTextColor
      }
    } else {
      textColor = person.textColor
    }
  }
  else if (selectedTextColor !== null && selectedTextColor !== undefined) {
    if (selectedTextColor.value !== undefined && selectedTextColor.value !== null) {
      textColor = selectedTextColor.value
    } else {
      textColor = selectedTextColor
    }
  }
  return textColor
}

export const getIconBorderColor = (person, selectedIconBorderColor) => {

  let iconBorderColor = "black"
  if (person !== undefined && person !== null && person.iconBorder !== "") {
    if (selectedIconBorderColor === undefined
      || selectedIconBorderColor === null
      || selectedIconBorderColor === "") {
      iconBorderColor = person.iconBorder
    } else {
      if (selectedIconBorderColor.value !== undefined) {
        iconBorderColor = selectedIconBorderColor.value
      } else {
        iconBorderColor = selectedIconBorderColor
      }
    }
  } else {
    if (selectedIconBorderColor !== null && selectedIconBorderColor !== undefined)
      if (selectedIconBorderColor.value !== undefined && selectedIconBorderColor.value !== null) {
        iconBorderColor = selectedIconBorderColor.value
      } else {
        iconBorderColor = selectedIconBorderColor
      }
  }
  return iconBorderColor
}

export const getIconBorderShape = (person, selectedIconBorderShape) => {
  let iconBorderShape = null
  if (person !== undefined && person !== null && person.iconOptions !== "") {
    if (selectedIconBorderShape !== null && selectedIconBorderShape !== undefined) {
      iconBorderShape = selectedIconBorderShape.value
    } else {
      iconBorderShape = person.iconOptions
    }
  } else if (selectedIconBorderShape !== undefined && selectedIconBorderShape !== null) {
    iconBorderShape = selectedIconBorderShape.value
  }
  return iconBorderShape
}

export const getLblStyle = (person, selectedTextColor) => {
  let textColor = getTextColor(person, selectedTextColor)
  let lblStyle = { color: textColor }
  return lblStyle
}

export const getWazeBtnStyle = (person, selectedIconBorderShape, selectedIconBorderColor) => {
  let iconBorderShape = getIconBorderShape(person, selectedIconBorderShape)
  let iconBorderColor = getIconBorderColor(person, selectedIconBorderColor)

  let bgColor = "#55cce7"
  let borderColor = "#55cce7"
  if (iconBorderColor === "Gold" || iconBorderColor === "goldenrod") {
    bgColor = "goldenrod"
    borderColor = "goldenrod"
  }

  let wazeBtnStyle = iconBorderShape === "Circle Icons" ?
    {
      borderColor: borderColor,
      backgroundColor: bgColor,
      borderRadius: "50%",
    }
    :
    iconBorderShape === "Rounded Rectangle Icons" ?
      {
        borderColor: borderColor,
        backgroundColor: bgColor,
        borderRadius: "15%",
      }
      :
      {
        borderColor: borderColor,
        backgroundColor: bgColor,
        borderRadius: "0%",
      }
  return wazeBtnStyle
}

export const getBtnStyle = (person, selectedIconBorderShape, selectedIconBorderColor) => {
  let iconBorderShape = getIconBorderShape(person, selectedIconBorderShape)
  let iconBorderColor = getIconBorderColor(person, selectedIconBorderColor)
  let btnStyle = iconBorderShape === "Circle Icons" ?
    {
      borderShape: iconBorderShape, borderRadius: "50%", borderColor: iconBorderColor
    }
    :
    iconBorderShape === "Rounded Rectangle Icons" ?
      {
        borderShape: iconBorderShape, borderRadius: "15%", borderColor: iconBorderColor
      }
      :
      {
        borderShape: iconBorderShape, borderRadius: "0%", borderColor: iconBorderColor
      }
  return btnStyle
}

export const getCardOption = (person, selectedCardOption, selectedBGColor) => {
  let cardOption = null
  if (person !== undefined && person !== null &&
    (person.selectedBGColor !== "" || person.cardBGName !== "")) {
    if (person.cardBGName !== "" && selectedCardOption === null
      && selectedBGColor === "") {
      cardOption = person.cardBGName
    }
    else if (person.selectedBGColor !== "" && selectedCardOption === null && selectedBGColor === "") {
      cardOption = person.selectedBGColor
    }
    else if (selectedCardOption !== null && selectedCardOption !== undefined) {
      cardOption = selectedCardOption.value
    } else if (person.cardBGName !== "" && selectedBGColor === "") {
      cardOption = person.cardBGName
    } else if (selectedBGColor !== "") {
      cardOption = selectedBGColor
    } else {
      cardOption = "#fff"
    }
  }
  else {
    if (selectedCardOption !== null && selectedCardOption !== undefined) {
      cardOption = selectedCardOption.value
    } else if (selectedBGColor !== "") {
      cardOption = selectedBGColor
    } else {
      cardOption = "#fff"
    }
  }

  return cardOption
}
export const getCardOptionForEdit = (person, selectedCardOption, selectedBGColor) => {

  let cardOption = null
  let cardOptionType = null

  if (person !== undefined && person !== null &&
    (person.selectedBGColor !== "" || person.cardBGName !== "")) {
    if (person.cardBGName !== "" && selectedCardOption === null
      && selectedBGColor === "") {
      cardOption = person.cardBGName
      cardOptionType = "cardBGName"
    }
    else if (person.selectedBGColor !== "" && selectedCardOption === null && selectedBGColor === "") {
      cardOption = person.selectedBGColor
      cardOptionType = "selectedBGColor"
    }
    else if (selectedCardOption !== null) {
      cardOption = selectedCardOption.value
      cardOptionType = "cardBGName"
    } else if (person.cardBGName !== "" && selectedBGColor === "") {
      cardOption = person.cardBGName
      cardOptionType = "cardBGName"
    } else if (selectedBGColor !== "") {
      cardOption = selectedBGColor
      cardOptionType = "selectedBGColor"
    } else {
      cardOption = "#fff"
      cardOptionType = "selectedBGColor"
    }
  }
  else {
    if (selectedCardOption !== null) {
      cardOption = selectedCardOption.value
      cardOptionType = "cardBGName"
    } else if (selectedBGColor !== "") {
      cardOption = selectedBGColor
      cardOptionType = "selectedBGColor"
    } else {
      cardOption = "#fff"
      cardOptionType = "selectedBGColor"
    }
  }

  return [cardOption, cardOptionType]

}

export const getProfileOption = (person, selectedProfileImgOption) => {
  let profileOption = null
  if (person !== undefined && person !== null && person.profileImgOptions !== "") {
    if (selectedProfileImgOption !== null && selectedProfileImgOption !== undefined) {
      profileOption = selectedProfileImgOption.value
    } else {
      profileOption = person.profileImgOptions
    }
  }
  else if (selectedProfileImgOption !== undefined && selectedProfileImgOption !== null) {
    if (selectedProfileImgOption !== null && selectedProfileImgOption !== undefined) {
      profileOption = selectedProfileImgOption.value
    } else {
      profileOption = person.profileImgOptions
    }
  }

  return profileOption
}

export const cardNameForUrl = (cardName) => {
  let cardName1 = cardName.replaceAll(/\s/g, '-')
  return cardName1
}

export const cardImgNameForUrl = (cardName) => {
  let cardName1 = cardName.replaceAll(/\s/g, '_') + "_"
  return cardName1
}