
import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import './CreateGallery.scss';

class CreateGallery extends Component {
    constructor() {
        super();
        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 100,
                height: 100,
                maxImages: 10,
            },
            chosenImages: [],
            chosenImageObjects: [],
            showAreYouSurePopup: false,
            selectedImgToRemove: null,
            selectedImgToRemoveIndex: "-1",
        }
    }
    componentDidMount() {
        this.getImages()
    }

    getImages = () => {
        const { theCard } = this.props
        let oldImgs = []
        if (theCard !== undefined && theCard !== null &&
            theCard.galleryImageUrls !== undefined && theCard.galleryImageUrls !== null) {
            oldImgs = Object.values(theCard.galleryImageUrls)
        }
        this.props.setAllGalleryImgUrls(oldImgs)
        this.setState({
            chosenImages: oldImgs
        })
    }
    getAcceptedFiles = async (acceptedFiles) => {

        let moreThenMaxImgs = false
        let maxImages = 10
        let maxImgKB = 1000

        if (this.state.chosenImages.length > maxImages) {
            moreThenMaxImgs = true
        }
        if (this.state.chosenImages.length + acceptedFiles.length > maxImages) {
            moreThenMaxImgs = true
        }
        if (moreThenMaxImgs === true) {
            alert("you can upload up to 10 images")
        } else {
            let oneImgToBig = false
            for (let index = 0; index < acceptedFiles.length; index++) {
                const element = acceptedFiles[index]
                let size = element.size / 1024 // in KB
                if (size > maxImgKB) {
                    oneImgToBig = true
                }
            }


            if (oneImgToBig == true) {
                alert("one of the images is too big! please pick only images smaller then 1MB")
            } else {
                for (let index = 0; index < acceptedFiles.length; index++) {
                    let reader = new FileReader();
                    let img = acceptedFiles[index]
                    reader.onload = (file) => {
                        this.setState({
                            src: reader.result,
                            chosenImages: this.state.chosenImages.concat(reader.result),
                            chosenImageObjects:
                                this.state.chosenImageObjects
                                    .concat(
                                        {
                                            url: reader.result,
                                            blob: img
                                        }
                                    ),
                        }, () => {
                            this.props.setAllGalleryImgUrls(this.state.chosenImageObjects)
                        })
                    }

                    reader.readAsDataURL(img)

                }
            }
        }
    }
    getDropzoneDiv = () => {
        let text1 = window.innerWidth < 1100 ? "click to add Image" : "Drag and Drop a file or click to add Image"
        let text2 = "accepted Image Sizes: 1MB or less"

        return (
            <div className="the-dropzone">
                <label className="the-dropzone-lbl1">
                    {text1}
                </label>
                <label className="the-dropzone-lbl1">
                    {text2}
                </label>
            </div>
        )
    }


    removeImageFromUser = async () => {
        const { selectedImgToRemove, selectedImgToRemoveIndex, chosenImages } = this.state
        let url = window.location.pathname
        let strings = url.split("/")
        let cardId = strings[strings.length - 1]
        await this.props.firebase.removeImageFromUser(selectedImgToRemove, cardId)
        this.setState({
            showAreYouSurePopup: false,
            selectedImgToRemove: null,
        }, () => {


            let arr2 = [...chosenImages]
            arr2.splice(selectedImgToRemoveIndex, 1)
            this.setState({
                chosenImages: arr2
            }, () => {
                this.props.cancelThisImg(selectedImgToRemoveIndex)
            })
        })
    }
    cancelThisImg = (index) => {
        const { chosenImages, chosenImageObjects } = this.state
        let arr = [...chosenImages]
        let arr2 = [...chosenImageObjects]
        let bool = false
        arr2.forEach(element => {
            if (arr.includes(element.url)) {
                bool = true
            }
        });
        if (bool === true) {
            arr.splice(index, 1)

            this.setState({
                chosenImages: arr
            }, () => {
                this.props.cancelThisImg(index)
            })
        } else {
            this.setState({
                selectedImgToRemove: arr[index],
                showAreYouSurePopup: true,
                selectedImgToRemoveIndex: index,
            })
        }
    }

    getAllChosenImages = () => {
        const { chosenImages } = this.state
        let imgs = []
        chosenImages.map((im, i) => {
            imgs.push(
                <div className="a-chosen-img-div" key={i}>

                    <button className="remove-img-btn3"
                        onClick={() => { this.cancelThisImg(i) }}
                    >x</button>
                    <img
                        src={im}
                        onClick={() => { this.cancelThisImg(i) }}
                        className="a-chosen-img" alt="" />
                </div>
            )
        })
        return imgs
    }


    hideAreYouSure = () => {
        this.setState({
            showAreYouSurePopup: false,
            selectedImgToRemove: null,
        })
    }
    getAreYouSurePopup = () => {
        const { selectedImgToRemove } = this.state
        return (
            <div className="are-you-sure-img-popup">
                <label className="are-you-sure-title-lbl">
                    Are you sure you want to remove this image?
                </label>
                <img alt=""
                    className="are-you-sure-img"
                    src={selectedImgToRemove} />


                <div className="are-you-sure-btns-div">
                    <button className="are-you-sure-btn2"
                        onClick={this.removeImageFromUser}
                    >Yes</button>
                    <button className="are-you-sure-btn2"
                        onClick={this.hideAreYouSure}
                    >No</button>
                </div>
            </div>
        )
    }

    render() {
        const { showAreYouSurePopup } = this.state
        return (
            <div className="create-gallery-main-container">

                {showAreYouSurePopup === true ? this.getAreYouSurePopup()
                    :
                    null}
                <div className="upload-images-div">

                    <div className="upload-image-inside-div">

                        <label className="add-top-lbl">
                            Upload Images To Gallery
                        </label>

                        <div className="add-img-container">

                            <Dropzone
                                onDrop={
                                    acceptedFiles => this.getAcceptedFiles(acceptedFiles)
                                }
                            >

                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            {this.getDropzoneDiv()}

                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                        </div>

                        <div className="chosen-images-div">
                            {this.getAllChosenImages()}
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default compose(withFirebase)(CreateGallery)