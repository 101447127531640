import React, { Component } from 'react';

import BottomBar from './BottomBar'
import ContactUsComponent from '../homepage components/ContactUsComponent'
import AdvantagesComponent from '../homepage components/AdvantagesComponent'
import HomeTopComponent from '../homepage components/HomeTopComponent'
import ExampleCardsCarousle from '../homepage components/ExampleCardsCarousle'

import './HomePage.scss';

class HomePage extends Component {
    constructor() {
        super();
        this.state = {
            fullName: "",
            email: "",
            phone: "",
            msg: "",
        }
    }

    componentDidMount() {
        let h = window.innerHeight
        let w = window.innerWidth
    }

    getDesktopView = () => {
        return (
            <div>

            </div>
        )
    }

    render() {

        return (
            <div className="home-page-main-container">
                <HomeTopComponent />

                <AdvantagesComponent />
              
                <ExampleCardsCarousle />

                <ContactUsComponent
                    bgColor={"black"} textColor={"white"}
                    contactTiltle={"How Can We Help You?"}
                />

                <BottomBar />

            </div>
        )
    }
}

export default HomePage;