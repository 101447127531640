import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../firebase/index';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { cardNameForUrl, } from "../utils/utils";
import searchIcon from '../images/search-icon.png'
import emptyUser from '../images/empty_user2.jpg'
import flagImg from '../images/flag-plan.png'

import './BuisnessCardsPage.scss'

class BuisnessCardsPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            usersData: [],
            userId: "",
            filteredUsersData: [],
            searchTerm: "",
            selected: 0,
            rowSelected: null,
            menuX: 0,
            menuY: 0,
            showAreYouSure: false,
            showChangePlanPopup: false,
            selectedPopupCard: null,
            needToChangePassword: false,
        }
    }
    componentDidMount = async () => {
        this.getData()
    }

    removeCard = async () => {
        let cardId = await this.props.firebase.getCardIdFromCardName(
            this.state.selectedPopupCard.businessCardTitle)

        await this.props.firebase.removeCardPermanently(
            this.state.userId, cardId)

        this.setState({ showAreYouSure: false }, () => {
            this.getData()
        })
    }

    onChangeSearchTerm = event => {
        let term = event.target.value
        let usersData1 = this.state.usersData.filter(function (user) {
            return user.businessCardTitle.toLowerCase().includes(term.toLowerCase())
        })
        this.setState({
            searchTerm: event.target.value,
            filteredUsersData: usersData1
        })
    }
    getData = async () => {
        this.props.firebase.getCurrentUser(
            async user1 => {
                let userEmail = user1.email
                let user = await this.props.firebase.getUserByEmail(userEmail)
                let userId = user.id
                if (user.cardIds !== undefined) {
                    let cardIds = Object.values(user.cardIds)
                    let cards = []
                    const promises = cardIds.map(async (id, i) => {
                        let c1 = await this.props.firebase.getCardById(id)
                        cards.push(c1)
                    })

                    Promise.all(promises).then(data => {
                        this.setState({
                            usersData: cards,
                            filteredUsersData: cards,
                            userId,
                            loading: false,
                            needToChangePassword: user.needToChangePassword === true ? true : false
                        })
                    })
                } else {
                    this.setState({
                        usersData: [],
                        filteredUsersData: [],
                        userId,
                        loading: false,
                        needToChangePassword: user.needToChangePassword === true ? true : false
                    })
                }
            },
            () => {
            }
        )
    }

    showChangePlanPopup = (card) => {
        this.setState({
            showChangePlanPopup: true,
            selectedPopupCard: card,
        })
    }

    hideChangePlanPopup = () => {
        this.setState({
            showChangePlanPopup: false,
            selectedPopupCard: null,
        })
    }

    showAreYouSureRemoveCard = (card) => {
        this.setState({
            showAreYouSure: true,
            selectedPopupCard: card,
        })
    }

    hideAreYouSure = () => {
        this.setState({
            showAreYouSure: false,
            selectedPopupCard: null,
        })
    }

    selectTab = async (tab) => {
        switch (tab) {
            case 0:
                // await this.getData()
                this.setState({
                    selected: tab, filteredUsersData: this.state.usersData
                })
                break
            case 1:
                // await this.getData()
                let usersData1 = this.state.usersData.filter(function (user) {
                    return user.status.includes("published")
                })
                this.setState({ selected: tab, filteredUsersData: usersData1 })
                break
            case 2:
                // await this.getData()
                let usersData2 = this.state.usersData.filter(function (user) {
                    return user.status.includes("pending")
                })
                this.setState({ selected: tab, filteredUsersData: usersData2 })
                break
            case 3:
                // await this.getData()
                let usersData3 = this.state.usersData.filter(function (user) {
                    return user.status.includes("expired")
                })
                this.setState({ selected: tab, filteredUsersData: usersData3 })
                break
            default:
                break
        }
    }

    goToAddCard = () => {
        window.location.href = "/pricing-plan"
    }

    getMobileList = () => {
        return this.state.filteredUsersData.map((row, i) => {

            let src = emptyUser
            if (row.faceImageUrl !== undefined && row.faceImageUrl !== "") {
                src = row.faceImageUrl
            }
            let v = 0
            if (row.views !== undefined && row.views !== "") {
                let arr = Object.values(row.views)
                v = arr.length
            }


            let miliseconds = row.createdAt
            let dateCreatedPlus1Year = moment(miliseconds).add(1, 'Y')
            let dateNow = moment(new Date(Date.now()))
            let diffInDays = dateCreatedPlus1Year.diff(dateNow, 'days');


            let plan = ""
            if (row.associatedPlan === "plan1") {
                plan = "Premium"
            } else if (row.associatedPlan === undefined) {
                plan = "Premium"
            } else if (row.associatedPlan === "") {
                plan = "Premium"
            } else {
                plan = "Diamond"
            }
            return (
                <div key={i}>
                    <div className="list-row-top-div" >
                        <img src={src} className="row-img-mobile-list" alt=""
                            onClick={() => { this.onRowClicked(row) }} />
                        <label className="row-name-lbl-list"
                            onClick={() => { this.onRowClicked(row) }}>
                            {row.businessCardTitle}
                        </label>
                        <div className="mobile-remove-card-btn"
                            onClick={() => {
                                this.showAreYouSureRemoveCard(row)
                            }}>
                            <FontAwesomeIcon
                                icon={faTimes} className="menu-btn-icon2" />
                        </div>
                    </div>
                    <div className="list-row-views-div">
                        <label className="list-row-views-lbl">views</label>
                        <label className="list-row-views-val">{v} Views</label>
                    </div>
                    <div className="list-row-views-div">
                        <label className="list-row-views-lbl">{row.status}</label>
                        {row.status === "pending" ?
                            <label className="list-row-views-val"> - </label>
                            :
                            <label className="list-row-views-val">
                                {diffInDays} subscription days remain
                            </label>
                        }
                    </div>
                    <div className="list-row-views-div">
                        <label className="list-row-views-lbl">Associated Plan</label>
                        <label className="list-row-views-val">{plan}</label>
                    </div>
                    <div className="list-row-status-div">
                        <label className="list-row-views-lbl">Status</label>

                        <div className="list-row-status-row">

                            {row.status === "pending" ?
                                <button className="pay-btn"
                                    onClick={() => {
                                        window.location.href = "/payment-checkout"
                                    }}>

                                    <FontAwesomeIcon
                                        className="dots-icon2"
                                        icon={faCreditCard}
                                        color="white"
                                    />
                                    Pay
                                </button>
                                :
                                null}

                            <label className="list-row-status-lbl"
                                style={{
                                    color: row.status === "pending" ? "black" : "green"
                                }}
                            >{row.status} </label>
                        </div>
                    </div>
                    <div className="list-row-line" />
                </div>
            )
        })
    }

    getAreYouSurePopup = () => {
        const { selectedPopupCard } = this.state
        return (
            <div className="are-you-sure-popup">
                <label className="are-you-sure-title-lbl">
                    Are you sure you want to remove this card?
                </label>

                <label className="are-you-sure-title-lbl2">
                    this action is irreversable
                </label>

                <img alt=""
                    className="are-you-sure-img"
                    src={selectedPopupCard.faceImageUrl !== undefined
                        && selectedPopupCard.faceImageUrl !== null
                        && selectedPopupCard.faceImageUrl !== "" ?
                        selectedPopupCard.faceImageUrl : emptyUser} />
                <label className="are-you-sure-name-lbl">
                    {selectedPopupCard.businessCardTitle}
                </label>

                <div className="are-you-sure-btns-div">
                    <button className="are-you-sure-btn"
                        onClick={this.removeCard}
                    >Yes</button>
                    <button className="are-you-sure-btn"
                        onClick={this.hideAreYouSure}
                    >No</button>
                </div>
            </div>
        )
    }

    choosePlan = async (plan) => {

        let cardId = await this.props.firebase.getCardIdFromCardName(
            this.state.selectedPopupCard.businessCardTitle)

        await this.props.firebase.setCardPlan(cardId, plan)

        this.setState({ showChangePlanPopup: false }, () => {
            this.getData()
        })


    }
    getChangePlanPopup = () => {
        let currentPlan = this.state.selectedPopupCard.associatedPlan
        return (
            <div className="change-plan-popup">
                <button className="change-plan-popup-x-btn"
                    onClick={this.hideChangePlanPopup}
                >X</button>
                <label className="change-plan-title-lbl">
                    change this card plan
                </label>
                <div className="current-div"
                    style={{
                        justifyContent:
                            currentPlan === "1 Digital Business Card" ?
                                "flex-start" : "flex-end"
                    }}
                >
                    <label className="current-plan-lbl">
                        current plan
                    </label>
                </div>
                <div className="plan-cards-div2">

                    <div className="plan-card-div">
                        <label className="plan-card-top-lbl">
                            1 Digital Business Card
                        </label>

                        <label className="plan-card-duration-lbl">
                            Duration: 1 Year
                        </label>

                        <label className="price-lbl">
                            N 25,000
                        </label>

                        <label className="plan-card-per-lbl">
                            Per Business Card
                        </label>

                        <label className="plan-card-includes-lbl">
                            The price includes card setup,
                        </label>

                        <label className="plan-card-includes-lbl">
                            support, and a management app
                        </label>

                        <button
                            className="choose-plan-btn"
                            onClick={() => this.choosePlan("1 Digital Business Card")}
                        >
                            CHOOSE PLAN
                        </button>
                    </div>

                    <div className="plan-card-div2">
                        <img className="flag-img" alt="flag" src={flagImg} />

                        <label className="plan-card-top-lbl">
                            5 Digital Business Card
                        </label>

                        <label className="best-seller-lbl">
                            Best Seller
                        </label>

                        <label className="plan-card-duration-lbl">
                            Duration: 1 Year
                        </label>

                        <label className="price-lbl">
                            $270
                        </label>

                        <label className="plan-card-per-lbl">
                            Per Business Card
                        </label>

                        <label className="plan-card-includes-lbl">
                            The price includes card setup,
                        </label>

                        <label className="plan-card-includes-lbl">
                            support, and a management app
                        </label>

                        <button
                            className="choose-plan-btn"
                            onClick={() => this.choosePlan("5 Digital Business Cards")}
                        >
                            CHOOSE PLAN
                        </button>
                    </div>

                </div>


            </div>
        )
    }

    getColumns = () => {
        const columns = [
            {
                name: '',
                selector: 'faceImageUrl',
                sortable: false,
                width: "11%",
                format: row => {

                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        if (row.faceImageUrl !== undefined && row.faceImageUrl !== "") {
                            return <img src={row.faceImageUrl}
                                className="row-img" alt=""
                                onClick={() => { this.onRowClicked(row) }}
                            />
                        } else {
                            return <img src={emptyUser} className="row-img" alt=""
                                onClick={() => { this.onRowClicked(row) }} />
                        }
                    }
                },
            },
            {
                name: 'Title',
                selector: 'businessCardTitle',
                sortable: true,
                width: "20%",
                style: {
                    fontSize: '100%',
                },
                format: row => {

                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        if (row.businessCardTitle !== "") {
                            return <label
                                onClick={() => { this.onRowClicked(row) }}
                                className="row-lbl">
                                {row.businessCardTitle}
                            </label>
                        } else {
                            return <label></label>
                        }
                    }
                },
            },
            {
                name: 'Views',
                selector: 'views',
                sortable: true,
                width: "10%",

                format: row => {
                    let v = 0
                    if (row.views !== undefined && row.views !== "") {
                        let arr = Object.values(row.views)
                        v = arr.length
                    }
                    return <label>{v}</label>
                },
            },
            {
                name: 'Published',
                selector: 'createdAt',
                sortable: true,
                width: "15%",
                format: row => {
                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        let miliseconds = row.createdAt
                        let dateCreated = moment(miliseconds).format('DD/MM/YYYY')
                        return <label>{dateCreated}</label>
                    }
                }
            },
            {
                name: 'Expiry',
                selector: 'createdAt',//1624461467712
                sortable: true,
                width: "7%",
                format: row => {
                    if (row.businessCardTitle === undefined) {
                        return <label></label>
                    } else {
                        let miliseconds = row.createdAt
                        let dateCreatedPlus1Year = moment(miliseconds).add(1, 'Y')
                        let dateNow = moment(new Date(Date.now()))
                        let diffInDays = dateCreatedPlus1Year.diff(dateNow, 'days');
                        return <label>{diffInDays}</label>
                    }
                }
            },
            {
                name: 'Plan',
                selector: 'associatedPlan',
                sortable: true,
                width: "15%",
                format: row => {
                    return <label className="associatedPlan-lbl">
                        {row.associatedPlan}
                    </label>
                }
            },
            {
                name: 'status',
                selector: 'status',
                width: "10%",
                sortable: true,
                format: row => {
                    if (row.status === "pending") {
                        return <label className="pending-lbl">{row.status}</label>
                    } else {
                        return <label style={{
                            color: "lightgreen", fontWeight: "bold"
                        }}>{row.status}</label>
                    }
                },
            },
            {
                name: '',
                selector: 'status',
                width: "7%",
                sortable: true,
                format: row => {
                    if (row.status === "pending") {
                        return <button className="pay-btn2"
                            onClick={() => {
                                window.location.href = "/payment-checkout"
                            }}
                        >Pay</button>
                    } else {
                        return <label></label>
                    }
                },
            },
            {
                name: '',
                selector: 'status',
                width: "5%",
                sortable: false,
                format: row => {
                    return <FontAwesomeIcon icon={faTimes} className="menu-btn-icon2"
                        onClick={() => {
                            this.showAreYouSureRemoveCard(row)
                        }}
                    />
                },
            },
        ]

        return columns

    }

    getCustomStyles = () => {
        const customStyles = {
            divider: {
                style: {
                }
            },
            rows: {
                style: {
                }
            },
            headCells: {
                style: {
                    background: "#eef2f4",
                    color: "black",
                    fontSize: "90%",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                },
            },
            cells: {
                style: {
                    fontSize: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                },
            },
        }
        return customStyles
    }
    onRowClicked = (row) => {
        let name = cardNameForUrl(row.businessCardTitle)
        let id = row.id
        window.location.href = "/card/" + name + "/" + id
    }

    getAnnoyingChangePsw = () => {
        if (this.state.needToChangePassword === true) {
            return (
                <div className="annoying-change-psw">
                    <label className="annoying-change-psw-lbl">
                        please update your password
                    </label>
                    <button className="annoying-change-psw-btn"
                        onClick={() => {
                            window.location.href = "/listing-author?dashboard=update-profile"
                        }}>OK</button>
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const { loading, filteredUsersData, selected, searchTerm,
            showAreYouSure, showChangePlanPopup,
        } = this.state

        if (loading === true) {
            return (
                <div className="loading-main-div">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }

        let businessCardTitleA = "All"
        switch (selected) {
            case 0:
                businessCardTitleA = "All"
                break;
            case 1:
                businessCardTitleA = "Published"
                break;
            case 2:
                businessCardTitleA = "Pending"
                break;
            case 3:
                businessCardTitleA = "Expired"
                break;
            default:
                businessCardTitleA = ""
                break
        }
        const columns = this.getColumns()
        const customStyles = this.getCustomStyles()

        return (
            <div className="buisness-cards-page-container">
                {showAreYouSure === true ? this.getAreYouSurePopup()
                    :
                    null}
                {this.getAnnoyingChangePsw()}

                {showChangePlanPopup === true ? this.getChangePlanPopup() : null}
                {window.innerWidth > 1100 ?
                    <div className="buisness-cards-page-container-browser">

                        <div className="buisness-cards-inside">

                            <label className="buisness-cards-title">
                                {businessCardTitleA}
                                Digital Business Cards
                                ({filteredUsersData.length})
                            </label>

                            <button className="add-card-btn"
                                onClick={this.goToAddCard}
                            >
                                + Add Buisness Card
                            </button>

                            <div className="tabs-and-search">

                                <div className="buisness-cards-table-tabs">
                                    <div className="table-tab"
                                        style={selected === 0 ? {
                                            borderBottom: "4px solid skyblue"
                                        } : null}
                                        onClick={() => this.selectTab(0)}
                                    >
                                        ALL
                                    </div>
                                    <div className="table-tab"
                                        style={selected === 1 ? {
                                            borderBottom: "4px solid skyblue"
                                        } : null}
                                        onClick={() => this.selectTab(1)}
                                    >
                                        PUBLISHED
                                    </div>
                                    <div className="table-tab"
                                        style={selected === 2 ? {
                                            borderBottom: "4px solid skyblue"
                                        } : null}
                                        onClick={() => this.selectTab(2)}
                                    >
                                        PENDING
                                    </div>
                                    <div className="table-tab"
                                        style={selected === 3 ? {
                                            borderBottom: "4px solid skyblue"
                                        } : null}
                                        onClick={() => this.selectTab(3)}
                                    >
                                        EXPIRED
                                    </div>

                                </div>

                                <div className="search-div">
                                    <input
                                        className="my-search"
                                        type="text"
                                        placeholder="Search Card"
                                        name="searchTerm"
                                        value={searchTerm}
                                        onChange={this.onChangeSearchTerm}
                                    />
                                    <img src={searchIcon}
                                        className="search-img"
                                        alt="" />
                                </div>
                            </div>
                            <div className="table-line" />

                            <DataTable
                                className="the-table"
                                columns={columns}
                                data={filteredUsersData}
                                defaultSortField="businessCardTitle"
                                noHeader={true}
                                customStyles={customStyles}
                            />

                        </div>
                    </div>

                    :

                    <div className="buisness-cards-page-container-mobile">
                        <div className="buisness-cards-inside-mobile">

                            <div className="title-and-search">
                                <label className="saved-card-title">
                                    {businessCardTitleA}
                                    Digital Business Cards
                                    ({filteredUsersData.length})
                                </label>

                                <div className="search-div">

                                    <input
                                        className="my-search"
                                        type="text"
                                        placeholder="Search Card"
                                        name="searchTerm"
                                        value={this.state.searchTerm}
                                        onChange={this.onChangeSearchTerm}
                                    />
                                    <img src={searchIcon}
                                        className="search-img"
                                        alt="" />
                                </div>
                            </div>

                            <button className="add-card-btn-mobile"
                                onClick={this.goToAddCard}
                            >
                                + Add Buisness Card
                            </button>

                            <div className="buisness-cards-table-tabs-mobile">
                                <div className="table-tab"
                                    style={selected === 0 ? {
                                        borderBottom: "4px solid skyblue"
                                    } : null}
                                    onClick={() => this.selectTab(0)}
                                >
                                    ALL
                                </div>
                                <div className="table-tab"
                                    style={selected === 1 ? {
                                        borderBottom: "4px solid skyblue"
                                    } : null}
                                    onClick={() => this.selectTab(1)}
                                >
                                    PUBLISHED
                                </div>
                                <div className="table-tab"
                                    style={selected === 2 ? {
                                        borderBottom: "4px solid skyblue"
                                    } : null}
                                    onClick={() => this.selectTab(2)}
                                >
                                    PENDING
                                </div>
                                <div className="table-tab"
                                    style={selected === 3 ? {
                                        borderBottom: "4px solid skyblue"
                                    } : null}
                                    onClick={() => this.selectTab(3)}
                                >
                                    EXPIRED
                                </div>
                            </div>
                            <div className="table-line" />

                            <div className="the-list-div">
                                {this.getMobileList()}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default compose(withFirebase)(BuisnessCardsPage)