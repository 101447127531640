
import React, { Component } from 'react';
import BottomBar from './BottomBar'
import './PrivacyPolicyPage.scss';

class PrivacyPolicyPage extends Component {
    constructor() {
        super();
        this.state = {}
    }
    componentDidMount() { }

    render() {

        return (
            <div id="top" className="privacy-main-container">

                <div className="choose-plan-title-bg"></div>
                <div className="title-overly">---Privacy Policy---</div>

                <div className="privacy-text-container">

                    <label className="privacy-bold">Introduction</label>

                    <br />
                    The NetGoCard.com website (hereinafter: the “Website”) is a system for building digital business cards (hereinafter: the “Service”), for a renewable subscription fee.
                    <br />
                    The parties to this agreement are “NetGoCard.com” (hereinafter: “the Vendor”), you (the ‘user’) and all the users of the NetGoCard.com website for the variety of services provided there (the ‘service’, the ‘users’). These Terms of Use apply and will apply to any use of the Site.
                    <br />
                    By using the Service, you accept these Terms. If you do not agree to these terms, do not use the service.

                    <br /> <br /> <br />
                    <label className="privacy-bold">Terms of Use</label>
                    <br />
                    The user is given permission to use the site’s services subject to what is stated in this agreement.
                    <br />
                    The user declares that he/she is over the age of 18 and has legal capacity to contact the owners of the site in this agreement. The user is solely responsible for all content that is published on his digital business card.
                    <br />
                    Under no circumstances, the vendor will not be liable for any indirect or consequential damages of any kind (including but not limited to, consequential, special or incidental damages, loss of business profits, disruption to customer business, loss of business information or any other monetary damages) as a result of use or inability to use the site software, even if it has been notified of the possibility of this damage. Anyway, the Supplier will not be liable for any liability that exceeds the amount paid by the customer in accordance with this agreement.
                    <br />
                    The user confirms registration for a mailing list and receiving advertising information from the supplier, for the avoidance of doubt, you can exit the mailing list at any time via the link at the bottom of the mailing
                    <br /> <br /> <br />
                    <label className="privacy-bold">enrollment</label>
                    <br />
                    As part of the registration, you will be required to provide personal information: name, address, contact information, e-mail and / or credit card information. The data you provide when registering will be stored in the system’s database. You are not required by law to provide the information but without it you will not be able to use the services provided. You are asked to be careful in providing the details and to provide only correct details.

                    <br /> <br /> <br />
                    <label className="privacy-bold">Backups</label>
                    <br />
                    The vendor will not be responsible for backing up the content on the customer’s digital business card and it is the customer’s responsibility to make a copy of anything he uploads to the system. The supplier will do its utmost to maintain the information and material contained in the customer’s digital business card, including its backup, but it cannot be responsible for it or the content sent for its production.

                    <br /> <br /> <br />
                    <label className="privacy-bold">Trademarks</label>
                    <br />
                    The trademarks appearing on the digital business card are in the possession of their respective owners, as the case may be.




                    <br /> <br /> <br />
                    <label className="privacy-bold">Abuse</label>
                    <br />
                    The vendor may close a digital business card if it violates one or more of the rules contained in the content the entire “Terms of Use”, and for clarification have been presented and summarized in the following sections:

                    <br />
                    Copyrighted Content: Music, movies or games that you do not own.

                    Adult content.

                    <br />
                    Spam / SEO: A digital visit whose sole purpose is to achieve the ranking of Google, Facebook, etc.

                    <br />
                    Phishing: Get users to provide their username and password.

                    <br />
                    Scams: For quick enrichment, pyramid / network marketing, or other dubious programs.

                    <br />
                    This list is partial.

                    <br /> <br /> <br />
                    <label className="privacy-bold">Credit</label>
                    <br />
                    A digital business card built with the help of the system includes a credit link to the service’s registration site.

                    <br />
                    The vendor / NetGoCard.com have the discretion to change the location of the link from time to time according to the digital business card designs or to remove it as they wish.

                    <br /> <br /> <br />
                    <label className="privacy-bold">Graphics</label>
                    <br />
                    The service fee (subscription) does not include graphic services and / or design by the supplier.

                    <br />
                    Payment for the digital business card design by the carrier will be made separately.

                    <br />
                    It is stated that the business card can be designed using the system without the help of the supplier.


                    <br /> <br /> <br />
                    <label className="privacy-bold">Payments</label>
                    <br />
                    The customer undertakes to provide an appropriate payment for the services received the supplier, in advance and before receiving them.

                    <br />
                    It is your responsibility to make sure that your means of payment are up to date, and that all invoices are paid on time.

                    Although the provider has the right to make price changes for all provider services at any time, the prices are guaranteed for a pre-paid period.

                    <br />
                    In the event that the credit card number or its validity will result in a postponement of clearing by the credit card companies, the provider may immediately suspend the services it provides to the customer, until the issue of payment in full and actual full billing is clarified.

                    <br />
                    Invoices will be sent to customers via e-mail depending on the charges made, up to a few days after the debit is made and according to the recipient details provided by the customer.

                    <br />
                    Payment for the service is on an annual subscription basis, paid in advance for one year (or for a longer period if agreed in advance).

                    <br />
                    If the user is interested in continuing to receive the service at the end of the subscription period, the customer must renew and pay for the renewal of the subscription before the end of the subscription period (depending on the rate and conditions valid at that time).

                    <br />
                    If the customer does not renew and pay for the renewal of the subscription, the digital card will be suspended for a period of two months (during which he will be able to renew the subscription and reactivate it) and then the digital card will be deleted from the system.



                    <br /> <br /> <br />
                    <label className="privacy-bold">Cancelation</label>
                    <br />
                    A customer who wishes to stop using our services before the end of the contract period will be able to do so with 30 days’ notice, but there will be no refund from the supplier and no partial credit will be given.



                    <br /> <br /> <br />
                    <label className="privacy-bold">Copyright and Usage Permissions</label>
                    <br />
                    All copyrights and intellectual property in the design of the system and in any software, application, computer code, graphic file, text and any other material contained therein – are the sole property of the supplier. No part of this site may be copied, retransmitted, reposted, duplicated or otherwise used without the prior written consent of the Supplier. The trademarks on the site and in the digital business card are the property of the supplier only – or to the extent published on behalf of advertisers, the property of these advertisers only. They may not be used without their consent, in writing and in advance.

                    <br />
                    It is not possible to transfer and / or copy a tool / code / software / image etc. from the supplier’s system without his written consent or as part of a product offered by the supplier.

                    <br />
                    It is not possible to build a digital business card in the system and store it on another server on or off the network.

                    <br />
                    Used in the company’s services and sending media materials, including commercial logos, photos, videos, etc., the customer declares that he owns the copyright for the materials he sent and gives permission to the company (supplier) use these materials to create a business card.



                    <br /> <br /> <br />
                    <label className="privacy-bold">Indemnification</label>
                    <br />
                    The Client undertakes to protect and indemnify the Supplier and to refrain from any kind of claims and claims in respect of liabilities, losses, costs and claims, including attorney’s fees, against the Supplier, its agents, clients, supervisors and employees, which may arise or result from any service provided or agreed, or Any product sold by the customer, his agents, employees or beneficiaries.

                    <br />
                    The customer undertakes to indemnify the supplier for any claim for damages resulting from injury to person or property caused by any of the products sold or otherwise distributed by the customer through the service provided by the company and indemnify the supplier for intellectual property, information or details provided on By the customer and allegedly infringing the proprietary rights of a third party.

                    <br />
                    The customer hereby declares that all the information included in the electronic card is his sole responsibility for everything and anything.



                    <br /> <br /> <br />
                    <label className="privacy-bold">Changes in terms of use</label>
                    <br />
                    Vendor may change these Terms of Use from time to time without prior notice.

                    <br />
                    The supplier will publish the new terms and conditions and their effective date will be the date of publication.



                    <br /> <br /> <br />
                    <label className="privacy-bold">General</label>
                    <br />
                    Non-existence of a section of this document will be considered a reason for immediate shutdown of the customer’s system and without any prior notice.

                    <br />
                    The Supplier shall be entitled to delete the system of those who violate these Terms of Use in whole or in part.

                </div>

                <BottomBar />

            </div>
        )
    }
}

export default PrivacyPolicyPage;