import React, { Component } from 'react';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import { getIconBorderShape, getIconBorderColor } from "../utils/utils";
import './CreateCardDesign.scss';

class CreateCardDesign extends Component {
    constructor() {
        super();
        this.state = {
            profileImgOptions: [
                { value: 'Rectangle', label: 'Rectangle' },
                { value: 'Rounded Rectangle', label: 'Rounded Rectangle' },
                { value: 'Circle', label: 'Circle' },
            ],

            iconBorderShapeOptions: [
                { value: 'Rounded Rectangle Icons', label: 'Rounded Rectangle Icons' },
                { value: 'Circle Icons', label: 'Circle Icons' },
            ],

            iconBorderColorOptions: [
                { value: 'Black', label: 'Black' },
                { value: 'goldenrod', label: 'Gold' },
                { value: 'Silver', label: 'Silver' },
            ],

            cardOptions: [
                { value: 'Classic', label: 'Classic' },
                { value: 'Glory', label: 'Glory' },
                { value: 'Midnight', label: 'Midnight' },
                // { value: 'Midnight2', label: 'Midnight2' },
                { value: 'Metal Blue', label: 'Metal Blue' },
                { value: 'Peaceful White', label: 'Peaceful White' },
                { value: 'Pick Another Color', label: 'Pick Another Color' },
            ],
            textColorOptions: [
                { value: 'Black', label: 'Black' },
                { value: 'White', label: 'White' },
                { value: 'Pick Another Color', label: 'Pick Another Color' },
            ],

            showSketchPickerCard: false,
            showSketchPickerText: false,
        }
    }
    componentDidMount() { }

    handleProfileImgOptionsChange = selectedOption => {
        this.setState({ selectedProfileImgOption: selectedOption }, () => {
            this.props.setProfileImgDesign(selectedOption)
        })
    }

    handleIconBorderShapeOptionsChange = selectedOption => {
        this.setState({ selectedIconImgOption: selectedOption }, () => {
            this.props.setIconBorderShape(selectedOption)
        })
    }
    handleIconBorderColorChange = selectedOption => {
        this.setState({ selectedIconBorderColor: selectedOption }, () => {
            this.props.setIconBorderColor(selectedOption)
        })
    }

    handleCardOptionsChange = selectedOption => {
        this.setState({
            selectedCardOption: selectedOption,
            showSketchPickerCard: selectedOption.value === "Pick Another Color"
        }, () => {
            this.props.setCardBGColor("")
            this.props.setCardBGOption(selectedOption)
        })
    }
    handleCardColorSelected = (color) => {
        this.setState({ selectedColor: color.hex }, () => {
            this.props.setCardBGColor(color.hex)
            this.props.setCardBGOption(null)
        })
    }


    handleTextColorOptionsChange = selectedOption => {
        this.setState({
            selectedTextColor: selectedOption,
            showSketchPickerText: selectedOption.value === "Pick Another Color"
        }, () => {
            this.props.setTextColor(selectedOption)
        })
    }

    closeSketchPicker = () => {
        this.setState({
            showSketchPickerText: false,
            showSketchPickerCard: false,
        })
    }
    handleTextColorSelected = (color) => {
        this.setState({ selectedTextColor: color.hex }, () => {
            this.props.setTextColor(color.hex)
        })
    }

    render() {
        const theCard = this.props.theCard || null
        const { profileImgOptions, iconBorderShapeOptions, cardOptions, iconBorderColorOptions,
            selectedProfileImgOption, selectedIconImgOption, selectedCardOption,
            selectedTextColor, textColorOptions, selectedIconBorderColor,
            showSketchPickerCard, showSketchPickerText,
            selectedColor,
        } = this.state

        let profileOption = (theCard !== null && theCard.profileImgOptions !== ""
            && selectedProfileImgOption === undefined) ?
            { value: theCard.profileImgOptions, label: theCard.profileImgOptions }
            :
            selectedColor

        let iconBorderShape1 = getIconBorderShape(theCard, selectedIconImgOption)
        if (iconBorderShape1 === null) {
            iconBorderShape1 = "Rounded Rectangle Icons"
        }
        let iconBorderShape = { value: iconBorderShape1, label: iconBorderShape1 }

        let theSelectedIconBorderColor1 = getIconBorderColor(theCard, selectedIconBorderColor)
        if (theSelectedIconBorderColor1 === "goldenrod") {
            theSelectedIconBorderColor1 = "gold"
        }
        let theSelectedIconBorderColor = { value: theSelectedIconBorderColor1, label: theSelectedIconBorderColor1 }

        let bgOption = null

        if (theCard !== null && theCard.cardBGName !== "" &&
            selectedColor === undefined &&
            selectedCardOption === undefined) {
            bgOption = { value: theCard.cardBGName, label: theCard.cardBGName }
        } else if (selectedColor !== undefined) {
            bgOption = selectedColor
        } else if (selectedCardOption !== undefined) {
            bgOption = selectedCardOption
        }

        let textColorOption = null
        if (theCard !== null && theCard.textColor !== "" && selectedTextColor === undefined) {
            textColorOption = { value: theCard.textColor, label: theCard.textColor }
        } else if (selectedTextColor !== undefined) {
            textColorOption = selectedTextColor
        }

        return (
            <div className="add-design-container">

                <label className="add-top-lbl">
                    Card Design
                </label>

                <div className="selects-div">

                    <div className="select-with-title-div">

                        <label className="select-title-lbl"> Profile Image Design </label>

                        <Select
                            className="my-design-select"
                            isSearchable={false}
                            value={profileOption}
                            onChange={this.handleProfileImgOptionsChange}
                            options={profileImgOptions}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{
                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                    </div>

                    <div className="select-with-title-div">

                        <label className="select-title-lbl"> Card Background </label>

                        <Select
                            className="my-design-select"
                            isSearchable={false}
                            value={bgOption}
                            onChange={this.handleCardOptionsChange}
                            options={cardOptions}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{
                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                        {showSketchPickerCard === true ?
                            <div className="my-color-picker">
                                <SketchPicker
                                    color={selectedColor}
                                    onChangeComplete={this.handleCardColorSelected}
                                />
                                <button
                                    className="my-color-picker-close-btn"
                                    onClick={this.closeSketchPicker}>X</button>
                            </div>

                            :
                            null
                        }
                    </div>


                    <div className="select-with-title-div">

                        <label className="select-title-lbl"> Text Color </label>

                        <Select
                            className="my-design-select"
                            isSearchable={false}
                            value={textColorOption}
                            onChange={this.handleTextColorOptionsChange}
                            options={textColorOptions}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{
                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />

                        {showSketchPickerText === true ?
                            <div className="my-color-picker">
                                <SketchPicker
                                    color={textColorOption}
                                    onChangeComplete={this.handleTextColorSelected}
                                />
                                <button
                                    className="my-color-picker-close-btn"
                                    onClick={this.closeSketchPicker}>X</button>

                            </div>

                            :
                            null
                        }

                    </div>


                </div>

                <div className="selects-div">



                    <div className="select-with-title-div">

                        <label className="select-title-lbl"> Icons Design </label>

                        <Select
                            className="my-design-select"
                            isSearchable={false}
                            value={iconBorderShape}
                            onChange={this.handleIconBorderShapeOptionsChange}
                            options={iconBorderShapeOptions}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{
                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                    </div>

                    <div className="select-with-title-div">

                        <label className="select-title-lbl"> Icons Border Color </label>

                        <Select
                            className="my-design-select"
                            isSearchable={false}
                            value={theSelectedIconBorderColor}
                            onChange={this.handleIconBorderColorChange}
                            options={iconBorderColorOptions}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{
                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                    </div>


                    <div className="select-with-title-div">

                    </div>


                </div>
            </div>
        )
    }
}

export default CreateCardDesign;