
import React, { Component } from 'react';
import sessionstorage from 'sessionstorage'
import { setUserName } from "../store/actions/index";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { compose } from 'recompose';
import { withFirebase } from '../firebase';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import Resizer from "react-image-file-resizer";
import 'react-image-crop/dist/ReactCrop.css';
import MainInfo from '../createCard/MainInfo';
import CreateCardImages from './CreateCardImages';
import CreateGallery from './CreateGallery';
import CreateContactInfo from './CreateContactInfo';
import CreateCardDesign from './CreateCardDesign';
import CreateSocialMedia from './CreateSocialMedia';
import CreateVideos from './CreateVideos';
import PreviewPhone from '../app/PreviewPhone'
import './CreateCardPage.scss';

import emptyUser from '../images/empty_user2.jpg'

class CreateCardPage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            userName: "",
            userId: "",
            password: "",
            userEmail: "",
            showErrorUser: false,

            galleryImageUrls: [],
            aGalleryImageUrl: "",
            selectedTextColor: null,

            selectedProfileImgOption: null,
            selectedIconBorderShape: null,
            selectedCardOption: null,
            selectedBGColor: '#fff',
            selectedIconBorderShape: "black",

            businessCardTitle: '',
            businessJobTitle: '',
            industry: "",

            fullAddress: '',
            additionalDirections: '',
            phone: '',
            email: '',
            landline: '',
            whatsapp: '',
            videoUrl: '',
            website: '',

            linkTitle: '',
            linkDescription: '',
            linkUrl: '',

            allSocialMedia: [],
            videoUrls: [],

            editorState: EditorState.createEmpty(),
            convertedContent: "",

        }
    }

    componentDidMount = async () => {
        this.getUser()
    }
    getUser = () => {
        this.props.firebase.getCurrentUser(
            (user) => {
                this.setState({
                    userName: user.displayName,
                    userId: user.uid,
                    showErrorUser: false,
                    userEmail: user.email
                }, () => {
                })
            },
            () => {
            }
        )
    }

    saveImagesInCardAndThenSaveCard = async () => {
        const { galleryImageUrls, businessCardTitle } = this.state

        let name1 = businessCardTitle.replaceAll(/\s/g, '_')
        let imgUrls = []
        if (galleryImageUrls.length > 0) {
            const promises = galleryImageUrls.map(async (img, i) => {
                if (img.blob != undefined) {
                    let url3 = await this.props.firebase.uploadBlobGallery(img.blob, name1, i)
                    imgUrls.push(url3)
                }
            })

            Promise.all(promises).then(data2 => {
                this.saveCardDone(imgUrls)
            })
        } else {
            this.saveCardDone([])
        }
    }
    saveCardDone = async (imgUrls) => {
        const { userId, currentContentAsHTML,
            selectedBGColor, cardOption,
            selectedProfileImgOption, selectedIconBorderShape, selectedCardOption,
            faceImageUrl, coverImageUrl,
            businessCardTitle, businessJobTitle,
            industry,
            fullAddress,
            additionalDirections,
            phone,
            email,
            userEmail,
            landline,
            whatsapp,
            videoUrl,
            website,
            linkTitle,
            linkDescription,
            linkUrl,
            selectedTextColor,
            allSocialMedia,
        } = this.state


        let profileImgOptions = selectedProfileImgOption !== null && selectedProfileImgOption.value !== undefined ? selectedProfileImgOption.value : "Rectangle"
        let iconOptions = selectedIconBorderShape !== null && selectedIconBorderShape.value !== undefined ? selectedIconBorderShape.value : "Rounded Rectangle Icons"
        let cardBGName = selectedCardOption !== null ? selectedCardOption.value : ""
        let iconBorder = selectedIconBorderShape !== null ? selectedIconBorderShape.value : ""

        let textColor = null
        if (selectedTextColor !== null && selectedTextColor.value !== undefined) {
            textColor = selectedTextColor.value
        } else if (selectedTextColor !== null && selectedTextColor.value === undefined) {
            textColor = selectedTextColor
        }
        else {
            if (cardOption === "Midnight") {
                textColor = "white"
            } else {
                textColor = "black"
            }
        }

        let name = businessCardTitle.replaceAll(/\s/g, '_')
        while (name.charAt(name.length - 1) === "_") {
            name = name.substr(0, name.length - 1)
        }
        let faceUrl = ""
        let coverUrl = ""
        if (faceImageUrl !== undefined) {
            faceUrl = await this.props.firebase.uploadBlobFace(faceImageUrl, name)
        }
        if (coverImageUrl !== undefined) {
            coverUrl = await this.props.firebase.uploadBlobCover(coverImageUrl, name)
        }
        let currentContentAsHTML1 = currentContentAsHTML !== undefined ? currentContentAsHTML : ""
        let iconBorder1 = iconBorder !== undefined ? iconBorder : ""


        let associatedPlan = sessionStorage.getItem("associatedPlan")
        let card = {
            businessCardTitle,
            businessJobTitle,
            industry,
            faceImageUrl: faceUrl,
            coverImageUrl: coverUrl,
            profileImgOptions,
            iconOptions,
            selectedBGColor,
            cardBGName,
            textColor,
            fullAddress,
            additionalDirections,
            phone,
            email,
            landline,
            whatsapp,
            videoUrl,
            website,
            allSocialMedia,
            iconBorder: iconBorder1,
            currentContentAsHTML: currentContentAsHTML1,
            linkTitle,
            linkDescription,
            linkUrl,
            name_uppercase: businessCardTitle.toUpperCase(),
            status: "pending",
            associatedPlan,
            userId,
            createdAt: Date.now(),
        }

        let id1 = ""
        id1 = await this.props.firebase.addCardToDbAndUserCards(card, userId)
        let promises = imgUrls.map(async (img, i) => {
            await this.props.firebase.addImgUrlToGallery(id1, img)
        })

        Promise.all(promises).then(async data2 => {
            await this.props.firebase.sendNewCardEmail(userEmail, card.businessCardTitle)
            if (id1 !== "") {
                sessionstorage.setItem("userEmail", userEmail)
                window.location.href = "/card/" + name + "/" + id1
            } else {
                this.showError()
            }
        })
    }


    saveCard = () => {
        const { businessCardTitle, userName } = this.state

        if (userName === "") {
            this.setState({ showErrorUser: true }, () => {
                alert("please sign in or sign up first")
            })
        } else {
            let hasAllMust = businessCardTitle !== ''
            if (hasAllMust === true) {
                this.showLoading()
                this.saveImagesInCardAndThenSaveCard()
            } else {
                alert("make sure you added a buisness title before saving the card")
            }
        }
    }

    onChangeP = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    getPersonSocialValue = (socialType) => {
        const { allSocialMedia } = this.state
        var result = allSocialMedia.filter(obj => {
            return obj.type === socialType
        })
        let ans = ""
        if (result.length > 0) {
            if (result[0] !== undefined) {
                ans = result[0].value
            }
        }
        return ans
    }

    setSocialMediaOptions = (options) => {
        this.setState({ allSocialMedia: options }, () => {
        })
    }

    setVideos = (arr) => {
        this.setState({ videoUrls: arr }, () => {
        })
    }

    setFaceImg = (imgBlob) => {
        var file = new File([imgBlob], "name11");
        this.reduceImgSizeTo200x200(file)
    }

    setCoverImg = (imgBlob) => {
        this.setState({ coverImageUrl: imgBlob })
    }

    reduceImgSizeTo200x200 = async (file) => {
        try {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                "JPEG",
                100,
                0,
                async uri => {
                    const response = await fetch(uri);
                    const blob = await response.blob();
                    blob.name = 'newFile1.jpeg'
                    this.setState({ faceImageUrl: blob })
                },
                "base64",
                200,
                200
            );

        } catch (err) {
            console.error(err);
        }
    }

    cancelCroppedFaceImg = () => {
        this.setState({ faceImageUrl: undefined })
    }

    cancelCroppedCoverImg = () => {
        this.setState({ coverImageUrl: undefined })
    }

    addImg = (imgUrl) => {
        this.setState({ galleryImageUrls: this.state.galleryImageUrls.concat(imgUrl) })
    }

    setImg = (imgUrl) => {
        this.setState({ aGalleryImageUrl: imgUrl })
    }

    setAllGalleryImgUrls = (imgsUrls) => {
        this.setState({ galleryImageUrls: imgsUrls })
    }

    cancelImg = (imgUrl) => {
        this.setState({ aGalleryImageUrl: "" })
    }
    cancelThisImg = (index) => {
        let arr = [...this.state.galleryImageUrls]
        arr.splice(index, 1)
        this.setState({
            galleryImageUrls: arr
        })
    }
    cancelAllImgs = () => {
        this.setState({ galleryImageUrls: [] })
    }

    removeImg = (imgUrl) => {

        let array = [...this.state.galleryImageUrls]
        let index = array.indexOf(imgUrl)
        if (index !== -1) {
            array.splice(index, 1)
            this.setState({ galleryImageUrls: array })
        }
    }

    setIndustry = (option) => {
        this.setState({ industry: option.value })
    }
    setTextColor = (color) => {
        this.setState({ selectedTextColor: color })
    }

    setCardBGOption = (color) => {
        this.setState({ selectedCardOption: color })
    }

    setCardBGColor = (color) => {
        this.setState({ selectedBGColor: color })
    }

    setProfileImgDesign = (option) => {
        this.setState({ selectedProfileImgOption: option })
    }

    setIconBorderShape = (shape) => {
        this.setState({ selectedIconBorderShape: shape })
    }

    setIconBorderColor = (color) => {
        this.setState({ selectedIconBorderColor: color })
    }

    showError = () => {
    }

    showLoading = () => {
        this.setState({
            loading: true
        })
    }

    scrollToEndIdInPreview = () => {
    }

    onEditorStateChange = (editorState) => {
        this.scrollToEndIdInPreview()
        this.setState({
            editorState,
        }, () => {
            this.convertContentToHTML()
        })
    }

    convertContentToHTML = () => {
        const { editorState } = this.state
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
        this.setState({
            currentContentAsHTML
        })
    }
    createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }


    goToGalleryInPreview = () => {
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            showErrorUser: false,
        })
    }
    createUserIDB = async (id, username) => {
        const { email } = this.state

        //create user in db
        const payload = {
            id,
            name: username,
            email,
            needToChangePassword: false,
            createdAt: Date.now(),
        }

        let ans = await this.props.firebase.doAddUserToDb(payload)
        if (ans === true) {
        } else {
            this.setState({
                showAlert: true,
                alertMsg: 'error adding user',
            })
        }
    }
    signup = async () => {
        const { email, password } = this.state
        await this.props.firebase
            .doCreateUserWithEmailAndPassword(email, password)
            .then(authUser => {
                sessionstorage.setItem("userEmail", email);
                sessionstorage.setItem("userId", authUser.user.uid);
                let username = email.substr(0, email.indexOf("@"))
                this.props.firebase.auth.currentUser
                    .updateProfile({ displayName: username })
                    .then(() => {
                        this.props.setUserName(username)
                        this.createUserIDB(authUser.user.uid, username)
                    })
            })
            .catch(error => {
                alert("error signing up. please try again later")
            })
    }
    signin = async () => {
        const { email, password } = this.state
        await this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
        sessionstorage.setItem("userEmail", email)
    }

    signout = async () => {
        this.props.firebase.doSignOut()
        sessionstorage.removeItem("userEmail")
        sessionstorage.removeItem("userId")
        sessionstorage.removeItem("userName")
        this.setState({
            userName: "",
            showErrorUser: false,
        })
    }

    getSignedInDiv = () => {
        const { userName, editorState, email, password, showErrorUser } = this.state
        return (
            <div className="sign-container"
                style={{ border: showErrorUser === true ? "1px solid red" : "" }}
            >
                {userName !== "" ?
                    <div className="signedin-as-container">
                        <img alt="f" src={emptyUser} className="signedin-as-img" />
                        <label className="signedin-as-lbl1">You are currently signed in.</label>
                        <label className="signout-link-2" onClick={this.signout}>
                            Sign Out
                        </label>
                        <label className="signedin-as-lbl11">or continue below and start submission.</label>

                    </div>
                    :
                    <div className="create-card-signup-container">
                        <input
                            className="create-card-signup-input"
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDown}
                            type="text"
                            placeholder="email"
                            autoComplete="off"
                        />

                        <input
                            className="create-card-signup-input"
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder="password"
                            autoComplete="off"
                        />
                        <button
                            className="create-card-signup-btn"
                            onClick={this.signup}>
                            Sign Up
                        </button>
                        <button
                            className="create-card-signup-btn"
                            onClick={this.signin}>
                            Sign In
                        </button>
                    </div>
                }


            </div>
        )
    }

    getPage = (boolMobile) => {
        const { editorState } = this.state

        return (
            <div className={boolMobile === true ?
                "create-card-page-inside-main-container-mobile" :
                "create-card-page-inside-main-container"}
            >

                <label className="add-listing-title1">
                    LETS GO!
                </label>
                <label className="add-listing-sub-title">
                    Start creating your digital card
                </label>

                <MainInfo
                    onChangeP={this.onChangeP}
                    setIndustry={this.setIndustry}
                />

                <CreateCardImages
                    setFaceImg={this.setFaceImg}
                    setCoverImg={this.setCoverImg}
                    cancelCroppedFaceImg={this.cancelCroppedFaceImg}
                    cancelCroppedCoverImg={this.cancelCroppedCoverImg}
                    canEditStyle={true}
                />

                <CreateCardDesign
                    setTextColor={this.setTextColor}
                    setCardBGColor={this.setCardBGColor}
                    setCardBGOption={this.setCardBGOption}
                    setProfileImgDesign={this.setProfileImgDesign}
                    setIconBorderShape={this.setIconBorderShape}
                    setIconBorderColor={this.setIconBorderColor}
                />


                <CreateContactInfo onChangeP={this.onChangeP} />

                <div className="editor-div">

                    <label className="add-top-lbl">
                        Write About Yourself
                    </label>
                    <Editor
                        className="my-text-editor"
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: [
                                'inline',
                                'fontSize',
                                'list',
                                'textAlign',
                                "link",
                            ],
                            list: {
                                options: ["ordered", "unordered"],
                            },
                            inline: {
                                options: ["bold", "italic", "underline"],
                            },
                        }}

                    />
                </div>

                <CreateSocialMedia
                    setSocialMediaOptions={this.setSocialMediaOptions}
                    allSocialMedia={this.state.allSocialMedia}
                />

                <CreateVideos
                    videoUrls={this.state.videoUrls}
                    setVideos={this.setVideos}
                />

                <CreateGallery
                    addImg={this.addImg}
                    setImg={this.setImg}
                    cancelImg={this.cancelImg}
                    cancelThisImg={this.cancelThisImg}
                    cancelAllImgs={this.cancelAllImgs}
                    setAllGalleryImgUrls={this.setAllGalleryImgUrls}
                    numOfImages={this.state.galleryImageUrls.length}
                    goToGalleryInPreview={this.goToGalleryInPreview}
                />

                {this.getSignedInDiv()}


                <button className="save-details-btn"
                    onClick={this.saveCard}>
                    {"Save Card"}
                </button>


            </div>
        )
    }

    getPhonePreview = (device) => {
        return (
            <PreviewPhone
                theState={this.state}
                device={device}
                rerender={this.rerender}
                saveEditedCard={this.saveCard}
            />
        )
    }

    render() {
        const { loading } = this.state
        return (
            <div className="create-card-page-main-container">

                {loading === true ?
                    <div className={window.innerWidth < 1250 ?
                        "loading-div-mobile" : "loading-div"}
                    >
                        <label className="loading-lbl"> Saving Card</label>
                        <label className="loading-lbl2"> this might take a few seconds...</label>
                        <ReactLoading type={"spinningBubbles"} color="crimson" />
                    </div>
                    :
                    null
                }

                {window.innerWidth < 1250 ?
                    <div className="edit-card-render-div">
                        {this.getPhonePreview("mobile")}
                        {this.getPage(true)}
                    </div>
                    :
                    <div className="edit-card-render-div">
                        {this.getPhonePreview("desktop")}
                        {this.getPage(false)}
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userName1: state.userName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserName: name => dispatch(setUserName(name))
    };
}

export default compose(
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps)
)(CreateCardPage);
