import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { compose } from 'recompose';
import { withFirebase } from '../firebase';

import './AlertPopup.scss'

class AlertPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            msg: '',
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount = async () => {
        document.addEventListener('mousedown', this.handleClickOutside);
        let msg1 = this.props.msg;
        let msg = ''
        switch (msg1) {

            case "error adding user":
                msg = "oops! \n something went wrong...  :("
                break;

            case "error finding user":
                msg = "oops! \n no user with this email was found "
                break;

            case "toBig":
                msg = "image too big! please upload an image up to 700KB"
                break;

            case "There is no user record corresponding to this identifier. The user may have been deleted.":
                msg = "oops! \n  no user with this email was found "
                break;

            case "The password is invalid or the user does not have a password.":
                msg = " oops! \n The password is invalid or the user does not have a password"
                break;

            default:
                msg = msg1
                break;
        }
        this.setState({
            msg
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.stopPopup()
        }
    }
    stopPopup = () => {
        this.props.stopPopup()
    }
    getMsg = () => {
        const { msg } = this.state
        if (msg && typeof (msg) === "string") {
            let newText = msg.split('\n').map((item, i) => {
                return <p key={i} className="msg">{item}</p>;
            });
            return newText
        } else {
            return "oops... something went wrong :("
        }
    }
    sendConfirmationEmail = async () => {
        await this.props.firebase.doSendEmailVerification()
        alert('email sent!') 
    }
    render() {
        let msg = this.getMsg()
        return (
            <div className="alert-container"
                ref={this.setWrapperRef}
            >
                <div className="exit-btn-container">
                    <button className="exit-popup-btn"
                        onClick={this.stopPopup}>
                        <FontAwesomeIcon icon={faTimesCircle} color="black" />
                    </button>
                </div>
                <label className="alert-text"> {msg} </label>
                
                {this.state.msg === "please verify your email before logging in" ?
                    <button onClick={this.sendConfirmationEmail}>
                        Send Confirmation Email Again
                    </button>
                    :
                    null
                }


                <div className="ok-btn-container">
                    <button className="ok-popup-btn"
                        onClick={this.stopPopup}>
                        OK
                    </button>
                </div>
            </div >
        )
    }
}


export default compose(withFirebase)(AlertPopup)