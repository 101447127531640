import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './ExampleCardsCarousle.scss';

import ex1 from '../images/ex1.png'
import ex2 from '../images/ex2.png'
import ex3 from '../images/ex3.png'
import ex4 from '../images/ex4.png'
import ex5 from '../images/ex5.png'
import ex6 from '../images/ex6.png'

class ExampleCardsCarousle extends Component {
    constructor() {
        super();
        this.state = {}
    }
    componentDidMount() { }

    onClickItem = (image) => {
        // window.location.href = "/card/" + image.name +"/"+ image.id
    }

    render() {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5,
                // slidesToSlide: 6 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
                // slidesToSlide: 6 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
                // slidesToSlide: 6 // optional, default to 1.
            }
        }

        const images = [
            { src: ex1, name: "titanium-air", id: "-Mc32S2fGoWerYM3pi69" },
            { src: ex2, name: "minho-koung", id: "-Mc3-WvREFmJZFVXbh2u" },
            { src: ex3, name: "aruna-iduku", id: "-MdRR1uCbY2LUdM_73oL" },
            { src: ex4, name: "stephen-ogbaini", id: "-Mc30W80dxU5HQ-Quotj" },
            { src: ex5, name: "mrs-iminathi-tisani", id: "-Mc310OxXurwxe6nAksx" },
            { src: ex6, name: "ng-card", id: "-Mc31Xlx96AQGexX77Hr" },
        ]


        return (
            <div id="examples" className={window.innerWidth < 1100 ?
             "examples-div-mobile" : "examples-div"} >
                <div className="bg-overly" >

                    <label className="examples-main-title">Some Examples</label>
                    <label className="examples-sub-title">
                        Click image to open the link
                    </label>

                    <Carousel
                        className={window.innerWidth < 1100 ? "carousel-div-mobile" : "carousel-div"}
                        ssr
                        partialVisbile
                        deviceType={window.innerWidth < 1100 ? "mobile" : "desktop"}
                        itemClass="image-item"
                        responsive={responsive}
                        infinite={true}
                        showDots={true}
                        arrows={false}
                        keyBoardControl={false}
                        autoPlay={true}
                    >
                        {images.map((image, i) => {
                            return (
                                <img alt=""
                                    onClick={() => this.onClickItem(image)}
                                    key={i}
                                    style={{ width: "90%" }}
                                    src={image.src}
                                />
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default ExampleCardsCarousle;