import React, { Component } from 'react';
import './CreateVideos.scss';

class CreateVideos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            videoTitle: '',
            videoUrl: '',
            videoObjects: this.props.videoObjects,
            videoObjects: this.props.videoObjects,
            showErrorVideo: false,
        }
    }

    componentDidMount() {
    }

    handleSocialMediaOptionsChange = selectedOption => {
        this.setState({
            showErrorVideo: false,
        });
    }

    addVideo = () => {
        const { videoUrl, videoTitle } = this.state
        if (videoUrl === "" || videoTitle === "") {
            this.setState({
                showErrorVideo: true,
            })
            return
        }
        let moreThen3 = false
        let maxVideos = 3
        if (this.props.theCard !== undefined) {
            if (this.props.theCard.videoObjects !== undefined) {
                if (this.props.theCard.videoObjects.length >= maxVideos) {
                    moreThen3 = true
                }
            }
        } else {
            if (this.state.videoObjects.length >= maxVideos) {
                moreThen3 = true
            }
        }


        if (moreThen3 === true) {
            alert("you can upload up to 3 videos")
        } else {
            let bool = this.state.videoObjects.includes(videoUrl)
            if (bool === false) {
                let newvideoObjects = this.state.videoObjects
                newvideoObjects = newvideoObjects.concat({ videoTitle, videoUrl })
                this.setState({
                    videoUrl: "",
                    videoTitle: "",
                    videoObjects: newvideoObjects
                }, () => {
                    this.props.setVideos(this.state.videoObjects)
                })
            } else {
                alert("you already uploaded this video")
            }
        }
    }

    getvideoObjects = () => {
        let all = []
        if (this.state.videoObjects !== undefined && this.state.videoObjects.length > 0) {
            let arr = Object.values(this.state.videoObjects)        
            arr.forEach((video, i) => {
                all.push(video)
            })
        }
        return all
    }


    getVideosList = () => {
        let all = this.getvideoObjects()
        let list = []

        if (window.innerWidth > 1100) {
            all.map((v, i) => {
                list.push(<div className="a-video-row" key={i}>
                    <label className="a-video-lbl1">{v.videoTitle}</label>
                    <label className="a-video-lbl2">{v.videoUrl}</label>
                    <button className="video-remove-btn"
                        onClick={() => { this.removeVideo(v) }}
                    >X</button>
                </div>
                )
            })
        } else {
            all.map((v, i) => {
                list.push(<div className="a-social-media-row" key={i}>
                    <div className="social-name-and-remove-btn">
                        <label className="a-video-lbl1">{v.videoTitle}
                        </label>
                        <button className="social-media-remove-btn"
                            onClick={() => { this.removeVideo(v) }}
                        >X</button>
                    </div>
                    <label className="a-video-lbl2">{v.videoUrl}</label>
                </div>
                )
            })
        }
        return list
    }
    removeVideo = (m) => {
        let all = this.getvideoObjects()
        let newList = []

        all.map((m1, i) => {
            if (m1 === m) {
            } else {
                newList.push(m1)
            }
        })
        this.setState({
            videoObjects: newList
        }, () => {
            this.props.setVideos(newList)
        })
    }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { videoTitle, videoUrl, showErrorVideo } = this.state
        return (

            <div className="add-social-media-container">

                <label className="add-top-lbl">
                    Videos
                </label>

                <div className="select-video-div">

                    <input
                        style={{ border: showErrorVideo === true ? "1px solid red" : null }}
                        className="add-video-input"
                        name="videoTitle"
                        value={videoTitle}
                        onChange={this.onChange}
                        type="url"
                        placeholder="Video Title"
                    />

                    <input
                        style={{ border: showErrorVideo === true ? "1px solid red" : null }}
                        className="add-video-input"
                        name="videoUrl"
                        value={videoUrl}
                        onChange={this.onChange}
                        type="url"
                        placeholder="https://"
                    />
                    <button className="add-social-media-btn"
                        onClick={this.addVideo}>
                        Add
                    </button>
                </div>

                {this.getVideosList()}
            </div>
        )
    }
}

export default CreateVideos;