import React, { Component } from 'react';
import { compose } from 'recompose';
import sessionstorage from 'sessionstorage'
import ReactLoading from "react-loading";
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../firebase';

import './SignUpPage.scss'

class SignUpPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            passwordOne: '',
            passwordTwo: '',
            loading: false,
            error: null,
            invalid1: false,
            invalid2: false,
            invalid3: false,
            invalid4: false,
            showAlert: false,
            alertMsg: '',
            show_error: false,
            errorMsg: '',
        };
    }
    componentDidMount = () => {
        const env = process.env
        let url = env["REACT_APP_CONFIRMATION_EMAIL_REDIRECT"]
    }

    createUserIDB = async (id) => {
        const { username, email } = this.state

        //create user in db
        const payload = {
            id,
            name: username,
            email,
            needToChangePassword: false,
            createdAt: Date.now(),
        }

        let ans = await this.props.firebase.doAddUserToDb(payload)
        if (ans === true) {
            this.logout()
        } else {
            this.setState({
                showAlert: true,
                alertMsg: 'error adding user',
            })
        }
    }

    onChange = event => {
        let value = event.target.value
        if (event.target.name === "email") {
            value = value.toLowerCase()
        }
        this.setState({
            [event.target.name]: value,
            invalid1: false,
            invalid2: false,
            invalid3: false,
            invalid4: false
        });
    };

    logout = async () => {
        this.setState({ selected: 6 }, async () => {
            await this.props.firebase.doSignOut()
            sessionstorage.removeItem("userEmail")
            sessionstorage.removeItem("userId")
            sessionstorage.removeItem("userName")
            window.location.href = "/login"
        })
    }

    onSubmit = () => {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '';

        if (isInvalid === true) {
            let msg = ''
            let invalid1 = false
            let invalid2 = false
            let invalid3 = false
            let invalid4 = false
            if (passwordOne !== passwordTwo) {
                msg = 'confiremed password does not match the first pasword'
                invalid3 = true
                invalid4 = true
            } else if (username === '') {
                msg = 'enter a user name'
                invalid1 = true
            } else if (email === '') {
                msg = 'enter a valid email'
                invalid2 = true
            } else if (passwordOne === '') {
                msg = 'enter password'
                invalid3 = true
            }
            this.setState({
                showAlert: true,
                alertMsg: msg,
                errorMsg: msg,
                invalid1,
                invalid2,
                invalid3,
                invalid4,
                show_error: invalid1 || invalid2 || invalid3 || invalid4
            })
        } else {

            this.setState({ loading: true }, async () => {
                this.props.firebase
                    .doCreateUserWithEmailAndPassword(email, passwordOne)
                    .then(authUser => {
                        sessionstorage.setItem("userEmail", email);
                        sessionstorage.setItem("userId", authUser.user.id);
                        this.props.firebase.auth.currentUser
                            .updateProfile({ displayName: username })
                            .then(() => {
                                this.createUserIDB(authUser.user.uid)
                            })
                    })
                    .catch(error => {
                        console.error("firebase signup error: ", error)
                        this.setState({
                            showAlert: true,
                            alertMsg: error.message,
                            show_error: true,
                            errorMsg: error.message,
                            loading: false
                        })
                    })
            })
        }
    }

    removeAlertPopUp = () => {
        this.setState({
            showAlert: false
        })
    }

    showAlertPopUp = () => {
    }

    render() {
        const {
            username,
            email,
            passwordOne,
            passwordTwo,
            loading,
            showAlert,
            invalid1,
            show_error,
            errorMsg,

        } = this.state;

        if (loading === true) {
            return (
                <div className="loading-signin-container">
                    <label className="main-loading-lbl"> Loading ...</label>
                    <ReactLoading type={"spinningBubbles"} color="crimson" />
                </div>
            )
        }

        return (

            <div className="sign-up-page-container">
                <div className="bg-overly">
                    <div className="sign-up-form-container">  
                        {showAlert ?
                            this.showAlertPopUp()
                            :
                            null
                        }
                        <h1 className="sign-up-title">Sign Up</h1>
                        <input
                            className="sign-up-input"
                            style={{ border: invalid1 ? "solid 1px gainsboro" : null }}
                            name="username"
                            value={username}
                            onChange={this.onChange}
                            type="text"
                            placeholder="user name.."
                        />
                        <input
                            className="sign-up-input"
                            style={{ border: invalid1 ? "solid 1px gainsboro" : null }}
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            placeholder="email.."
                        />
                        <input
                            className="sign-up-input"
                            style={{ border: invalid1 ? "solid 1px gainsboro" : null }}
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            placeholder="password..."
                        />
                        <input
                            className="sign-up-input"
                            style={{ border: invalid1 ? "solid 1px gainsboro" : null }}
                            name="passwordTwo"
                            value={passwordTwo}
                            onChange={this.onChange}
                            type="password"
                            placeholder="password confirmation..."
                        />

                        <label
                            className="error_msg_lbl"
                            style={{ visibility: show_error ? true : false }}
                        >
                            {errorMsg}
                        </label>

                        <button
                            className="sign-up-btn"
                            onClick={this.onSubmit}
                        >OK</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter, withFirebase,
)(SignUpPage);
