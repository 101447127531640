
import React, { Component } from 'react';
import './CreateContactInfo.scss';

class CreateContactInfo extends Component {
    constructor() {
        super();
        this.state = {
            businessCardTitle: null,
            businessJobTitle: null,
            fullAddress: null,
            additionalDirections: null,
            phone: null,
            email: null,
            landline: null,
            whatsapp: null,
            videoUrl: null,
            website: null,

            linkTitle: null,
            linkDescription: null,
            linkUrl: null,
        }
    }
    componentDidMount() { }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.onChangeP(event)
        });
    }

    render() {
        const theCard = this.props.theCard || null
        const {
            fullAddress,
            additionalDirections,
            phone,
            email,
            landline,
            whatsapp,
            website,
            linkTitle,
            linkDescription,
            linkUrl,

        } = this.state

        return (

            <div className="add-details-container">

                <label className="add-top-lbl">
                    Contact Information
                </label>

                <label className="add-details-lbl">
                    Email
                </label>

                <input
                    className="add-details-input"
                    name="email"
                    value={theCard !== null && email === null ?
                        theCard.email : email === null ? "" : email}
                    onChange={this.onChange}
                    type="text"
                    placeholder=""
                />

                <label className="add-details-lbl">
                    Full Address
                </label>

                <input
                    className="add-details-input"
                    name="fullAddress"
                    value={theCard !== null && fullAddress === null ?
                        theCard.fullAddress :
                        fullAddress === null ? "" : fullAddress}
                    onChange={this.onChange}
                    autoComplete="off"
                    placeholder="e.g: No 1 Usuma St', Abuja"
                />

                <label className="add-details-lbl">
                    Additional Directions
                </label>

                <input
                    className="add-details-input"
                    name="additionalDirections"
                    value={theCard !== null && additionalDirections === null ?
                        theCard.additionalDirections :
                        additionalDirections === null ? "" : additionalDirections}
                    onChange={this.onChange}
                    type="text"
                    placeholder="ex: Building number 5, Floor 2"
                />


                <label className="add-details-lbl">
                    Phone
                </label>

                <input
                    className="add-details-input"
                    name="phone"
                    value={theCard !== null && phone === null ?
                        theCard.phone :  phone === null ? "" :phone}
                    onChange={this.onChange}
                    type="text"
                    placeholder="+2341115551111"
                />

                <label className="add-details-lbl">
                    Landline
                </label>

                <input
                    className="add-details-input"
                    name="landline"
                    value={theCard !== null && landline === null ?
                        theCard.landline :  landline === null ? "" :landline}
                    onChange={this.onChange}
                    type="text"
                    placeholder=""
                />


                <label className="add-details-lbl">
                    Whatsapp
                </label>

                <input
                    className="add-details-input"
                    name="whatsapp"
                    value={theCard !== null && whatsapp === null ?
                        theCard.whatsapp :  whatsapp === null ? "" :whatsapp}
                    onChange={this.onChange}
                    type="text"
                    placeholder="+2341115551111"
                />

                <label className="add-details-lbl">
                    Website
                </label>

                <input
                    className="add-details-input"
                    name="website"
                    value={theCard !== null && website === null ?
                        theCard.website : website === null ? "" : website}
                    onChange={this.onChange}
                    type="url"
                    placeholder="https://"
                />


                <label className="add-details-lbl">
                    Free Link
                </label>

                <input
                    className="add-link-input"
                    name="linkTitle"
                    value={theCard !== null && linkTitle === null ?
                        theCard.linkTitle : linkTitle === null ? "" : linkTitle}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Link Title - ex: Check out this amazing survay"
                />

                <input
                    className="add-link-input"
                    name="linkDescription"
                    value={theCard !== null && linkDescription === null ?
                        theCard.linkDescription : linkDescription === null ? "" : linkDescription}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Link Description - ex: please enter this survay and tell us what you think :)"
                />

                <input
                    className="add-link-input"
                    name="linkUrl"
                    value={theCard !== null && linkUrl === null ?
                        theCard.linkUrl : linkUrl === null ? "" : linkUrl}
                    onChange={this.onChange}
                    type="url"
                    placeholder="https://"
                />

            </div>
        )
    }
}

export default CreateContactInfo;